@use "inc/setting" as inc_set;
@use "inc/reset" as inc_re;

:root {
  // --pri-height: (65px + 120px);
  --pri-height: 20vh;
  --pri-height_sp: 33vh;
}

@mixin box01 {
  height: 6vh;
}

@mixin box02 {
  height: 6vh;
}

#top {

  /* モーダルの基本スタイル */
  .modal {
    display: none;
    /* 初期状態では非表示 */
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100dvh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10000;
  }

  .modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 550px;
  }

  #closeModal {
    cursor: pointer;
    float: right;
    width: clamp(30px, 3vw, 40px);
  }

  .banner {
    position: fixed;
    bottom: 10px;
    right: 10px;
    width: 60%;
    max-width: 320px;
    box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    font-size: 1rem;
    transition: .5s;

    &.close {
      opacity: 0;
    }

    &.hidden {
      display: none;
    }

    @keyframes fadeOut {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }

    &.show {
      animation: fadeIn 0.5s ease-in-out;
    }

    @keyframes fadeIn {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }
  }

  /* 閉じるボタンのスタイル */
  .close-banner {
    background: none;
    border: none;
    color: #fffaf0;
    font-size: 1.2rem;
    cursor: pointer;
    position: absolute;
    top: -18%;
    right: 0;
    width: 35px;
  }

  .close-banner:hover {
    text-decoration: underline;
  }

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp) {
    .banner {
      bottom: 50px;
    }

    /* 閉じるボタンのスタイル */
    .close-banner {
      top: -40px;
    }

    .close-banner:hover {
      text-decoration: underline;
    }

    /*@media screen end*/
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________

  /* アニメーションを無効化 (アクセシビリティ配慮) */
  @media (prefers-reduced-motion: reduce) {
    .banner {
      animation: none;
    }
  }

  .main_wrap {
    width: 100%;
    // height: 100vh;
    overflow: hidden;
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;

    .main_mov {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background: #000;
      position: fixed;
      left: 0;
      z-index: 11;
      overflow: hidden;
      transition: 1s;

      &.out {
        opacity: 0;
      }

      &.hdn {
        display: none;
      }

      video {
        width: 100%;
      }
    }

    .main {
      width: 100%;
      // height: 100vh;
      position: relative;

      .skip {
        position: absolute;
        top: 106px;
        right: 0;
        padding: 5px 10px 0 10px;
        border: 1px solid #fff;
        z-index: 15;
        filter: drop-shadow(0 0 2px rgba(0, 0, 0, 1));
        cursor: pointer;
        transition: 1s;

        &.no_show {
          opacity: 0;
        }

        p {
          color: #fff;
          font-size: clamp(0px, inc_set.$vw_24px, 14px);
          text-align: center;
        }
      }
    }

    .scroll_slid02 {
      width: 100%;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;

      >div {
        width: 100%;
        // height: 100vh;
        // height: calc(100vh - (65px + 135px));
        // height: calc(100vh - var(--pri-height));
        height: calc(100dvh - var(--pri-height));
        display: flex;
        justify-content: center;
        align-items: center;
        background-size: cover;
        position: relative;

        .scrollnav {
          position: absolute;
          left: 50%;
          // bottom: var(--pri-height);
          bottom: 0;
          transform: translate(-50%, 0);
          text-align: center;
          z-index: 5;
          opacity: 1;
          transition: 1s;

          p {
            color: #fff;
            text-align: center;
            font-size: clamp(10px, 3.6vw, 22px);
          }

          img {
            max-width: 80px;
            margin: 0 auto 10px;
          }

          @keyframes indicator {
            0% {
              top: -100%;
            }

            50% {
              top: 100%;
            }

            100% {
              bottom: -100%;
            }
          }

          .indicator {
            position: relative;
            height: 30px;
            overflow: hidden;

            span {
              width: 3px;
              height: 100%;
              background: #fff;
              left: 50%;
              position: absolute;

              &:nth-of-type(1) {
                animation: indicator 2s ease infinite;
                z-index: 5;
              }

              &:nth-of-type(2) {
                top: 0;
                opacity: .5;
              }
            }
          }
        }

        &:not(:first-of-type) {
          position: absolute;
          top: 0;
          left: 0;
        }

        .sl_text {
          color: #fff;
          font-size: clamp(10px, 2.6vw, 42px);
          text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.8);
          position: absolute;
        }

        .img_p {
          position: absolute;
          right: 0;
          bottom: 0;
          font-size: 10px;
          padding: 10px;
          color: #fff;
        }

        &.ob {
          height: var(--pri-height);
          bottom: 0;
          top: auto;
          flex-direction: column;

          .ob_p1 {
            background: linear-gradient(90deg, #805C25, #DBA418 50%, #2B0304);
            width: 100%;
            // height: 8.4vh;
            height: 8.4dvh;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 3;

            p {
              font-size: clamp(10px, 2.7vw, 35px);
              color: #fff;
              font-family: "a1", serif;
              text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
              font-weight: 600;
              letter-spacing: .2em;
              padding-bottom: 4px;

              span {
                font-size: clamp(10px, 2.8vw, 38px);
              }
            }
          }

          .price_area {
            background: #E7EAE6;
            // padding: 25px 0;
            width: 100%;
            position: relative;
            z-index: 0;
            height: calc(20dvh - 8.4vh);
            // height: calc(20vh - 8.4vh);
            display: flex;
            justify-content: center;
            align-items: center;

            .price_in {
              max-width: 980px;
              width: 80%;
              margin: 0 auto;
              display: flex;
              // justify-content: space-between;
              justify-content: center;
              // flex-wrap: wrap;
              position: relative;

              .flex {
                // width: 46%;
                width: 100%;
                min-width: 50px;
                display: flex;
                // justify-content: space-between;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;

                .l {
                  display: flex;
                  // flex-direction: column;
                  justify-content: center;
                  // align-items: center;
                  align-items: end;

                  // .p_p1{
                  //   font-size: clamp(10px,3vw,14px);
                  // }
                  .p_p2 {
                    // font-size: clamp(10px,1.4vw,22px);
                    font-size: clamp(10px, 2.3vw, 33px);
                    letter-spacing: .01em;
                    // line-height: 1em;
                    font-family: 'a1', serif;
                    // border: solid 1px #000;
                    display: inline-block;
                    // width: 100%;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    // align-items: center;
                    align-items: baseline;
                    position: relative;
                    box-sizing: border-box;
                    margin-bottom: -7px;

                    // color: #960e16;
                    &::before {
                      content: "";
                      width: 100%;
                      height: 82%;
                      position: absolute;
                      top: 52%;
                      left: 50%;
                      transform: translate(-50%, -50%);
                      // border: solid 1px #000;
                      // border: 1px solid #960e16;

                    }

                    span {
                      font-size: clamp(10px, 2.3vw, 25px);
                      // font-size: clamp(10px,1.2vw,18px);
                      // margin-right: 12px;
                      letter-spacing: -0.2em;
                    }

                    .big {
                      font-size: clamp(10px, 5vw, 33px);
                      margin: 0 10px;
                    }
                  }

                  .p_p2_1 {
                    font-size: clamp(10px, 0.9vw, 11px);
                    letter-spacing: .1em;
                    width: 100%;
                    text-align: left;
                    margin: 3px 0 -8px;
                  }

                  .p_p2_2 {
                    font-size: clamp(10px, 2.3vw, 33px);
                    font-family: 'a1', serif;
                    letter-spacing: .05em;
                    line-height: 1.2em;

                    span {
                      font-size: clamp(10px, 2vw, 30px);
                    }

                    b {
                      font-size: clamp(10px, 2.3vw, 30px);
                    }
                  }
                }

                .r {
                  position: relative;

                  .p_p3 {
                    font-size: clamp(10px, 5.2vw, 65px);
                    letter-spacing: .01em;
                    line-height: 1;
                    margin-left: 15px;

                    span {
                      &.s1 {
                        color: #997240;
                        font-family: 'a1', serif;
                        letter-spacing: .02em;
                      }

                      &.s2 {
                        font-size: clamp(10px, 2.9vw, 38px);
                      }

                      &.s3 {
                        font-size: clamp(10px, 1.2vw, 13px);
                        position: absolute;
                        right: 0;
                        top: 10%;
                      }

                      &.s4 {
                        font-size: clamp(10px, 1vw, 12px);
                        // font-size: 10px;
                        position: absolute;
                        right: 0;
                        bottom: 0%;
                      }
                    }

                    b {
                      letter-spacing: -0.2em;
                    }
                  }
                }

                &:nth-child(2) {
                  margin-left: 50px;
                  width: 48%;
                  justify-content: flex-start;

                  .l {
                    .p_p2 {
                      padding: 0 2px;
                      font-size: clamp(10px, 1.4vw, 20px);
                      color: #960e16;

                      &::before {
                        border: 1px solid #960e16;
                      }
                    }
                  }

                  .r {
                    margin-left: 13px;
                  }
                }
              }
            }
          }
        }
      }

      .sl_all {
        position: relative;

        .badge {
          position: absolute;
          width: 11vw;
          bottom: 3%;
          left: 3%;
          min-width: 150px;
        }
      }

      .sl01 {
        background-image: url(../img/top/scroll_slid_01.jpg);

        .sl_text {
          top: 23%;
          right: 4%;
          letter-spacing: .05em;
        }
      }

      .sl02 {
        background-image: url(../img/top/scroll_slid_02.jpg);
        z-index: 1;

        .sl_text {
          bottom: 12%;
          right: 5%;
        }
      }

      // .sl03{
      //   background-image: url(../img/top/scroll_slid_03.jpg);
      //   z-index: 2;
      //   .sl_text{
      //     bottom: 10%;
      //     left: 7%;
      //   }
      // }
      .sl04 {
        background-image: url(../img/top/scroll_slid_04.jpg);
        // background-image: url(../img/top/scroll_slid_04bg_sp.jpg);
        z-index: 3;

        .sl_text {
          top: 32%;
          left: 10%;
          color: #926F2E;
          text-shadow: none;
        }

        // .sld_in{
        //   width: 60%;
        //   max-width: 1050px;
        //   position: absolute;
        //   right: 0;
        //   bottom: 0;
        // }
      }
    }

    .scroll_slid02_scrollbpx {
      width: 100%;
      height: calc(100vh*3);

      .slsc_all {
        width: 100%;
        height: calc(100%/3);
      }

      // .slsc_end{
      //   height: 30vh;
      // }

      // .scrollnav{
      //   position: fixed;
      //   left: 50%;
      //   bottom: var(--pri-height);
      //   transform: translate(-50%,0);
      //   text-align: center;
      //   z-index: 5;
      //   opacity: 1;
      //   transition: 1s;
      //   p{
      //     color: #fff;
      //     text-align: center;
      //     font-size: clamp(10px,3.6vw,22px);
      //   }
      //   img{
      //     max-width: 80px;
      //     margin: 0 auto 10px;
      //   }
      //   @keyframes indicator {
      //     0%{
      //       top: -100%;
      //     }
      //     50%{
      //       top: 100%;
      //     }
      //     100%{
      //       bottom: -100%;
      //     }
      //   }
      //   .indicator{
      //     position: relative;
      //     height: 30px;
      //     overflow: hidden;
      //     span{
      //       width: 3px;
      //       height: 100%;
      //       background: #fff;
      //       left: 50%;
      //       position: absolute;
      //       &:nth-of-type(1){
      //         animation: indicator 2s ease infinite;
      //         z-index: 5;
      //       }
      //       &:nth-of-type(2){
      //         top: 0;
      //         opacity: .5;
      //       }
      //     }
      //   }
      // }
    }


    // .scroll_slid{
    //   width: 100%;
    //   position: relative;
    //   padding: 0;
    //   .sld_box{
    //     width: 100%;
    //     position: relative;
    //     .fixBg{
    //       width: 100%;
    //       left: 0;
    //       top: 0;
    //       position: fixed;
    //       z-index: 0;
    //       &::before{
    //         position: relative;
    //       }
    //       .sldBg{
    //         width: 100%;
    //         height: 100vh;
    //         position: absolute;
    //         left: 0;
    //         top: 0;
    //         opacity: 0;
    //         .imgbox{
    //           width: 100%;
    //           height: 100%;
    //           background-repeat: no-repeat;
    //           background-position: center;
    //           background-size: cover;
    //           position: relative;
    //           p{
    //             color: #fff;
    //             font-size: clamp(10px,2.8vw,45px);
    //             text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.8);
    //             position: absolute;
    //           }
    //         }
    //         &.sldBg1{
    //           .imgbox{
    //             background-image: url(../img/top/scroll_slid_01.jpg);
    //             p{
    //               bottom: 12%;
    //               right: 5%;
    //             }
    //           }
    //         }
    //         &.sldBg2{
    //           .imgbox{
    //             background-image: url(../img/top/scroll_slid_02.jpg);
    //             p{
    //               bottom: 8%;
    //               right: 5%;
    //             }
    //           }
    //         }
    //         &.sldBg3{
    //           .imgbox{
    //             background-image: url(../img/top/scroll_slid_03.jpg);
    //             p{
    //               bottom: 10%;
    //               left: 7%;
    //             }
    //           }
    //         }
    //         &.sldBg4{
    //           .imgbox{
    //             background-image: url(../img/top/scroll_slid_04.jpg);
    //             p{
    //               top: 32%;
    //               left: 10%;
    //               color: #926F2E;
    //               text-shadow: none;
    //             }
    //           }
    //         }
    //         .img_p{
    //           position: absolute;
    //           bottom: 0;
    //           right: 0;
    //           font-size: 10px;
    //           color: #fff;
    //           padding: 10px;
    //         }
    //       }
    //     }
    //     .sld{
    //       width: 100%;
    //       height: 100vh;
    //       display: flex;
    //       justify-content: center;
    //       align-items: flex-end;
    //       opacity: 0;
    //       position: relative;
    //       z-index: 10;
    //       .imgbox{
    //          width: 100%;
    //          height: 100%;
    //          background-repeat: no-repeat;
    //          background-position: center;
    //          background-size: cover;
    //       }
    //       p{
    //         color: #fff;
    //         font-size: clamp(10px,2.8vw,45px);
    //         text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.8);
    //         position: absolute;
    //       }
    //       &.sld1{
    //         p{
    //           bottom: 40%;
    //           right: 5%;
    //         }
    //       }
    //       &.sld2{
    //         p{
    //           bottom: 8%;
    //           right: 5%;
    //         }
    //       }
    //       &.sld3{
    //         p{
    //           bottom: 10%;
    //           left: 7%;
    //         }
    //       }
    //       &.sld4{
    //         p{
    //           top: 32%;
    //           left: 10%;
    //           color: #926F2E;
    //           text-shadow: none;
    //         }
    //       }
    //     }
    //   }
    //
    //   .scrollnav{
    //     position: fixed;
    //     left: 50%;
    //     bottom: 0px;
    //     transform: translate(-50%,0);
    //     text-align: center;
    //     z-index: 5;
    //     opacity: 1;
    //     transition: 1s;
    //     &.active{
    //       opacity: 1;
    //       bottom: 0;
    //     }
    //     img{
    //       max-width: 80px;
    //       margin: 0 auto 10px;
    //     }
    //     @keyframes indicator {
    //       0%{
    //         top: -100%;
    //       }
    //       50%{
    //         top: 100%;
    //       }
    //       100%{
    //         bottom: -100%;
    //       }
    //     }
    //     .indicator{
    //       position: relative;
    //       height: 40px;
    //       overflow: hidden;
    //       span{
    //         width: 3px;
    //         height: 100%;
    //         background: #fff;
    //         left: 50%;
    //         position: absolute;
    //         &:nth-of-type(1){
    //           animation: indicator 2s ease infinite;
    //           z-index: 5;
    //         }
    //         &:nth-of-type(2){
    //           top: 0;
    //           opacity: .5;
    //         }
    //       }
    //     }
    //   }
    // }
  }

  // @media screen and (max-width: inc_set.$media_sp){
  //   .main_wrap{
  //     .main{
  //       .mainSld{
  //         .main_cntnt_01{
  //           .sld_img{
  //             .sld_in{
  //               .imgbox_in{
  //                 width: 43%;
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // /*@media screen end*/}
  // // ______________________________________
  // // ______________________________________
  // // ______________________________________

  @media screen and (max-width: inc_set.$media_sp) {
    .main_wrap {
      width: 100%;

      // height: 150vw;
      .main_mov {
        top: 0;
        // height: 100vh;
        height: 100dvh;
      }

      .main {
        width: 100%;
        // height: 150vw;
        // .grass{
        //   width: 100%;
        //   height: 150vw;
        //   .logo{
        //     width: 100%;
        //     max-width: 200px;
        //     padding: inc_set.$vw_150px 0 0;
        //   }
        // }
        // .text_area{
        //   width: 100%;
        //   top: 20%;
        //   .lead{
        //     font-size: clamp(0px, inc_set.$vw_32px, 24px);
        //   }
        //   .ttl{
        //     font-size: clamp(0px, inc_set.$vw_38px, 32px);
        //     letter-spacing: .2em;
        //   }
        // }

        .skip {
          bottom: auto;
          top: 58px;
          padding: 5px 10px 0 10px;
        }

        // .mainSld{
        //     .main_cntnt{
        //       height: 150vw;
        //     .sld_img{
        //         .img_text{
        //           top: 16%;
        //           p{
        //             font-size: clamp(10px,5vw,25px);
        //           }
        //         }
        //       }
        //     }
        //     .main_cntnt_01{
        //       .sld_img{
        //         background-image: url(../img/top/slide_01bg_sp.png);
        //         .sld_in{
        //           width: 95%;
        //           ._sp{
        //             display: block !important;
        //           }
        //           ._pc{
        //             display: none !important;
        //           }
        //           img{
        //             height: auto;
        //             width: 100%;
        //           }
        //         }
        //       }
        //     }
        //     .main_cntnt_02{
        //       .sld_img{
        //         background-image: url(../img/top/slide_02_sp.png);
        //       }
        //     }
        //   }
      }


      .scroll_slid02_scrollbpx {
        // height: 240vh;
        height: 180dvh;

        // height: 180vh;
        // width: 100%;
        // height: calc(100vh*5);
        // .slsc_all{
        //   width: 100%;
        //   height: calc(100%/5);
        // }
        .scrollnav {
          // bottom: calc(40px + 220px);
          bottom: calc(40px + var(--pri-height_sp));
          filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.9));

          img {
            max-width: 50px;
            margin: 0 auto 2px;
          }

          .indicator {
            height: 20px;

            span {
              width: 2px;
            }
          }
        }
      }



      .scroll_slid02 {
        width: 100%;
        // height: 100dvh;
        height: calc(100dvh - 40px);
        // height: calc(100vh - 40px);
        position: fixed;
        top: 0;
        left: 0;

        >div {
          background-size: cover;
          // height: calc(100vh - (40px + var(--pri-height_sp)) );
          // height: calc(100dvh - (40px + var(--pri-height_sp)) );
          // height: calc(100dvh - (40px + var(--pri-height_sp)) );
          // height: calc(65dvh - 40px);
          // height: calc(66dvh - 40px);
          // height: calc(75dvh - 40px);
          height: calc(79dvh - 40px);

          .sl_text {
            font-size: clamp(10px, 5.8vw, 30px);
            letter-spacing: .1em;
            transform: translate(-50%, 0);
            width: 100%;
            text-align: center;
            z-index: 1;
            text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.8), 0px 0px 5px rgba(0, 0, 0, 0.8);
          }

          .img_p {
            text-align: right;
            bottom: 3dvh;
          }

          .sld_in {
            position: absolute;
            top: 56%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;

            .img_p {
              position: absolute;
              // bottom: 0;
              bottom: 3dvh;
              right: 0;
              font-size: 10px;
              color: #fff;
              padding: 10px;
              width: auto;
              left: auto;
              transform: translate(0, 0);
            }
          }

          &.ob {
            // height: calc(0px + var(--pri-height_sp));
            // height: 35dvh;
            height: 25dvh;

            .ob_p1 {
              text-align: center;
              // height: 15vh;
              height: 15dvh;
              // height: 11dvh;

              p {
                font-size: clamp(10px, 5.8vw, 30px);
                line-height: 1.7em;
                letter-spacing: .18em;
                font-weight: normal;
                padding-bottom: 0;

                span {
                  font-size: clamp(10px, 8vw, 40px);
                }
              }
            }

            .price_area {
              // padding: 10px 0;
              // height: 20vh;
              // height: 20dvh;
              height: 14dvh;

              .price_in {
                justify-content: center;
                width: 100%;
                flex-wrap: wrap;

                .flex {
                  width: 100%;
                  margin: 5px auto;
                  max-width: 510px;

                  .l {
                    // margin-top: 1vw;
                    margin-top: -2vw;
                    flex-direction: row;
                    // width: 43%;
                    width: 46%;
                    // justify-content: space-between;
                    justify-content: center;
                    align-items: center;

                    .p_p2 {
                      font-size: clamp(10px, 5.3vw, 24px);
                      // width: 45%;
                      width: 56%;
                      // width: 43%;
                      margin-bottom: -12px;

                      &::before {
                        // content: "";
                        // width: 100%;
                        // height: 100%;
                        // position: absolute;
                        top: 50%;
                        // left: 50%;
                        // transform: translate(-50%,-50%);
                        // border: solid 1px #000;
                      }

                      span {
                        font-size: clamp(10px, 5.4vw, 16px);
                      }

                      .big {
                        margin: 0 5px;
                      }
                    }

                    .p_p2_1 {
                      margin: 5px 0 -5px;
                    }

                    .p_p2_2 {
                      font-size: clamp(10px, 6vw, 35px);

                      span {
                        font-size: clamp(10px, 4vw, 28px);
                      }

                      b {
                        font-size: clamp(10px, 4vw, 32px);
                      }
                    }
                  }

                  .r {
                    .p_p3 {
                      font-size: clamp(10px, 9.8vw, 58px);
                      margin-left: 3px;

                      span {
                        &.s2 {
                          font-size: clamp(10px, 5.5vw, 30px);
                        }

                        &.s3 {
                          font-size: clamp(10px, 2vw, 13px);
                          top: 2px;
                          // right: 5px;
                          // right: -8px;
                          right: -3px;
                        }

                        &.s4 {
                          bottom: -5px;
                          font-size: clamp(10px, 2vw, 13px);
                        }
                      }
                    }
                  }

                  &:nth-child(2) {
                    width: 88%;
                    margin: 5px auto;
                    max-width: 510px;

                    .l {
                      .p_p2 {
                        padding: 2px;
                        font-size: clamp(10px, 3.4vw, 20px);
                      }
                    }

                    .r {
                      margin-left: 2.2vw;

                      .p_p3 {
                        span.s3 {
                          // right: -8px;
                          right: -29px;
                        }

                        span.s4 {
                          // right: -7px;
                          right: -34px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .sl_all {
          .badge {
            width: 30vw;
            min-width: unset;
            max-width: 200px;
            bottom: 11dvh;
            left: 6%;
          }
        }

        .sl04 {
          .badge {
            // bottom: 17%;
            // left: 6%;
          }
        }

        .sl01 {
          background-image: url(../img/top/scroll_slid_01_sp.jpg);

          // background-size: cover;
          // background-position: 20% 50%;
          // background-image: url(../img/top/scroll_slid_01bg_sp.jpg);
          .sl_text {
            top: 22%;
            right: auto;
            left: 50%;
          }
        }

        .sl02 {
          background-image: url(../img/top/scroll_slid_02_sp.jpg);
          // background-size: cover;
          // background-position: 30% 50%;
          // background-image: url(../img/top/scroll_slid_02bg_sp.jpg);
          z-index: 1;

          .sl_text {
            // bottom: clamp(7%,10vw,12%);
            bottom: auto;
            top: 25%;
            right: auto;
            left: 50%;
          }
        }

        .sl03 {
          background-image: url(../img/top/scroll_slid_03bg_sp.jpg);
          z-index: 2;

          .sl_text {
            bottom: 16%;
            left: 50%;
          }
        }

        .sl04 {
          background-image: url(../img/top/scroll_slid_04bg_sp.jpg);
          background-position: center;
          z-index: 3;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          justify-content: flex-end;

          .sl_text {
            font-size: clamp(10px, 6.8vw, 35px);
            position: relative;
            top: auto;
            left: auto;
            transform: translate(0, 0);
            margin-bottom: 30px;
            margin-right: -15px;
            letter-spacing: .01em;
          }

          .sld_in {
            position: relative;
            top: auto;
            left: auto;
            transform: translate(0, 0);
            width: 100%;

            // transform: translate(-50%,0);
            img {
              vertical-align: bottom;
            }
          }
        }
      }
    }

    /*@media screen end*/
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________


  .top_wrap {
    background: #006070;
    position: relative;
    z-index: 2;

    .zeh_wrap {
      background: linear-gradient(90deg, #005564, #009C91, #005564);
      padding: 35px 0 30px;
      height: 160px;
      position: relative;

      .p3 {
        // text-align: center;
        font-size: clamp(10px, 4.3vw, 22px);
        color: #fff;
        letter-spacing: .08em;
        position: absolute;
        left: 51%;
        transform: translate(-50%, 0);
        width: 100%;
        text-align: center;

        // max-width: 480px;
        // width: 87%;
        // margin: 0 auto;
        .bgWh {
          color: #006070;
          padding: 14px 40px 4px 40px;
          margin: 5px 20px 0 5px;
          background: #fff;
          font-size: clamp(10px, 8vw, 42px);
          display: inline-block;
          filter: drop-shadow(3px 0 5px rgba(#000, .5));
        }

        .s {
          font-size: clamp(10px, 4vw, 20px);
          letter-spacing: 0;
        }
      }

      .zeh_btn {
        text-align: center;
        border: solid 1px #fff;
        max-width: 320px;
        width: 60%;
        // margin: 30px auto 0;
        padding: 12px 0 7px;
        color: #fff;
        // position: relative;
        transition: 1s;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        bottom: 12%;

        p {
          font-size: clamp(10px, 3.7vw, 16px);
          transition: 1s;
        }

        a {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
        }

        .arrow {
          width: 15%;
          position: absolute;
          top: 50%;
          right: 5%;
          transform: translate(0, -50%);
          transition: 1s;
        }

        &:hover {
          background: #fff;

          p {
            color: #006070;
          }
        }
      }
    }

    .price_area {
      background: #E7EAE6;
      padding: 25px 0;
      position: relative;
      z-index: 0;

      .price_in {
        max-width: 1150px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        position: relative;

        // &::before{
        //   content: "";
        //   width: 1px;
        //   height: 80%;
        //   position: absolute;
        //   top: 50%;
        //   left: 51%;
        //   transform: translate(-50%,-50%) rotateZ(35deg);
        //   background: #000;
        // }
        .flex {
          width: 50%;
          display: flex;
          justify-content: space-evenly;
          align-items: baseline;
          flex-wrap: wrap;

          .l {

            // .p_p1{
            //   font-size: clamp(10px,3vw,14px);
            // }
            .p_p2 {
              font-size: clamp(10px, 7.5vw, 46px);
              letter-spacing: .01em;
              line-height: 1;
              font-family: 'a1', serif;

              span {
                font-size: clamp(10px, 5.5vw, 30px);
                margin-right: 12px;
              }
            }
          }

          .r {
            position: relative;

            .p_p3 {
              font-size: clamp(10px, 14vw, 75px);
              letter-spacing: .01em;
              line-height: 1;

              span {
                &.s1 {
                  color: #997341;
                  font-family: 'a1', serif;
                  letter-spacing: .02em;
                }

                &.s2 {
                  font-size: clamp(10px, 7vw, 42px);
                }

                &.s3 {
                  font-size: clamp(10px, 2vw, 13px);
                  position: absolute;
                  right: 0;
                  top: 15px;
                }

                &.s4 {
                  font-size: 10px;
                  position: absolute;
                  right: 0;
                  bottom: 5px;
                }
              }

              b {
                letter-spacing: -0.2em;
              }
            }
          }
        }
      }
    }

    .sec {
      padding: 100px 0;
    }

    .sec1 {
      position: relative;
      z-index: 10;
      padding: 25px 0 100px;
      background: #FFFFF2;

      .notice_box {
        // width: 95%;
        // max-width: 1000px;
        margin: 0 auto 80px;
        background-image: url(../img/top/notice_bg.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        position: relative;


        .text_area {
          padding: 85px 0 100px;
          text-align: center;
          position: relative;

          &::before {
            content: "";
            width: 100%;
            height: 100%;
            background: #f2f2f2;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
          }

          .bnr {
            margin: 5% auto 0;
            width: 80%;
            max-width: 750px;
            filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.6));
          }

          p {
            text-align: center;
            display: inline-block;
          }

          .gld {
            background: linear-gradient(90deg, #806125 0%, #A5865A 50%, #806125 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: rgba(0, 0, 0, 0);
          }

          .p1 {
            font-size: clamp(10px, 5vw, 28px);
            margin-bottom: 30px;
          }

          .p2 {
            font-size: clamp(10px, 7.5vw, 38px);
            letter-spacing: .1em;
            background: linear-gradient(90deg, #9C5B4D 0%, #BD9765 50%, #9C5B4D 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: rgba(0, 0, 0, 0);

            span {
              &.s1 {
                font-size: clamp(0px, 4vw, 30px);
              }

              &.s2 {
                letter-spacing: -0.1em;
              }

              &.mark {
                margin-left: -15px;
              }
            }
          }

          .bnt_flex {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            max-width: 800px;
            margin: 0 auto;


            .cv {
              // max-width: 400px;
              width: calc(90%/2);
              margin: 50px auto 0;
              filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.6));

              p {
                color: #006070;
                display: block;
                font-size: clamp(0px, inc_set.$vw_28px, 20px);
              }

              &.req {
                background: linear-gradient(90deg, #c2c2c2 0%, #fff 50%, #c2c2c2 100%);
                border: solid 1px #fff;

                &:hover {
                  background: linear-gradient(90deg, #fff 0%, #fff 50%, #fff 100%);
                }
              }

              &.rev {
                background: linear-gradient(90deg, #D3A685 0%, #fff 50%, #D3A685 100%);
                border: solid 1px #fff;

                &:hover {
                  background: linear-gradient(90deg, #fff 0%, #fff 50%, #fff 100%);
                }
              }
            }
          }
        }
      }

      .logo {
        width: 95%;
        max-width: 400px;
        margin: 0 auto;
        padding: 0 40px 0 0;
      }

      p {
        color: #fff;
      }

      .lead_box {
        width: 95%;
        max-width: 1000px;
        margin: 50px auto 0;
        text-align: center;

        p {
          display: inline-block;
          margin: 0 0 10px;
          position: relative;
        }

        // .gld{
        //   background: linear-gradient(90deg, #ccc398 0%, #ffffff 50%, #cbc295 100%);
        //   -webkit-background-clip: text;
        //   -webkit-text-fill-color: rgba(0,0,0,0);
        //   padding: 5px 0 0;
        // }
        .rlv {
          position: relative;
        }

        .ab {
          position: absolute;
          left: 0;
          bottom: -10px;
        }

        .ttl {
          font-size: clamp(10px, 5.5vw, 32px);
          margin: 0 0 20px;
          color: #534741;
        }

        // .p2{
        //   font-size: clamp(0px, inc_set.$vw_32px, 20px);
        // }
      }

      .merit_box {
        width: 95%;
        max-width: 1000px;
        margin: 0 auto 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        .merit {
          width: calc(95%/2);
          // height: 70px;
          margin: 12px 0;
          // padding: 10px 0 0;
          box-sizing: border-box;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          // background: linear-gradient(90deg, #806125 0%, #a5865a 50%, #806125 100%);
          position: relative;

          // &::before{
          //   content: "";
          //   display: block;
          //   width: 100%;
          //   height: 100%;
          //   position: absolute;
          //   left: 0;
          //   top: 0;
          //   background-image: url(../img/top/merit_bg.png);
          //   background-repeat: no-repeat;
          //   background-position: center;
          //   background-size: calc(100% - 5px) calc(100% - 5px);
          // }
          &.pdNo {
            padding: 0;
            width: 100%;

            p {
              line-height: 1em;
            }
          }

          p {
            // color: #fff;
            background: linear-gradient(90deg, #005564, #009C91, #005564);
            -webkit-background-clip: text;
            -webkit-text-fill-color: rgba(0, 0, 0, 0);

            .span0 {
              font-size: clamp(10px, 3.5vw, 22px);
            }

            .span1 {
              font-size: clamp(10px, 6vw, 32px);
            }

            .span2 {
              font-size: clamp(10px, inc_set.$vw_62px, 45px);
              font-family: "a1", serif;
            }

            .span3 {
              // font-size: clamp(10px,8.3vw,35px);
              font-family: "a1", serif;
            }
          }

          .p1 {
            font-size: clamp(10px, inc_set.$vw_24px, 16px);
          }

          .p2 {
            font-size: clamp(10px, 4.5vw, 25px);
          }

          .p3 {
            font-size: clamp(10px, 3.5vw, 32px);

            .big {
              font-size: clamp(10px, inc_set.$vw_62px, 45px);
            }

            .span0 {
              font-size: clamp(10px, 4vw, 20px);
              letter-spacing: .05em;
            }
          }

          &:last-of-type {
            &::before {
              content: "";
              height: 60%;
              border: 1px solid #005564;
              border-image: linear-gradient(90deg, #005564, #009C91, #005564);
              border-image-slice: 1;
              position: absolute;
              left: 0;
              top: 50%;
              transform: translate(0, -50%);
            }
          }
        }
      }

      .bnr {
        width: 95%;
        max-width: 800px;
        margin: 50px auto 0;
        cursor: pointer;
      }

      .bnr_wrap {
        max-width: 800px;
        margin: 50px auto 0;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;

        .bnr {
          width: 49%;
          margin: 0;
        }
      }

      .bnt_p {
        font-size: 10px;
        margin-top: 2px;
        color: #000;
      }

      .movie_bg {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .8);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
        display: none;
        // opacity: 0;
        z-index: -100;

        &.show {
          // opacity: 1;
          display: flex;
          z-index: 1000;
        }

        .movie_bg_in {
          max-width: 820px;
          width: 97%;
          position: relative;

          video {
            width: 100%;
            height: auto;
          }

          .js-modal-close-cp {
            position: absolute;
            top: -10px;
            right: -6%;
            width: 55px;
            height: 55px;
            z-index: 999;

            // cursor: pointer;
            span {
              width: 28px;
              height: 2px;
              position: absolute;
              left: 50%;
              top: 50%;
              background: #fff;

              &:nth-of-type(1) {
                transform: translate(-50%, -50%) rotate(45deg);
              }

              &:nth-of-type(2) {
                transform: translate(-50%, -50%) rotate(-45deg);
              }
            }
          }
        }
      }
    }


    .sec2 {
      padding-top: 0;

      .menu_area {
        .flex {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;

          &.row_r {
            flex-direction: row-reverse;
          }
        }

        .menu {
          background: #ECEEEF;
          margin-bottom: 30px;

          .text_area {
            width: 100%;
            max-width: 440px;
            margin: 0 auto;

            &.scrollin {
              .m_p1 {

                &::before,
                &::after {
                  opacity: 1;
                  transform: translate(-5px, -10px);
                }
              }
            }

            .m_p1 {
              font-size: clamp(10px, 11vw, 62px);
              letter-spacing: .01em;
              color: #006070;
              margin-bottom: 30px;
              text-align: center;
              position: relative;

              &::before,
              &::after {
                content: "";
                display: block;
                position: absolute;
                background-repeat: no-repeat;
                background-position: center;
                background-size: 100% 100%;
                transition: 1s;
                opacity: 0;
              }

              &::before {
                width: 80px;
                height: 80px;
                left: 0;
                top: 0;
                background-image: url(../img/concept/sec2_txtDeco_left.png);
                transform: translate(20px, 20px);
              }

              &::after {
                width: 60px;
                height: 60px;
                right: 0;
                bottom: 0;
                background-image: url(../img/concept/sec2_txtDeco_right.png);
                transform: translate(-20px, -20px);
              }
            }

            .m_p2 {
              font-size: clamp(10px, 3.8vw, 18px);
              line-height: 1.8em;
            }

            .m_p3 {
              font-size: clamp(10px, 3.5vw, 16px);
              color: #AD9B74;
              margin-top: 20px;
            }

            .m_btn {
              margin-top: 45px;
              justify-content: flex-start;
              display: inline-block;
              position: relative;

              p {
                display: inline-block;
                padding-right: 20px;
                transition: .5s;
              }

              .arrow {
                display: inline-block;
                width: 23px;
                height: 23px;
                border-radius: 50%;
                border: solid 1px #333333;
                vertical-align: bottom;
                position: relative;
                overflow: hidden;

                &::before {
                  content: "";
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                  background: #006070;
                  transform: translateX(-100%);
                  transition: .5s;
                }

                span {
                  content: "";
                  background: #333333;
                  height: 1px;
                  position: absolute;

                  &:first-of-type {
                    width: 60%;
                    top: 55%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                  }

                  &:last-of-type {
                    width: 35%;
                    top: 9px;
                    right: 4px;
                    transform: rotate(40deg);
                  }
                }
              }

              a {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
              }

              &:hover {
                p {
                  letter-spacing: .25em;
                }

                .arrow {
                  border: solid 1px #006070;

                  &::before {
                    transform: translateX(0%);
                  }

                  span {
                    background: #fff;
                  }
                }
              }
            }
          }

          .img_area {
            width: 46%;

            .img {
              position: relative;
              overflow: hidden;

              img {
                vertical-align: bottom;
                transform: scale(1.1);
                transition: 2s;
              }

              &.scrollin {
                img {
                  transform: scale(1);
                }

                &::before {
                  top: 180%;
                }
              }

              &::before {
                content: "";
                width: 100%;
                height: 200%;
                position: absolute;
                background: #508879;
                top: 0;
                left: 0;
                transition: 2s;
                z-index: 1;
                transform: skewY(15deg) translateY(-20%);
              }

              .img_p {
                position: absolute;

                &.acc_p1 {
                  top: 0;
                  left: 30px;
                  color: #fff;
                  font-size: clamp(10px, 11vw, 62px);
                }

                &.loca_p1 {
                  bottom: 0;
                  right: 0;
                  color: #fff;
                  font-size: 10px;
                  padding: 3px;
                }
              }

              .acc_p2 {
                text-align: right;
                font-size: 10px;
                letter-spacing: .1em;
              }
            }

            .img_w50 {
              width: 48%;
            }
          }

          &.access {
            padding: 30px 0 30px 50px;

            .text_area {
              margin: 0 auto 0 10vw;
            }

            .img_area {
              width: 48%;
              align-items: flex-start;

              .img {
                &.scrollin {
                  &.img01 {
                    &::before {
                      top: -180%;
                    }
                  }
                }
              }
            }
          }

          &.location {
            .img_area {
              width: 42%;

              .img {
                &.scrollin {
                  &.img01 {
                    &::before {
                      top: -180%;
                    }
                  }
                }
              }
            }
          }

          // &.plan{
          //   .text_area{
          //     margin: 0 auto 0 12vw;
          //   }
          // }
          &.plan,
          &.modelroom {
            .text_area {
              margin: 0 auto 0 12vw;
            }
          }

          &.modelroom {
            .text_area {
              max-width: 580px;
            }
          }
        }
      }
    }


    .sec3 {
      width: 100%;
      background: #eaede9;
      padding: 50px 0;
      z-index: 10;
      position: relative;

      .info {
        width: 95%;
        margin: 0 auto;
        max-width: 1000px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .infottl {
          width: 25%;

          p {
            font-size: clamp(10px, 7vw, 35px);
          }
        }

        .infotxt {
          width: 60%;

          .infoin {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 0;
            border-top: 1px solid #505050;

            &:last-child {
              border-bottom: 1px solid #505050;
            }

            p {
              font-size: clamp(0px, inc_set.$vw_28px, 18px);
              text-align: left;
            }

            .date {
              width: 15%;
            }

            .text {
              width: 80%;
            }
          }
        }
      }
    }
  }

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp) {
    .top_wrap {
      .zeh_wrap {
        padding: 20px 0 15px;
        height: 40vw;
        max-height: 180px;

        .p3 {
          letter-spacing: .01em;
          line-height: 1.8em;
          max-width: 480px;
          left: 52%;

          .bgWh {
            padding: 10px 6.5vw 4px 6.5vw;
            margin-right: 10px;
            margin-left: 3px;
          }
        }

        // .zeh_btn{
        //   margin-top: 20px;
        // }
      }

      .price_area {
        padding: 20px 0;

        .price_in {
          justify-content: center;

          // &::before{
          //   width: 100%;
          //   height: 1px;
          //   top: 50%;
          //   left: 50%;
          //   transform: translate(-50%,-50%) rotateZ(0deg);
          // }
          .flex {
            width: calc(95%/1);
            margin: 10px auto;
            max-width: 600px;

            .r {
              .p_p3 {
                span {
                  &.s3 {
                    top: 10px;
                    right: -5px;
                  }

                  &.s4 {
                    bottom: -2px;
                  }
                }
              }
            }
          }
        }
      }

      .sec {
        padding: inc_set.$vw_100px 0;
      }

      .sec1 {
        padding: 8.3vw 0 inc_set.$vw_100px;

        .notice_box {
          // width: 95%;
          margin: 0 auto 50px;

          .text_area {
            padding: 9.5vw 0;

            .bnr {
              margin: 10% auto 0;
            }

            .p2 {
              letter-spacing: .01em;

              span {
                &.s2 {
                  letter-spacing: -0.2em;
                }

                &.mark {
                  margin-left: -5px;
                }
              }
            }

            .bnt_flex {
              margin: 10px auto 0;

              .cv {
                width: calc(80%/1);
                max-width: 280px;
                margin: 30px auto 0;
              }
            }
          }
        }

        .logo {
          max-width: 250px;
          padding: 0 20px 0 0;
        }

        .lead_box {
          margin: 10px auto 0;

          p {
            margin: 0 0 5px;
          }

          .ab {
            bottom: -9px;
          }
        }

        .merit_box {
          margin: 0 auto inc_set.$vw_50px;

          .merit {
            width: 100%;
            border-bottom: 1px solid #005564;
            border-image: linear-gradient(90deg, #005564, #009C91, #005564);
            border-image-slice: 1;

            // height: 60px;
            // margin: 5px 0;
            // padding: 5px 0 0;
            p {
              padding-bottom: 12px;
            }

            &:last-of-type {
              &::before {
                display: none;
              }
            }

            .p3 {
              font-size: clamp(10px, 5.8vw, 32px);
            }
          }
        }

        .bnr {
          margin: inc_set.$vw_50px auto 0;
        }

        .bnr_wrap {
          // max-width: 800px;
          width: 95%;
          margin: 0 auto;

          .bnr {
            // width: 95%;
            margin: inc_set.$vw_50px auto 0;
          }
        }

        .movie_bg {
          .movie_bg_in {
            .js-modal-close-cp {
              top: -40px;
              right: 0;
              width: 40px;
              height: 40px;
            }
          }
        }
      }


      .sec2 {
        padding-top: 0;
        padding-bottom: 5vw;

        .menu_area {
          .menu {
            margin-bottom: 15px;
            padding: 18px 0;

            .text_area {
              width: 100%;
              text-align: center;

              .m_p1 {
                margin-bottom: 10px;

                &::before {
                  width: 70px;
                  height: 70px;
                }

                &::after {
                  width: 50px;
                  height: 50px;
                }
              }

              .m_p2 {
                line-height: 1.5em;
              }

              .m_btn {
                margin: 10px 0 15px;

                p {
                  padding-right: 20px;
                }

                .arrow {
                  width: 18px;
                  height: 18px;
                  vertical-align: top;

                  span {
                    &:last-of-type {
                      top: 8px;
                      right: 2px;
                    }
                  }
                }
              }
            }

            .img_area {
              width: 100%;
              margin: 0 auto;

              .img {

                // transition: 1.5s;
                // &::before{
                //   transition: 1.5s;
                // }
                .img_p {
                  &.acc_p1 {
                    left: 10px;
                  }
                }
              }

              .img_w50 {
                width: 48%;
              }
            }

            &.access {
              padding: 18px 0 12px;

              .text_area {
                margin: 0 auto;
              }

              .img_area {
                width: 100%;
              }
            }

            &.location {
              .img_area {
                width: 85%;
              }
            }

            // &.plan{
            //   .text_area{
            //     margin: 0 auto;
            //   }
            // }
            &.plan,
            &.modelroom {
              .text_area {
                margin: 0 auto;
              }
            }
          }
        }
      }


      .sec3 {
        padding: inc_set.$vw_50px 0;

        .info {
          width: 95%;
          margin: 0 auto;
          max-width: 1000px;
          flex-wrap: wrap;

          .infottl {
            width: 100%;
            text-align: center;
            // max-width: 150px;
            margin: 0 auto 10px;
          }

          .infotxt {
            width: 100%;

            .infoin {
              padding: 15px 0 10px;

              .date {
                width: 20%;
              }

              .text {
                width: 75%;
              }
            }
          }
        }
      }

    }

    /*@media screen end*/
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp) {

    /*@media screen end*/
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp) {

    /*@media screen end*/
  }

  // ______________________________________
  // ______________________________________
  // ______________________________________
}