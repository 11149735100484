@use "setting" as inc_set;

header {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  padding: 10px 0;
  background: rgba(255, 255, 255, 0.5);
  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    backdrop-filter: saturate(90%) blur(10px);
  }
  .top_header {
    width: 95%;
    margin: 0 auto;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    z-index: 5;
    .logo {
      width: 30%;
      max-width: 300px;
    }
    .link_box {
      width: 70%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      div {
        margin: 0 5px;
        p {
          letter-spacing: 0.15em;
          // font-size: clamp(0px, inc_set.$vw_20px, 14px);
          font-size: clamp(10px,2.8vw,14px);
        }
      }
      .link {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        transition: 0.5s;
        &:hover {
          opacity: 0.5;
        }
        &::before {
          content: "";
          display: block;
          width: 10px;
          height: 10px;
          transform: rotate(45deg);
          background: #508879;
          margin: 0 5px 5px;
        }
      }
      .cv {
        width: 25%;
        max-width: 150px;
      }
    }
  }

  .bottom_header {
    width: 95%;
    max-width: 1380px;
    margin: 10px auto 0;
    position: relative;
    z-index: 5;
    nav {
      width: 100%;
      ul {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        li {
          width: calc(100% / 9);
          text-align: center;
          box-sizing: border-box;
          border-left: 1px solid #505050;
          position: relative;
          &.none {
            pointer-events: none;
            p {
              opacity: 0.3;
            }
          }
          &:hover {
            p {
              opacity: 0.5;
            }
          }
          &:last-child {
            border-right: 1px solid #505050;
            // background: rgba(#0768ad,.8);
            &::after{
              content: "";
              width: 100%;
              height: 170%;
              background: rgba(#0768ad,.8);
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%,-50%);
              z-index: -1;
            }
            p{
              color: #fff;
            }
          }
          .new {
            color: #fff;
            bottom: -8px;
            left: 50%;
            transform: translate(-50%, 0);
            padding: 3px 5px 0 5px;
            font-size: clamp(0px, inc_set.$vw_20px, 10px);
            text-align: center;
            background: #9e0000;
            position: absolute;
          }
          p {
            padding: 10px 0 5px;
            font-size: clamp(0px, inc_set.$vw_20px, 14px);
            transition: 0.5s;
          }
          &:nth-child(2) {
            border-right: 1px solid #505050;
          }
          &:nth-child(3) {
            border-left: none;
            p {
              font-size: clamp(0px,4vw,12px);
            }
          }
        }
      }
    }
  }
}
// ______________________________________
@media screen and (max-width: inc_set.$media_sp) {
  header {
    width: 100%;
    padding: 10px 0;
    transition: 2s;
    &.navAct {
      background: #fff;
    }
    .top_header {
      width: 95%;
      z-index: 5;
      position: relative;
      .logo {
        width: 70%;
        max-width: 240px;
      }
      .link_box {
        width: 100%;
        left: 0;
        bottom: 0;
        position: fixed;
        background: #fff;
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        div {
          width: calc(100% / 3);
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin: 0;
          // background: #006070;
          border-left: 1px solid rgba(255, 255, 255, 0.2);
          border-right: 1px solid rgba(255, 255, 255, 0.2);
          box-sizing: border-box;
          text-align: center;
          img {
            width: auto;
            height: 20px;
            margin: 0 auto;
          }
          p {
            padding: 5px 0 0;
          }
          &:nth-of-type(1) {
            border-right: 1px solid #006070;
          }
          &:nth-of-type(1),
          &:nth-of-type(2) {
            p {
              color: #006070;
            }
          }
        }
        .link {
          justify-content: center;
          &::before {
            display: none;
          }
          p {
            color: #fff;
            text-align: center;
          }
        }
        .cv {
          width: calc(100% / 3);
          max-width: none;
          &.rev {
            border: none;
          }
        }
      }

      .g_menu_btn {
        width: 100%;
        max-width: 30px;
        height: 20px;
        position: relative;
        cursor: pointer;
        &.active {
          span {
            &:nth-of-type(1) {
              top: 48%;
              transform: translate(-50%, -50%) rotate(45deg);
            }
            &:nth-of-type(2) {
              opacity: 0;
            }
            &:nth-of-type(3) {
              bottom: 48%;
              transform: translate(-50%, -50%) rotate(-45deg);
            }
          }
        }
        span {
          width: 100%;
          height: 1px;
          position: absolute;
          left: 50%;
          background: #333;
          transition: 1s;
          &:nth-of-type(1) {
            top: 0;
            transform: translate(-50%, -50%);
          }
          &:nth-of-type(2) {
            top: 50%;
            transform: translate(-50%, -50%);
          }
          &:nth-of-type(3) {
            bottom: 0;
            transform: translate(-50%, -50%);
          }
        }
      }
    }

    .bottom_header {
      width: 100%;
      height: 0;
      left: 0;
      top: auto;
      bottom: 41px;
      margin: 0 auto;
      position: fixed;
      background: #006070;
      z-index: 2;
      transition: 1s;
      overflow: hidden;
      &.active {
        height: calc(100vh - 54px - 41px);
        left: 0;
        top: 54px;
        bottom: auto;
        nav {
          opacity: 1;
        }
        .menu_txt {
          opacity: 0.5;
          transform: translate(0, 0);
        }
      }
      .menu_txt {
        position: absolute;
        bottom: -23px;
        right: 0;
        color: #fff;
        font-size: 83px;
        line-height: 1em;
        opacity: 0;
        transform: translate(0, 100px);
        transition: 1s 1s;
      }
      nav {
        width: 100%;
        opacity: 0;
        transition: 1s 1s;
        padding: 9 0 0;
        box-sizing: border-box;
        ul {
          width: 100%;
          flex-wrap: wrap;
          li {
            width: calc(100% / 2);
            height: 12vh;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            &::before {
              content: "";
              display: block;
              width: 100%;
              height: 100%;
              position: absolute;
              left: 0;
              top: 0;
              background-image: url(../img/common/navActiveBg.png);
              background-repeat: no-repeat;
              background-position: center;
              background-size: 100% 100%;
              opacity: 0;
            }
            &.active {
              &::before {
                opacity: 1;
              }
            }
            &:last-child {
              border: none;
              background: #fff;
              &::after{
                display: none;
              }
              p{
                color: #0768ad;
              }
            }
            .new {
              // top: 20px;
              top: 12px;
              left: auto;
              right: 30px;
              transform: translate(0, 0);
              bottom: auto;
              padding: 3px 5px 0 5px;
            }
            p {
              color: #fff;
              padding: 5px 0 0;
              // font-size: clamp(0px, inc_set.$vw_28px, 14px);
              transition: 0.5s;
            }
          }
        }
      }
    }
  }
  /*@media screen end*/
}
// ______________________________________
// ______________________________________
// ______________________________________
