@use "inc/setting" as inc_set;
@use "inc/reset" as inc_re;

#plan{
  .main_wrap{
    .imgbox{
      background-image: url(../img/plan/main.jpg);
    }
  }

  .bnr {
    width: 90%;
    max-width: 1000px;
    margin: 50px auto;
    cursor: pointer;
  }



  .plan_wrap{
    width: 100%;
    background-image: url(../img/plan/bg.png);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top;
    background-color: #e2e4e5;
  }
  .sec1{
    .lead_box{
      padding: 50px 0 0;
      p{
        text-align: center;
        line-height: 2em;
      }
      .gld{
        color: #8f805a;
      }
      .lead{
        color: #005959;
        margin: 0 0 20px;
        letter-spacing: .1em;
        .span1{
          font-size: clamp(0px, inc_set.$vw_60px, 42px);
        }
      }
    }

    .parse_slide{
      max-width: 1000px;
      width: 95%;
      margin: 0 auto 80px;
      position: relative;
      .slick-dots{
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 10px;
        li{
          width: 14px;
          height: 14px;
          margin: 0 5px;
          border-radius: 50%;
          background: transparent;
          border: solid 1px #333;
          color: transparent;
          cursor: pointer;
          button{
            display: none;
          }
          &.slick-active{
            background: #333;
          }
        }
      }
    }
    .parse_wrap{
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      background: #fff;
      .text_area{
        width: 35%;
        padding: 50px 0 50px 20px;
        position: relative;
        p{
          color: #006070;
          font-size: clamp(10px,3.8vw,17px);
          letter-spacing: .01em;
          padding: 30px 0;
        }
      }
      .img_area{
        width: 60%;
        position: relative;
        &::before{
          content: "";
          width: 20%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          background: linear-gradient(90deg,rgba(255,255,255,1),rgba(0,0,0,.0));
        }
        img{
          vertical-align: bottom;
        }
        p{
          position: absolute;
          bottom: 0;
          right: 0;
          font-size: 10px;
          color: #fff;
          padding: 5px;
          text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.8);
        }
      }
    }


    .type_ctgry{
      width: 95%;
      max-width: 1000px;
      margin: 0 auto 50px;
      background: #fff;
      p{
        color: #006070;
        font-size: clamp(0px, inc_set.$vw_32px, 24px);
        text-align: center;
      }
    }

    .type_list{
      width: 95%;
      max-width: 1000px;
      margin: 0 auto;
      padding: 0 0 100px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      .dummy{
        width: calc(95%/3);
        opacity: 0;
      }
      .list{
        width: calc(95%/3);
        margin: 0 0 40px;
        padding: 20px;
        box-sizing: border-box;
        text-align: center;
        background: #006070;
        position: relative;
        transition: .5s;
        &::before{
          content: "";
          display: block;
          width: calc(100% - 10px);
          height: calc(100% - 10px);
          border: 1px solid #fff;
          left: 50%;
          top: 50%;
          position: absolute;
          transform: translate(-50%,-50%);
        }
        &.lmt{
          pointer-events: none;
        }
        &:hover{
          box-shadow: 5px 5px 20px rgba(0,0,0,.5);
          .btn{
            &::after{
              opacity: 0;
            }
            &::before{
              background: #d7c586;
            }
            p{
              color: #d7c586;
            }
          }
        }
        &.ct{
          margin: 0 auto;
        }
        &.cs{
          margin: 0 auto;
          padding: 50px 0;
          pointer-events: none;
          .deco{
            width: 80%;
            position: absolute;
            left: 0px;
            top: 10px;
          }
          .csTxt{
            margin: 0;
            font-size: clamp(0px, inc_set.$vw_32px, 24px);
          }
        }
        .lmtTxt{
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          background: rgba(0,0,0,.5);
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          z-index: 10;
          p{
            width: 95%;
            margin: 0 auto;
            color: #fff;
            font-size: clamp(0px, inc_set.$vw_20px, 14px);
            letter-spacing: 0em;
            text-align: center;
            border: 1px solid #fff;
            padding: 15px 0;
          }
        }
        a{
          position: relative;
          z-index: 5;
        }
        p{
          color: #fff;
          text-align: center;
          margin: 0 0 10px;
        }
        .gld{
          background: linear-gradient(90deg, #d7c586 0%, #ffffff 50%, #d7c586 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: rgba(0,0,0,0);
        }

        .type_txt{
          // height: 270px;
          height: 280px;
        }
        .rb{
          color: #fff;
          font-size: clamp(10px,2.5vw,12px);
          padding: 5px 0 2px ;
          border: 1px solid #fff;
          display: block;
          width: 60%;
          margin: -2px auto 0;
          line-height: 1em;
        }
        .menu_type{
          font-size: clamp(10px,2.8vw,14px);
          -webkit-text-fill-color: #f4de95;
          border-image: linear-gradient(90deg,#ecd586,#fff,#ecd586);
          border-image-slice: 1;
          width: 35%;
        }
        .p1{
          height: 62px;
          letter-spacing: 0em;
          font-size: clamp(0px, inc_set.$vw_32px, 28px);
          padding: 10px 0 10px;
          // margin-bottom: 15px;
          // line-height: 1em;
          .span1{
            font-size: clamp(10px,9.5vw,56px);
          }
        }
        .p2{
          font-size: clamp(10px,3.8vw,20px);
          letter-spacing: .05em;
          border-top: 1px solid #fff;
          border-bottom: 1px solid #fff;
          padding: 15px 0 8px;
          .span1{
            font-size: clamp(10px,5.8vw,32px);
          }
          &.plan_2{
            padding: 10px 0 0;
            .floor{
              font-size: clamp(10px,2.5vw,12px);
              letter-spacing: .1em;
            }
          }
        }
        .p3{
          letter-spacing: 0em;
          font-size: clamp(0px, inc_set.$vw_38px, 28px);
          line-height: 1em;
          .span1{
            font-size: clamp(10px,6.5vw,42px);
          }
          .span2{
            font-size: clamp(10px,3.3vw,16px);
          }
        }
        .p4{
          letter-spacing: 0em;
          font-size: clamp(10px,2.5vw,12px);
          line-height: 1em;
        }
        .new{
          background: #C20A14;
          color: #fff;
          font-size: 11px;
          width: 45px;
          position: absolute;
          top: 0;
          left: 0;
          padding: 4px 0px 0px;
        }
        .bal{
          background: #E2E4E5;
          color: #006070;
          font-size: clamp(10px,2.7vw,12px);
          width: 70px;
          position: absolute;
          top: 10px;
          right: 10px;
          padding: 4px 0px 0px;
        }
        &.sold_out{
          pointer-events: none;
          &::after{
            content: "";
            background: rgba(0,0,0,.4);
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 5;
          }
          .sold_out_p{
            width: 100%;
            background: linear-gradient(90deg, #d7c586 0%, #ffffff 50%, #d7c586 100%);
            color: #005959;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            font-size: clamp(10px,5vw,28px);
            padding: 10px 0 4px;
            z-index: 6;
            &::before,&::after{
              content: "";
              width: 100%;
              height: 1px;
              background: #005959;
              position: absolute;
              left: 0;
            }
            &::before{
              top: 3px;
            }
            &::after{
              bottom: 3px;
            }
          }
        }
      }

      .btn{
        width: 100%;
        position: relative;
        border: 1px solid #d7c586;
        &::before,&::after{
          content: "";
          display: block;
          position: absolute;
          transition: .5s;
        }
        &::after{
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background: linear-gradient(90deg, #d7c586 0%, #ffffff 50%, #d7c586 100%);
        }
        &::before{
          width: 50px;
          height: 1px;
          background: #005959;
          right: 0;
          top: 50%;
          transform: translate(0,-50%);
          z-index: 2;
        }
        p{
          color: #005959;
          margin: 0;
          padding: 5px 0 0;
          font-size: clamp(0px, inc_set.$vw_24px, 20px);
          position: relative;
          z-index: 5;
          transition: .5s;
        }
      }
    }
  }
  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
    .sec1{
      .lead_box{
        padding: inc_set.$vw_50px 0 0;
        .lead{
          margin: 0 0 10px;
          letter-spacing: 0em;
        }
      }
      .bnr {
        margin: 50px auto 10px;
        cursor: pointer;
      }

      .parse_slide{
        margin: 0 auto 50px;
      }
      .parse_wrap{
        margin: 0 auto;
        width: 95%;
        flex-direction: column-reverse;
        .text_area{
          width: 90%;
          padding: 15px 0;
          p{
            text-align: center;
            padding: 20px 0;
          }
          &::before{
            top: 0;
          }
          &::after{
            bottom: 0;
          }
        }
        .img_area{
          width: 100%;
          &::before{
            width: 100%;
            height: 20%;
            left: 0;
            top: auto;
            bottom: -1px;
            background: linear-gradient(0deg,rgba(255,255,255,1),rgba(0,0,0,.0));
          }
          p{
            bottom: 15px;
          }
        }
      }


      .type_ctgry{
        width: 95%;
        margin: 0 auto 20px;
      }

      .type_list{
        padding: 0 0 inc_set.$vw_100px;
        &::after{
          width: calc(98%/2);
        }
        .list{
          width: calc(98%/2);
          margin: 0 0 10px;
          padding: 10px;
          &::before{
            width: calc(100% - 5px);
            height: calc(100% - 5px);
          }
          &.cs{
            padding: 20px 0;
            .deco{
              top: 5px;
            }
          }
          .lmtTxt{
            p{
              padding: 5px 0;
            }
          }
          p{
            margin: 0 0 5px;
          }
          .type_txt{
            height: 49vw;
            max-height: 270px;
            // height: inc_set.$vw_350px;
          }
          .rb{
            // font-size: clamp(0px, inc_set.$vw_16px, 12px);
            // padding: 2px 5px 0 5px;
            letter-spacing: 0em;
            width: 80%;
            margin-top: 2px;
          }
          .menu_type{
            width: 45%;
          }
          .p1{
            // height: 10vw;
            height: auto;
            padding: 6px 0 5px;
          }
          .p2{
            padding: 7px 0 3px;
            &.plan_2{
              padding: 6px 0 0;
            }
          }
          .p4{
            letter-spacing: -0.05em;
          }
          .bal{
            width: 60px;
            top: 2px;
            right: 2px;
          }
        }

        .btn{
          &::before{
            width: 30px;
          }
        }
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){

  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________
}
