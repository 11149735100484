@use "inc/setting" as inc_set;
@use "inc/reset" as inc_re;

#quality {
  .main_wrap {
    .imgbox {
      background-image: url(../img/quality/main.jpg);
    }
    .main_txt{
      height: 62px;
    }
  }

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp) {
    .main_wrap {
      .imgbox {
        background-position: 90% center;
      }
      .main_txt{
        height: 9vw;
      }
    }
    /*@media screen end*/
  }
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________

  .quality_wrap {
    .flex {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
    }

    .j_link {
      margin-top: -120px;
      padding-top: 120px;
      position: relative;
    }
    .sec_top {
      align-items: center;
      justify-content: center;
      width: 95%;
      max-width: 1050px;
      margin: 50px auto 60px;
      background: #dae2e3;
      .top_text {
        width: 46%;
        text-align: center;
        .t_p1 {
          font-size: clamp(10px, 5.2vw, 24px);
          color: #004553;
          margin-bottom: 40px;
          span {
            font-size: clamp(10px, 4.8vw, 20px);
          }
        }
        .t_p2 {
          font-size: clamp(10px, 3.2vw, 14px);
          letter-spacing: 0.1em;
        }
      }
      .top_img {
        width: 50%;
      }
    }

    .btn_area {
      max-width: 950px;
      margin: 0 auto;
      width: 95%;
      &.btn_area_bottom {
        margin-top: 80px;
      }
      .btn {
        width: calc(94% / 4);
        padding: 10px 0 7px;
        border: solid 1px #116b7c;
        cursor: pointer;
        position: relative;
        a {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
        p {
          color: #116b7c;
          text-align: center;
          font-size: clamp(10px, 3.5vw, 16px);
          letter-spacing: 0.1em;
        }
        &.show {
          background: #116b7c;
          p {
            color: #fff;
          }
        }
      }
    }

    .sec {
      display: none;
      &.show {
        display: block;
      }
    }

    .sec_in {
      max-width: 1050px;
      width: 95%;
      margin: 40px auto 0;
      .ttl_01 {
        align-items: center;
        background: #e6ecec;
        padding: 8px 5px 2px;
        p {
          color: #009ea6;
        }
        .ttl_p1 {
          font-size: clamp(10px, 4.5vw, 20px);
          letter-spacing: 0.05em;
        }
      }
      .ttl_02 {
        background: #d2eaec;
        color: #009ea6;
        text-align: center;
        font-size: clamp(10px, 4.2vw, 18px);
        margin: 10px 0 20px;
        padding: 5px 0 1px;
      }
      .small {
        font-size: 10px;
      }

      .box_area {
        .box {
          p {
            letter-spacing: 0.05em;
          }
          .p1 {
            color: #009ea6;
            border-bottom: solid 1px #727172;
            font-size: clamp(10px, 4.3vw, 16px);
            margin-bottom: 5px;
            line-height: 1em;
            span {
              font-size: clamp(10px, 3vw, 13px);
            }
          }
          .p2 {
            font-size: clamp(10px, 3.8vw, 14px);
            line-height: 1.3em;
            .small_note {
              font-size: 10px;
            }
          }
          .p3 {
            font-size: 13px;
          }
          .box_img {
            position: relative;
            p {
              font-size: 10px;
              text-align: right;
              &.white {
                color: #fff;
              }
              &.ab {
                position: absolute;
                bottom: 0;
                right: 0;
                padding: 5px;
              }
            }
            .text_none{
              color: transparent;
            }
          }
        }
      }
    }
    .box_area-lead {
      margin-bottom: 20px;
      color: #009ea6;
      font-size: clamp(10px, 4.3vw, 18px);
    }
    // ______________________________________
    @media screen and (max-width: inc_set.$media_sp) {
      .j_link {
        margin-top: -70px;
        padding-top: 70px;
      }

      .sec_top {
        margin: 50px auto 30px;
        .top_text {
          width: 100%;
          padding-top: 30px;
          .t_p1 {
            margin-bottom: 20px;
            text-align: center;
          }
          .t_p2 {
            text-align: center;
          }
        }
        .top_img {
          width: 90%;
          margin-top: 30px;
          padding-bottom: 30px;
        }
      }

      .btn_area {
        &.btn_area_bottom {
          margin-top: 60px;
        }
        .btn {
          width: calc(95% / 2);
          padding: 10px 0 5px;
          margin: 5px 0;
        }
      }

      .sec_in {
        margin: 30px auto 0;
      }
      /*@media screen end*/
    }
    // ______________________________________
    // ______________________________________
    // ______________________________________
    // ______________________________________

    .sec_01 {
      .box_area {
        .ttl_02 {
          &.ttl_w_3 {
            width: 74.5%;
          }
          &.ttl_w_1 {
            width: calc(96% / 4);
          }
        }
        .b_a_w3 {
          // width: 74.5%;
          width: 54%;
          .s145 {
            margin-top: 10px;
            div {
              width: calc(98% / 2);
              p {
                font-size: clamp(10px, 3.5vw, 12px);
              }
            }
          }
        }
        .b_a_w1{
          width: 18%;
        }
        .s1_in1 {
          .r {
            width: 46%;
          }
          .l {
            width: 52%;
          }
        }

        .box {
          margin-bottom: 20px;
          .p1 {
            margin-top: 10px;
          }
          &.w_2 {
            width: calc(96% / 2);
          }
          &.w_3 {
            width: calc(96% / 3);
          }
          &.w_4 {
            width: calc(96% / 4);
          }
        }
      }
    }
    @media screen and (max-width: inc_set.$media_sp) {
      .sec_01 {
        .box_area {
          .box{
            .box_img{
              .m_t{
                margin-top: -1.2vw;
              }
            }
            .p1{
              font-size: clamp(10px,4vw,16px);
              letter-spacing: -0.05em;
            }
            .p2{
              display: none;
            }
            &.box_h{
              // height: 190px;
              .p1{
                height: 24px;
                display: flex;
                align-items: flex-end;
              }
            }
          }
          .ttl_02 {
            &.ttl_w_3 {
              width: 100%;
            }
            &.ttl_w_1 {
              width: calc(100% / 1);
            }
          }
          .b_a_w3 {
            width: 100%;
            .s145 {
              div {
                width: calc(100% / 1);
                margin: 5px 0;
                // p{
                //   display: none;
                // }
              }
            }
          }
          .b_a_w1{
            width: 48%;
          }
          .s1_in1 {
            .r {
              width: 100%;
            }
            .l {
              width: 100%;
            }
          }

          .box {
            margin-bottom: 20px;
            .p1 {
              margin-top: 10px;
            }
            &.w_2 {
              width: calc(96% / 2);
            }
            &.w_3 {
              width: calc(96% / 2);
            }
            &.w_4 {
              width: calc(96% / 2);
            }
          }
        }
      }
      /*@media screen end*/
    }
    // ______________________________________
    // ______________________________________
    // ______________________________________
    // ______________________________________
    .sec_02 {
      .ttl_01 {
        margin-bottom: 15px;
      }
      .box_area {
        .box {
          .box_img {
            margin: 20px auto 0;
          }
        }
      }
      .s2_area1 {
        .box {
          width: calc(95% / 3);
          margin-bottom: 20px;
          &:first-of-type {
            width: calc(98% / 3 * 2);
            .box_img {
              width: 90%;
            }
          }
          .box_img {
            width: 80%;
          }
        }
      }

      .s2_area2 {
        .box {
          &:nth-of-type(1) {
            width: 38%;
            .box_in {
              margin-bottom: 5px;
              .text_area {
                width: 70%;
                .p3 {
                  margin-top: 5px;
                }
              }
              .box_img {
                width: 25%;
              }
            }
          }
          &:nth-of-type(2) {
            width: 60%;
            .box_in {
              align-items: center;
              .p2 {
                width: 65%;
              }
              .box_img {
                width: 30%;
                margin: 0;
              }
            }
          }
          &:nth-of-type(n + 3):nth-of-type(-n + 5) {
            margin: 20px 0;
          }
          &:nth-of-type(3) {
            width: 38%;
            .s216 {
              .text {
                width: 68%;
              }
              .box_img {
                width: 32%;
              }
            }
          }
          &:nth-of-type(4) {
            width: 28%;
            .box_img {
              width: 40%;
              margin-bottom: 5px;
            }
          }
          &:nth-of-type(5) {
            width: 26%;
          }
          &:nth-of-type(6) {
            width: 77%;
          }
          &:nth-of-type(7) {
            width: 20%;
            .s222 {
              margin-top: 10px;
              .text {
                width: 58%;
              }
              .box_img {
                width: 38%;
              }
            }
          }
          &:nth-of-type(n + 8):nth-of-type(-n + 10) {
            margin: 40px 0;
          }
          &:nth-of-type(8) {
            width: 55%;
          }
          &:nth-of-type(9) {
            width: 19%;
          }
          &:nth-of-type(10) {
            width: 22%;
            .box_img {
              width: 60%;
            }
          }
          &:nth-of-type(11) {
            width: 100%;
            .s226 {
              .r {
                width: 28%;
                .img {
                  width: 60%;
                  margin: 5px auto;
                }
              }
              .l {
                width: 70%;
                margin: 0;
              }
            }
          }
        }
      }
    }

    @media screen and (max-width: inc_set.$media_sp) {
      .sec_02 {
        .s2_area1 {
          .box {
            width: 100%;
            margin-bottom: 20px;
            &:first-of-type {
              width: 100%;
              .box_img {
                width: 80%;
              }
            }
            .box_img {
              width: 80%;
            }
          }
        }

        .s2_area2 {
          .box {
            &:nth-of-type(1) {
              width: 100%;
              // .box_in{
              //   .text_area{
              //     width: 100%;
              //   }
              //   .box_img{
              //     width: 50%;
              //   }
              // }
            }
            &:nth-of-type(2) {
              width: 100%;
              .box_in {
                align-items: center;
                .p2 {
                  width: 100%;
                }
                .box_img {
                  width: 40%;
                  margin: 15px auto;
                }
              }
            }
            &:nth-of-type(n + 3):nth-of-type(-n + 5) {
              margin: 20px 0;
            }
            &:nth-of-type(3) {
              width: 100%;
            }
            &:nth-of-type(4) {
              width: 45%;
              .box_img {
                width: 50%;
              }
            }
            &:nth-of-type(5) {
              width: 50%;
            }
            &:nth-of-type(6) {
              width: 100%;
            }
            &:nth-of-type(7) {
              width: 100%;
            }
            &:nth-of-type(n + 7):nth-of-type(-n + 10) {
              margin: 25px 0;
            }
            &:nth-of-type(8) {
              width: 100%;
            }
            &:nth-of-type(9) {
              width: 40%;
            }
            &:nth-of-type(10) {
              width: 58%;
              .box_img {
                width: 40%;
              }
            }
            &:nth-of-type(11) {
              .s226 {
                .r {
                  width: 100%;
                  .img {
                    width: 45%;
                    margin: 10px auto;
                  }
                }
                .l {
                  width: 100%;
                }
              }
            }
          }
        }
      }
      /*@media screen end*/
    }
    // ______________________________________
    // ______________________________________
    // ______________________________________
    // ______________________________________

    .sec_03 {
      .sec_03-lead {
        color: #009ea6;
        font-size: clamp(10px, 4.2vw, 20px);
        padding-top: 20px;
        padding-bottom: 20px;
      }
      .ttl_03 {
        background: #009ea6;
        color: #fff;
        padding: 10px 10px 5px;
        font-size: clamp(10px,4.2vw,20px);
      }
      .sec_03-zeh {
        width: 58%;
        &Description {
          padding-top: 10px;
          padding-bottom: 10px;
          font-size: clamp(10px,3.7vw,14px);
        }
      }
      .sec_03-bels {
        width: 38%;
        border: 2px solid #009ea6;

        &Description {
          padding: 10px;
          font-size: clamp(10px,3.7vw,14px);
        }
        &Img {
          padding-left: 10px;
          padding-right: 10px;
        }
        &Note {
          padding: 10px;
          font-size: 10px;
        }
      }

      &-margin {
        margin-top: 60px;
        // margin-bottom: 25px;
      }

      .box__material {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 20px;
        .box_1{
          width: 65%;
        }
      }
      .box_img-h77 {
        aspect-ratio: 409 / 514;
        height: 227px;
        display: block;
        margin-inline: auto;
        & img {
          height: 100%;
        }
      }
      .box_water {
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        gap: 20px;
      }
      .box_toilet-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 30px;
      }
      .box_toilet {
        width: 50%;
        display: flex;
        flex-direction: column;
      }
      .box_bath-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 20px;
      }

      .box_bath-inner {
        flex-shrink: 0;
        width: 43%;
      }
      .box_bath {
        flex-shrink: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 20px;
        .box_img {
          flex-shrink: 0;
          width: 55%;
        }
      }
      .box_formaldehyde {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
      .mt40 {
        margin-top: 40px;
      }
      .mt20 {
        margin-top: 20px;
      }
    }

    @media screen and (max-width: inc_set.$media_sp) {
      .sec_03 {
        .box_area-zeh {
          flex-direction: column;
        }
        .sec_03-zeh,
        .sec_03-bels {
          width: 100%;
        }
        .box__material {
          flex-direction: column;
          .box_1{
            width: 100%;
            .box_img{
              width: 35%;
            }
          }
          &-inner {
            display: flex;
            gap: 15px;
          }
        }
        .box_img-h77 {
          flex-shrink: 0;
          width: 43%;
          max-width: 250px;
          margin-top: -15px;
          height: auto;
        }
        .box_water {
          flex-direction: column;
          gap: 40px;
        }
        .box_toilet-wrapper {
          flex-direction: column;
        }
        .box_bath-inner {
          width: 100%;
        }
        .box_toilet {
          width: 100%;
          flex-direction: row;
        }
        .box_bath-wrapper {
          flex-direction: column;
        }
        .box_bath {
          width: 100%;
          flex-direction: row;
        }
      }
      /*@media screen end*/
    }
    // ______________________________________
    // ______________________________________
    // ______________________________________
    // ______________________________________

    .sec_04 {
      .box_area-flex {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        gap: 20px;
      }
      .box_area-wrapper {
        flex-shrink: 0;
        width: 54%;
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
      .box_wrapper {
        display: flex;
        justify-content: space-between;
        gap: 8px;

        .box_img {
          flex-shrink: 0;
          width: 38%;
        }
      }
      .other__services {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 30px;
        &-contents {
          flex-shrink: 0;
          width: 34%;
        }
      }
      .delivery__grid-box {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-column-gap: 15px;
        grid-row-gap: 15px;
      }
      .delivery__grid1 {
        grid-area: 1 / 1 / 2 / 4;
      }
      .delivery__grid2 {
        grid-area: 2 / 1 / 7 / 3;
      }
      .delivery__grid3 {
        grid-area: 2 / 3 / 7 / 4;
      }
      .bbiq__grid-box {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(5, 1fr);
        grid-column-gap: 15px;
        grid-row-gap: 5px;
      }
      .bbiq__grid1 {
        grid-area: 1 / 1 / 3 / 5;
      }
      .bbiq__grid2 {
        grid-area: 1 / 5 / 3 / 6;
      }
      .bbiq__grid3 {
        grid-area: 3 / 1 / 6 / 2;
      }
      .bbiq__grid4 {
        grid-area: 3 / 2 / 6 / 4;
      }
      .bbiq__grid5 {
        grid-area: 3 / 4 / 6 / 6;
      }
      .mt40 {
        margin-top: 40px;
      }
      .mt20 {
        margin-top: 20px;
      }
    }

    @media screen and (max-width: inc_set.$media_sp) {
      .sec_04 {
        .box_area-flex {
          flex-direction: column;
        }
        .box_area-wrapper {
          width: 100%;
        }
        .other__services {
          flex-direction: column;
          gap: 20px;

          &-contents {
            width: 100%;
          }
        }
        .delivery__grid-box {
          grid-template-columns: repeat(5, 1fr);
          grid-template-rows: repeat(4, 1fr);
          grid-row-gap: 5px;
        }
        .delivery__grid1 {
          grid-area: 1 / 1 / 3 / 4;
        }
        .delivery__grid2 {
          grid-area: 3 / 1 / 7 / 6;
        }
        .delivery__grid3 {
          grid-area: 1 / 4 / 3 / 6;
        }
        .bbiq__grid-box {
          display: flex;
          flex-direction: column;
        }
        .bbiq__grid1 {
          order: 1;
        }
        .bbiq__grid2 {
          order: 4;
          width: 40%;
          margin-left: auto;
          margin-right: 0;
        }
        .bbiq__grid3 {
          order: 2;
          width: 40%;
          margin-inline: auto;
        }
        .bbiq__grid4 {
          order: 3;
        }
        .bbiq__grid5 {
          order: 5;
        }
      }
      /*@media screen end*/
    }
    // ______________________________________
    // ______________________________________
    // ______________________________________
    // ______________________________________
  }
}
