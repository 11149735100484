@use "inc/setting" as inc_set;
@use "inc/reset" as inc_re;

#aftersupport{
  .main_wrap{
    .imgbox{
      background-image: url(../img/aftersupport/main.jpg);
    }
    // .main_cmn{
    //   .main_txt{
    //     height: 75px;
    //   }
    // }
  }

  .flex_area{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    &.fld{
      flex-direction: row-reverse;
    }
  }
  .aftersupport_wrap{
    background: #eceeef;
    padding: 50px 0 80px;
  }
  .sec{
    max-width: 1100px;
    margin: 0 auto;
    .p1{
      color: #005959;
      font-size: clamp(10px,5.5vw,20px);
      text-align: center;
      margin-bottom: 20px;
      span{
        &.s1{
          font-size: clamp(10px,6.8vw,25px);
          letter-spacing: .08em;
        }
        &.s2{
          font-size: clamp(10px,4vw,16px);
          letter-spacing: .1em;
        }
      }
    }
    .p2{
      text-align: center;
      font-size: clamp(10px,3.8vw,14px);
      line-height: 1.8em;
      letter-spacing: .1em;
    }
    .small{
      font-size: 10px;
      margin-top: 15px;
    }
    .text_box{
      margin-bottom: 20px;
      .img_area{
        width: 45%;
        position: relative;
        p{
          position: absolute;
          bottom: 0;
          right: 0;
          font-size: 10px;
          padding: 10px;
          &.col_white{
            color: #fff;
          }
        }
      }
      .text_area{
        width: 52%;
        p{
          color: #505050;
        }
        .p3{
          font-size: clamp(10px,3.7vw,16px);
        }
        .p4{
          font-size: clamp(10px,4vw,20px);
          margin: 18px 0 20px;
          padding-bottom: 10px;
          border-bottom: solid 1px #505050;
          letter-spacing: .1em;
          span{
            font-size: clamp(10px,8.2vw,42px);
            letter-spacing: .01em;
          }
        }
        .p5{
          font-size: clamp(10px,3.8vw,16px);
        }
      }
    }
  }
  .sec1{
    .box_01{
      background: #fff;
      padding: 20px 0;
      margin-top: 30px;
      p{
        text-align: center;
        color: #505050;
      }
      .b1_01{
        width: 32%;
        margin: 0 auto 20px;
        div{
          width: 18%;
        }
        .a_p1{
          text-align: left;
          font-size: clamp(10px,4vw,16px);
          border-left: solid 1px #505050;
          padding-left: 15px;
        }
      }
      .a_p2{
        font-size: clamp(10px,4.2vw,18px);
        span{
          font-size: clamp(10px,6vw,28px);
          color: #0071bc;
        }
      }
      .a_p3{
        width: 50%;
        margin:5px auto 8px;
        background: linear-gradient(90deg,rgba(0,0,0,0),#0071bc 15%,#0071bc 85%,rgba(0,0,0,0));
        color: #fff;
        font-size: clamp(10px,4vw,16px);
        padding: 5px 0 2px;
      }
      .a_p4{
        font-size: clamp(10px,3.4vw,14px);
      }
      .b1_02{
        width: 90%;
        margin: 25px auto 15px;
        .box{
          width: calc(96%/4);
          height: 115px;
          padding: 10px 0;
          background: #0071bc;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          p{
            color: #fff;
            letter-spacing: .1em;
            font-family: sans-serif;
          }
          .icon{
            img{
              width: auto;
              height: 30px;
            }
          }
          .a_p5{
            font-size: clamp(10px,4.5vw,18px);
            margin-top: 5px;
          }
          .a_p6{
            font-size: 12px;
            letter-spacing: .01em;
          }
        }
      }
    }
  }
  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
    .main_wrap{
      .imgbox{
        background-position: 65% 50%;
      }
      // .main_cmn{
      //   .main_txt{
      //     height: 13vw;
      //   }
      // }
    }

    .sec{
      max-width: 1100px;
      margin: 0 auto;
      .p1{
        margin-bottom: 20px;
      }
      .text_box{
        .img_area{
          width: 100%;
        }
        .text_area{
          width: 100%;
          margin-top: 15px;
          .p3,.p4{
            text-align: center;
            letter-spacing: .1em;
          }
          .p4{
            margin: 12px 0 15px;
          }
        }
      }
    }
    .sec2,.sec3,.sec4{
      width: 95%;
      margin: 0 auto;
    }
    .sec1{
      .box_01{
        // padding: 20px 0;
        // margin-top: 30px;
        .b1_01{
          width: 85%;
        }
        .a_p3{
          width: 85%;
        }
        .b1_02{
          width: 90%;
          .box{
            width: calc(75%/1);
            margin: 0 auto 10px;
          }
        }
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________

  .sec2{
    margin: 80px auto;
    .text_box{
      .text_area{
        .p4{
          color: #6fbbc5;
        }
      }
    }
  }

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
    .sec2{
      margin: 50px auto;
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________

  .sec3{
    .text_box{
      .text_area{
        .p4{
          color: #76b384;
        }
      }
    }
  }
  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
    .sec3{
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________

  .sec4{
    margin-top: 80px;
    .text_box{
      .img_area{
        width: 58%;
      }
      .text_area{
        width: 38%;
        color: #505050;
        font-size: clamp(10px,3.7vw,15px);
      }
    }
  }

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
    .sec4{
      margin-top: 50px;
      .text_box{
        flex-direction: column-reverse;
        .img_area{
          width: 100%;
        }
        .text_area{
          width: 100%;
          margin: 0 0 15px;
        }
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________
}
