@use "inc/setting" as inc_set;
@use "inc/reset" as inc_re;

#voice{
  .main_wrap{
    .imgbox{
      background-image: url(../img/voice/main.jpg);
      background-position: top;
    }
    .main_txt{
      height: 45px;
      // img{
      //   height: 70%;
      // }
    }
  }

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
    .main_wrap{
      .imgbox{
        background-position: 80% 50%;
      }
      .main_txt{
        height: 7vw;
      }
    }

  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________


  $green:#006464;
  $beige:#958A65;

  .flex_area{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    &.fld{
      flex-direction: row-reverse;
    }
  }

  .img_area{
    position: relative;
    .img_note{
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 10px;
      font-size: 10px;
      &.coler_white{
        color: #fff;
      }
    }
  }

  .voice_wrap{
    // font-family: 'a1',serif;

    .ttl_p1{
      font-size: clamp(10px,5.2vw,38px);
      text-align: center;
    }
    .small{
      font-size: 10px;
      letter-spacing: .01em;
      &.text_r{
        text-align: right;
        width: 100%;
      }
    }

    .sans-serif{
      font-family: sans-serif;
    }

    .max1200{
      max-width: 1200px;
      width: 93%;
      margin: 0 auto;
    }

    .btn_wrap{
      max-width: 980px;
      margin: 0 auto 80px;
      flex-wrap: wrap;
      &.btn_wrap_bottom{
        margin: 50px auto;
      }
      .btn{
        width: calc(90%/3);
        background: #fff;
        padding: 20px 0 15px;
        position: relative;
        filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.6));
        transform: translateZ(0);
        a{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 2;
        }
        &::before{
          content: "";
          width: 95%;
          height: 85%;
          border: solid 1px #958A65;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
        }
        p{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          color: #958A65;
          font-size: clamp(10px,5vw,20px);
        }
        &.active{
          background: #958A65;
          &::before{
            border: solid 1px #fff;
          }
          p{
            color: #fff;
          }
        }
      }
    }
  }

  .top_wrap{
    padding: 50px 0;
    .top_p1{
      text-align: center;
      font-size: clamp(14px,5.5vw,26px);
      line-height: 2.5em;
      color: #5A5A5A;
      span{
        font-size: clamp(16px,8vw,38px);
        position: relative;
         &::before{
           content: "";
           width: 110%;
           height: 30%;
           background: #D6C287;
           position: absolute;
           bottom: 2px;
           left: 50%;
           transform: translate(-50%,0);
           z-index: -1;
         }
      }
    }
  }

  // ______________________________________
  @media screen and (max-width: 768px){
    .voice_wrap{
      .btn_wrap{
        margin: 0 auto 40px;
        .btn{
          width: 80%;
          margin: 10px auto;
        }
      }
    }


    .top_wrap{
      .top_p1{
        span{
          &::before{
            width: 80%;
            height: 10%;
            left: 64%
          }
          .marker_sp{
            &::before{
              content: "";
              width: 100%;
              height: 25%;
              background: #d6c287;
              position: absolute;
              bottom: 2px;
              left: 50%;
              -webkit-transform: translate(-50%, 0);
              transform: translate(-50%, 0);
              z-index: -1;
            }
          }
        }
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________

  .point{
    padding: 80px 0;
    .po_num{
      text-align: center;
      padding-top: 120px;
      margin-top: -90px;
      p{
        font-size: clamp(10px,6vw,24px);
        color: $green;
        span{
          font-size: clamp(10px,17vw,80px);
          display: inline-block;
          margin-top: 15px;
        }
      }
    }

    .point_text{
      max-width: 650px;
      width: 95%;
      margin: 20px auto 80px;
      background-size: 100% 100%;
      background-image: url(../img/voice/point_bg.png);
      padding: 15px 0 12px;
      p{
        text-align: center;
        font-size: clamp(10px,4.5vw,20px);
        color: $green;
        letter-spacing: .1em;
      }
    }

    .link_btn{
      max-width: 350px;
      width: 80%;
      margin: 30px auto;
      padding: 15px 0 12px;
      justify-content: center;
      background: $green;
      position: relative;
      a{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }
      p{
        color: #fff;
        font-size: clamp(10px,4vw,20px);
      }
      .arrow{
        display: inline-block;
        width: 20%;
        height: 23px;
        vertical-align: bottom;
        position: relative;
        overflow: hidden;
        span{
          content: "";
          background: #fff;
          height: 1px;
          position: absolute;
          &:first-of-type{
            width: 75%;
            top: 65%;
            left: 50%;
            transform: translate(-50%,-50%);
          }
          &:last-of-type{
            width: 30%;
            top: 8px;
            right: 6px;
            transform: rotate(40deg);
          }
        }
      }
    }
  }

  // ______________________________________
  @media screen and (max-width: 768px){
    .point{
      padding: 50px 0;
      .po_num{
        padding-top: 90px;
        margin-top: -70px;
      }
      .point_text{
        margin: 20px auto 60px;
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________

  .point1{
    background: #EAE8E8;
    .po1_textarea{
      .po1_p1{
        text-align: center;
        color: $green;
        font-size: clamp(10px,3.5vw,50px);
        span{
          font-size: clamp(10px,4vw,68px);
          letter-spacing: .1em;
        }
      }
      .po1_box1{
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        div{
          &:first-of-type{
            margin: 10px 0 10px 25px;
          }
        }
        .po1_p2{
          font-size: clamp(10px,6vw,92px);
          color: $beige;
          .s1{
            font-size: clamp(10px,11vw,155px);
          }
          b{
            color: $green;
          }
        }
        .po1_p3{
          font-size: clamp(10px,4vw,44px);
          color: $green;
          .s3{
            font-size: clamp(10px,5vw,62px);
          }
        }
      }

      .po1_p5{
        color: $beige;
        font-size: clamp(10px,15vw,110px);
        text-align: center;
        position: relative;
        span{
          color: $green;
          font-size: clamp(10px,6vw,50px);
        }
        b{
          font-size: clamp(10px,3vw,15px);
          letter-spacing: 0;
          position: absolute;
          top: -25px;
          left: 55%;
        }
      }
    }

    .po1_box2{
      width: 85%;
      margin: 50px auto;
    }

    .po1_box3{
      .img_area{
        width: calc(100%/2);
        background-size: cover;
        background-repeat: no-repeat;
        img{
          vertical-align: bottom;
        }
        &::before{
          content: "";
          width: 100%;
          height: 100%;
          background: rgba(#00222C,.4);
          position: absolute;
          top: 0;
          left: 0;
        }
        .po1_p4{
          position: absolute;
          top: 10px;
          left: 2%;
          color: #fff;
          font-size: clamp(10px,4vw,65px);
          font-family: 'newyork',sans-serif;
          span{
            font-size: clamp(10px,4vw,32px);
          }
        }

        .voice_img{
          position: absolute;
          bottom: 5%;
          width: 50%;
        }
        .voice_img1{
          left: 2%;
        }
        .voice_img2{
          right: 2%;
        }
      }
    }

    .po1_box4{
      margin-bottom: 80px;
    }
  }

  // ______________________________________
  @media screen and (max-width: 768px){
    .point1{
      .po1_textarea{
        .po1_p1{
          font-size: clamp(10px,6vw,50px);
          span{
            font-size: clamp(10px,7vw,65px);
          }
        }
        .po1_box1{
          margin: 0 auto;
          text-align: center;
          .po1_p2{
            font-size: clamp(10px,16vw,120px);
            .s1{
              font-size: clamp(10px,30vw,200px);
            }
            b{
              display: inline-block;
              width: 20px;
            }
          }
          .po1_p3{
            font-size: clamp(10px,6vw,42px);
            color: $green;
            .s3{
              font-size: clamp(10px,9vw,60px);
            }
          }
        }
      }
      .po1_box2{
        width: 100%;
        margin: 30px 0 20px;
        overflow: scroll;
        img{
          width: 180%;
        }
      }
      .swipeBx{
        width: 15%;
        margin: 0 0 40px;
      }

      .po1_box3{
        display: block;
        .img_area{
          width: calc(100%/1);
          // &.slick-active{
          //   .slick-dots{
          //     button{
          //       background-color: #006464;
          //     }
          //   }
          // }
          .po1_p4{
            font-size: clamp(10px,12vw,65px);
            span{
              font-size: clamp(10px,11vw,32px);
            }
          }

          .voice_img{
            bottom: 5%;
            width: 75%;
          }
          .voice_img1{
            left: 5%;
            bottom: 15%;
          }
          .voice_img2{
            left: 50%;
            top: 55%;
            transform: translate(-50%,-50%);
          }
        }
        .slick-dots{
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 10px 0 40px;
          li{
            &.slick-active{
              button{
                background-color: #006464;
              }
            }
            button{
              border-radius: 50%;
              color: transparent;
              background-color: rgba(0,100,100,0.5);
              border: none;
              margin: 0 10px;
              width: 20px;
              height: 20px;
            }
          }
        }
      }
      // .po1_box4{
      //   // height: 160vw;
      //   // background-image: url(../../assets/img/voice/po1_04.jpg);
      //   // background-size: cover;
      //   // background-position-x: center;
      // }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________


  .point2{
    background: $green;
    .po_num{
      p{
        color: #fff;
      }
    }

    .link_btn{
      background: #fff;
      p{
        color: $green;
      }
      .arrow{
        span{
          background: $green;
        }
      }
    }

    .po2_img_area{
      position: relative;
      .img_note{
        position: absolute;
        bottom: 0;
        padding: 10px;
        color: #fff;
        font-size: clamp(10px,4vw,18px);
        letter-spacing: 0.01em;
        text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.8);
        span{
          font-size: clamp(10px,4vw,40px);
          letter-spacing: .1em;
        }
      }
      .img_note_f1{
        font-size: clamp(10px,4vw,16px);
        span{
          font-size: clamp(10px,7vw,30px);
        }
      }
      .img_note_f2{
        span{
          letter-spacing: 0;
        }
      }
      .posi_l{
        left: 0;
      }
      .posi_r{
        right: 0;
      }
    }

    .po2_p2{
      font-size: clamp(10px,1.8vw,26px);
      color: $green;
      border-bottom: solid 1px $green;
      position: relative;
      line-height: 2em;
      letter-spacing: .01em;
    }

    .po2_box1{
      justify-content: flex-end;
      .p2_b1_text{
        margin-right: 30px;
        p{
          writing-mode: vertical-rl;
          text-orientation: upright;
          color: #D9CCA9;
          font-size: clamp(10px,3vw,45px);
          line-height: 2.8em;
          letter-spacing: .2em;
          span{
            font-size: clamp(10px,4vw,70px);
          }
        }
      }
      .p2_b1_img{
        width: 62%;
        max-width: 1050px;
      }
    }

    .po2_box2{
      position: relative;
      margin: 250px 0 330px;
      &:before{
        content: "";
        width: 70%;
        height: 155%;
        background-image: url(../img/voice/po2_bg.png);
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        top: 45%;
        left: 0;
        transform: translate(0, -50%);
      }
      .po2_p1{
        width: 62%;
        text-align: right;
        font-size: clamp(10px,3vw,35px);
        color: #717071;
        position: relative;
        line-height: 1;
        span{
          font-size: clamp(10px,1.5vw,20px);
          letter-spacing: .1em;
        }
      }
      .po2_box2_in{
        width: 95%;
        margin: 30px auto;
        .po2_img_area{
          width: calc(98%/2);
        }
      }

      .voice_img{
        position: absolute;
        left: 28%;
        bottom: -40%;
        width: 25%;
      }
    }

    .po2_box3{
      // justify-content: flex-end;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      &::before{
        content: "";
        width: 60%;
        height: 110%;
        background-image: url(../img/voice/po2_bg2.png);
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -50%);
      }
      .p2_b3_l{
        width: 22%;
      }
      .p2_b3_c{
        width: 25%;
        margin: 0 2%;
      }
      .p2_b3_r{
        width: 42%;
        .po2_img_area{
          margin-top: 20px;
        }
      }
    }

    .po2_box4{
      justify-content: flex-end;
      align-items: center;
      position: relative;
      margin: 150px 0 100px;
      &::before{
        content: "";
        width: 85%;
        height: 100%;
        background-image: url(../img/voice/po2_bg2.png);
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        top: 55%;
        left: 0;
        transform: translate(0, -50%);
      }
      .p2_b4_l{
        width: 40%;
      }
      .p2_b4_c{
        width: 30%;
        margin-right: 1%;
      }
      .p2_b4_r{
        width: 18%;
        margin-top: 45px;
      }
    }
  }

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
    .point2{
      .po2_box1{
        display: block;
        .p2_b1_text{
          margin-right: 0;
          p{
            font-size: clamp(10px,6vw,45px);
            margin: 0 auto;
            span{
              font-size: clamp(10px,9vw,70px);
            }
          }
        }
        .p2_b1_img{
          width: 95%;
          margin: 40px auto 0 0;
        }
      }
      .po2_box2{
        margin: 110px 0 40px;
        &::before{
          top: 12%;
          width: 99%;
          height: 60vw;
          left: auto;
          right: 0;
        }
        .wbg{
          content: '';
          width: 95%;
          height: 40vw;
          background-color: #FBF7EF;
          position: absolute;
          top: 70%;
          right: 0;
        }
        .po2_p1{
          text-align: center;
          font-size: clamp(14px,7vw,45px);
          margin: 0 auto;
          line-height: 1;
          width: auto;
          span{
            font-size: clamp(10px,3.8vw,45px);
          }
        }
        .po2_box2_in{
          display: block;
          margin: 0 0 0 auto;
          width: 90%;
          .po2_img_area{
            width: 100%;
            margin: 30px 0 60px;
            &.mb70_sp{
              margin-bottom: 70%;
            }
            .img_note{
              font-size: clamp(10px,2.5vw,45px);
            }
            .posi_l{
              left: auto;
              right: 0;
            }
          }
        }
        .voice_img{
          left: 15%;
          bottom: 35%;
          width: 80%;
        }
      }
      .po2_box3{
        // display: block;
        margin: 0 0 0 auto;
        width: 100%;
        &::before{
          width: 94%;
          height: 50vw;
          top: 10%;
        }
        .po2_p2{
          font-size: clamp(14px,6vw,24px);
          // width: 80%;
          margin-left: 10%;
        }
        // .po2_img_area{
        //   width: 80%;
        // }
        .p2_b3_r{
          width: 90%;
          margin: 40px auto;
          order: 1;
          .po2_img_area {
            margin-top: 20px;
            width: 90%;
            margin: 20px 0 0 auto;
          }
        }
        .p2_b3_c{
          width: 90%;
          margin: 40px auto;
          order: 3;
          .img_note_f2{
            font-size: clamp(10px,3vw,16px);
            span{
              font-size: clamp(10px,6vw,30px);
            }
          }
        }
        .p2_b3_l{
          width: 90%;
          margin: 40px auto;
          order: 2;
          position: relative;
          .voice_img{
            position: absolute;
            width: 95%;
            z-index: 5;
          }
          .po2_img_area{
            margin-top: 40%;
          }
        }
      }
      .po2_box4{
        margin: 40px auto 70px;
        width: 100%;
        &:before{
          width: 95%;
          height: 65%;
          top: 30%;
          left: auto;
          right: 0;
        }
        .p2_b4_l{
          width: 90%;
          margin: 0 auto 0 0;
          .po2_p2{
            font-size: clamp(10px,6vw,24px);
            padding-left: 5%;
            margin-left: 6%;
          }
        }
        .p2_b4_c{
          width: 90%;
          margin: 40px auto 0 0;
        }
        .p2_b4_r{
          width: 90%;
        }
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________

  .point3{
    position: relative;
    &::before{
      content: "";
      width: 100%;
      height: 15%;
      background: #EAE8E8;
      position: absolute;
      top: 0;
      left: 0;
    }
    .sec_in{
      max-width: 1050px;
      width: 95%;
      margin: 0 auto;
    }

    .po3_box1{
      background-image: url(../img/voice/po3_bg_pc.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      height: 47vh;
      position: relative;
      &::before{
        content: "";
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg,rgba(#084644,0),#084644);
        position: absolute;
        top: 0;
        left: 0;
      }
      .p3_b1_in{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        position: relative;
        p{
          font-size: clamp(18px,4vw,40px);
          color: #fff;
          line-height: 2.5em;
          span{
            font-size: clamp(28px,5.5vw,68px);
          }
        }
      }
    }

    .po3_box2{
      background: #e7eded;
      .merit_area{
        padding: 50px 0;
        .ttl_p1{
          color: #747338;
          letter-spacing: .1em;
          span{
            font-size: clamp(10px,10vw,50px);
          }
        }
        .merit_box{
          margin-top: 30px;
        }
      }

      .zeh_1{
        background: #E7EDED;
        padding: 65px 0;
        .zeh_in{
          background: #fff;
          padding: 40px 0 20px;
          max-width: 1050px;
          width: 93%;
          margin: 0 auto;
          position: relative;
          .p_ab{
            position: absolute;
            left: -20px;
            top: -15px;
            background: #009EA6;
            color: #fff;
            padding: 2px 3px 3px 10px;
            font-size: clamp(10px,4.8vw,20px);
          }
          .zeh_text{
            width: 88%;
            margin: 0 auto;
            .z_p1{
              font-size: clamp(10px,4vw,15px);
              margin-bottom: 20px;
              line-height: 1.8em;
              letter-spacing: .1em;
            }
            .img_area{
              align-items: center;
              .box{
                &:nth-of-type(1),&:nth-of-type(3){
                  width: calc(92%/2);
                  height: 315px;
                }
                &:nth-of-type(2){
                  position: relative;
                  width: 18px;
                  height: 3px;
                  span{
                    width: 100%;
                    height: 100%;
                    background: #009EA6;
                    position: absolute;
                    &:nth-of-type(1){
                      transform: rotate(0deg) translate(-50%,0);
                      left: 50%;
                    }
                    &:nth-of-type(2){
                      transform: rotate(90deg);
                      left: 0;
                    }
                  }
                }
                p{
                  color: #009EA6;
                  background: #D3EBED;
                  text-align: center;
                  font-size: clamp(10px,5vw,22px);
                  padding: 6px 0 3px;
                  letter-spacing: .01em;
                }
                div{
                  width: 90%;
                  margin: 20px auto 0;
                }
              }
            }
          }
        }
      }
    }

    .po3_box3{
      padding: 60px 0;
      position: relative;
      &::before{
        content: "";
        width: 45%;
        height: 15vw;
        position: absolute;
        top: 0;
        left: 0;
        -webkit-clip-path: polygon(0 0, 0 100%, 100% 0);
        clip-path: polygon(0 0, 0 100%, 100% 0);
        background: #f6eeec;
      }
      .sec_main{
        position: relative;
        height: 370px;
        margin-top: 20px;
        margin-bottom: 50px;
        .ttl_area{
          width: 100%;
          position: relative;
          z-index: 1;
          .ttl{
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 40px;
            .p1{
              color: #fff;
              text-align: center;
              width: 16%;
              line-height: 2em;
              font-size: clamp(10px,4vw,15px);
              position: relative;
              z-index: 0;
              padding: 5px;
              margin-right: 20px;
              background: #C1726C;
              span{
                font-size: clamp(10px,7vw,32px);
              }
              &::before{
                content: "";
                width: 95%;
                height: 90%;
                position: absolute;
                top: 0;
                left: 0;
                clip-path: polygon(0 0, 0 100%, 100% 0);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                z-index: -1;
                background: #b4605d;
              }
            }
            .p2{
              font-size: clamp(10px,5.8vw,28px);
            }
          }
          .p3{
            font-size: clamp(10px,3.9vw,15px);
            width: 37%;
            line-height: 1.8em;
            letter-spacing: .1em;
          }
        }
        .img_area{
          width: 60%;
          position: absolute;
          right: 0;
          top: -15px;
        }
      }
      .ttl_01{
        text-align: center;
        font-size: clamp(10px,4.6vw,22px);
        letter-spacing: .1em;
        padding: 7px 0 7px;
        position: relative;
        .s_small{
          font-size: clamp(10px,3.8vw,18px);
        }
        .s_large{
          font-weight: 600;
          font-size: clamp(10px,5.3vw,25px);
        }
      }

      .text_01{
        font-size: 14px;
        letter-spacing: .05em;
      }

      .text_02{
        font-size: clamp(10px,4vw,16px);
      }

      .s1_wrap{
        .ttl_01{
          background: #FBF7F6;
          border-top: solid 1px #C1726C;
          border-bottom: solid 1px #C1726C;
          color: #C1726C;
          margin-bottom: 20px;
        }
        .s1_box1{
          margin: 25px auto 0;
        }
      }
    }

    .po3_box3_2{
      margin-top: 30px;
      &::before{
        background: #eaebef;
      }
      .sec_main{
        height: auto;
        .ttl_area{
          .ttl{
            .p1{
              background: #7386a2;
              &::before{
                background: #5a7492;
              }
            }
          }
        }
      }

      .s4_wrap{
        .ttl_01{
          background: #EAEBEF;
          border-top: solid 1px #5A7492;
          border-bottom: solid 1px #5A7492;
          color: #5A7492;
          margin-bottom: 20px;
        }

        .s4_box1{
          .img_area{
            margin: 10px auto;
          }
        }

        .s4_box2{
          margin: 40px 0;
          .s4b2{
            .l{
              width: 36%;
            }
            .r{
              width: 60%;
            }
          }

          .s4b3{
            .l{
              width: 45%;
              .small{
                margin-top: 10px;
              }
            }
            .r{
              width: 52%;
            }
          }
        }
      }
    }

    .po3_box4{
      .s2_wrap{
        .ttl_01{
          background: #F7EED4;
          border-top: solid 1px #AD941C;
          border-bottom: solid 1px #AD941C;
          color: #AD941C;
          margin-bottom: 20px;
        }
        .s2_p1{
          text-align: center;
          font-size: clamp(10px,4vw,18px);
          border-bottom: solid 1px #000000;
          padding-bottom: 10px;
          margin-bottom: 20px;
          span{
            font-size: clamp(10px,4.5vw,24px);
          }
        }

        .s2_box2{
          padding: 60px 0 40px;
          .s2b2in{
            margin-bottom: 10px;
            .box{
              width: calc(96%/2);
              padding: 20px 0;
              .img_in{
                width: 80%;
                margin: 15px auto;
                &.w_90{
                  width: 92%;
                }
              }
              .s2b2_p1{
                text-align: center;
                font-size: clamp(10px,4vw,20px);
                font-family: "axis",sans-serif;
              }
              &.box_col{
                background: #F1F5F4;
                .s2b2_p1{
                  color: #218B7F;
                }
              }
            }
            .small{
              margin-top: 10px;
              width: 95%;
            }
          }
        }
      }
    }

    .po3_box5{
      padding: 60px 0;
      .po3_box5_in{
        justify-content: flex-start;
        max-width: 1600px;
        width: 100%;
        // margin: 0 0 -230px;
        margin: 0 0 -18vw;
        .p3_b5_l{
          width: 48%;
        }
        .p3_b5_r{
          width: 43%;
          margin-left: 2%;
          .p3_b5_r1{
            position: relative;
            div{
              &:first-of-type{
                width: 55%;
                margin-top: 70px;
              }
              &:last-of-type{
                width: 50%;
                position: absolute;
                right: 0;
                top: -125px;
              }
            }
          }

          .p3_b5_r2{
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin-top: 30px;
            div{
              &:first-of-type{
                width: 85%;
              }
              &:last-of-type{
                width: 55%;
                margin: -10px 18% 0 0;
              }
            }
          }
        }
      }

      .p3_b5_b{
        width: 30%;
        max-width: 500px;
        margin-left: 14%;
        p{
          text-align: left;
          font-size: 10px;
        }
      }
    }

    // .link_btn{
    //   margin: 0 auto;
    // }
  }


  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
    .point3{
      .po3_box1{
        background-image: url(../../assets/img/voice/po3_bg_sp.jpg);
        background-position: center;
        height: 64vw;
      }
      .po3_box2{
        .zeh_1{
          padding: 45px 0;
          .zeh_in{
            padding: 40px 0 25px;
            .p_ab{
              left: -5px;
            }
            .zeh_text{
              .img_area{
                flex-direction: column;
                .box{
                  &:nth-of-type(1),&:nth-of-type(3){
                    width: calc(96%/1);
                    height: auto;
                  }
                  &:nth-of-type(2){
                    margin: 30px 0;
                  }
                }
              }
            }
          }
        }
      }

      .po3_box3{
        padding: 30px 0;
        .sec_main{
          height: auto;
          margin-top: 0;
          .ttl_area{
            width: 100%;
            .ttl{
              margin-bottom: 10px;
              justify-content: space-between;
              .p1{
                width: 40%;
                max-width: 250px;
                line-height: 1.8em;
                margin: 0 0 15px;
              }
              .p2{
                letter-spacing: .03em;
              }
            }
            .p3{
              width: 100%;
              line-height: 1.5em;
            }
          }
          .img_area{
            width: 100%;
            position: relative;
            right: auto;
            top: auto;
            margin-top: 20px;
          }
        }

        .s1_wrap{
          .scroll{
            position: relative;
            max-width: 150px;
            width: 34%;
            p{
              font-size: clamp(10px,4vw,20px);
            }
            .arrow{
              position: absolute;
              top: 50%;
              right: 0;
              transform: translate(0,-50%);
              width: 40px;
              height: 6px;
              span{
                background: #000;
                height: 1px;
                position: absolute;
                &:nth-of-type(1){
                  width: 100%;
                  bottom: 0;
                  right: 0;
                }
                &:nth-of-type(2){
                  width: 24%;
                  bottom: 4px;
                  right: 0;
                  transform: rotate(42deg);
                }
              }
            }
          }

          .img_area{
            .img_note{
              position: relative;
              text-align: right;
              margin-top: -10px;
            }
          }

          .s1_box1{
            margin: 25px auto;
          }

          .s1_box2{
            margin-top: 35px;
            .box{
              &:nth-of-type(-n+4){
                width: calc(96%/2);
              }
              &:nth-of-type(5){
                width: 100%;
                .s1_06{
                  .l{
                    width: 55%;
                    margin: 0 auto;
                  }
                  .r{
                    width: 100%;
                    .text_01{
                      margin-bottom: 5px;
                    }
                  }
                }
              }
              &:nth-of-type(n+6){
                width: calc(96%/2);
              }
            }
          }

          .s1_box3{
            margin-top: 50px;
            .s1b3_in{
              .text_02{
                width: 100%;
                margin-bottom: 10px;
              }
              .img_area{
                width: 100%;
              }
            }
          }

          .img_scroll{
            overflow: scroll;
            img{
              width: 170%;
            }
          }
        }
      }

      .po3_box3_2{
        margin-top: 0px;
        .s4_wrap{
          .s4_box2{
            margin: 40px 0;
            .s4b2,.s4b3{
              .l{
                width: 100%;
                margin-top: 10px;
                .small{
                  margin: 10px 0;
                }
              }
              .r{
                width: 100%;
              }
            }
          }
        }
      }

      .po3_box4{
        .s2_wrap{
          .ttl_01{
            letter-spacing: .01em;
          }
          .s2_p1{
            letter-spacing: .01em;
          }

          .s2_box1{
            div{
              &:last-of-type{
                width: 90%;
              }
            }
          }
          .s2_box2{
            padding: 30px 0 40px;
            .s2b2in{
              align-items: flex-end;
              .small{
                letter-spacing: -0.12em;
              }
              .box{
                &:first-of-type{
                  width: 42%;
                  .s2b2_p1{
                    font-size: clamp(10px,2.6vw,20px);
                  }
                }
                &:last-of-type{
                  width: 56%;
                }
                // width: calc(100%/1);
                .img_in{
                  margin: 10px auto;
                }
              }
            }
          }
        }
      }
      .po3_box5{
        padding-top: 0;
        .po3_box5_in{
          width: 90%;
          margin: 0 auto;
          .p3_b5_l{
            width: 100%;
          }
          .p3_b5_r{
            width: 100%;
            margin: 75px auto;
            .p3_b5_r1{
              div{
                &:first-of-type{
                  width: 100%;
                }
                &:last-of-type{
                  width: 65%;
                  top: -100px;
                }
              }
            }
            .p3_b5_r2{
              div{
                &:first-of-type{
                  width: 75%;
                }
                &:last-of-type{
                  width: 100%;
                  margin: -10px 0 0 0;
                }
              }
            }
          }
        }
        .p3_b5_b{
          width: 65%;
          margin-left: 0;
          p{
            padding-left: 5%;
          }
        }
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________
}
