@use "inc/setting" as inc_set;
@use "inc/reset" as inc_re;

#design{
  .main_wrap{
    .imgbox{
      background-image: url(../img/design/main.jpg);
    }
    .main_cmn{
      .note{
        text-align: right;
      }
    }
  }


  .design_wrap{
    .flex{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
    }
    .img_area{
      position: relative;
      .img_note{
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: 10px;
        padding: 5px;
        color: #fff;
        text-align: right;
        &.col_bl{
          color: #000;
        }
      }
    }

    .ttl_area{
      width: 100%;
      margin: 0 auto;
      p{
        text-align: center;
      }
      .ttl{
        font-size: clamp(10px,7.8vw,35px);
      }
      .p1{
        font-size: clamp(10px,4.7vw,20px);
        margin: 20px 0;
        letter-spacing: .1em;
      }
      .p2{
        font-size: clamp(10px,3.7vw,15px);
        line-height: 2.2em;
        letter-spacing: .1em;
      }
    }
  }


  .sec1{
    background: #006070;
    padding-top: 200px;
    .s1_top{
      justify-content: flex-end;
      position: relative;
      &::before{
        content: "";
        width: 100%;
        height: 95%;
        background: #ECEEEF;
        position: absolute;
        top: -25px;
        left: 0;
      }
      .text_area{
        width: 44%;
        max-width: 600px;
        margin-top: 20px;
        margin-right: -10vw;
        position: relative;
        .s1_p1{
          font-size: clamp(10px,5vw,30px);
          color: #A07A2D;
        }
        .s1_p2{
          font-size: clamp(10px,3vw,14px);
          line-height: 2.3em;
          margin-top: 20px;
          text-align: left;
        }
      }
      .img_area{
        width: 56%;
        // max-width: 1140px;
      }
    }

    .s1_bottom{
      margin-top: 150px;
      img{
        vertical-align: bottom;
      }
      .ttl_area{
        margin-bottom: 50px;
        p{
          color: #fff;
        }
        .ttl{
          color: #CEB274;
        }
      }
    }
  }
  // ______________________________________
  @media screen and (max-width: 1410px){
    .sec1{
      .s1_top{
        .text_area{
          margin-right: 0px;
          .s1_p1{
            font-size: clamp(10px,2vw,28px);
          }
          .s1_p2{
            font-size: clamp(10px,1.5vw,12px);
            br{
              display: none;
            }
          }
        }
      }
    }
    /*@media screen end*/}
    // ______________________________________
    // ______________________________________
    // ______________________________________
  // ______________________________________


  @media screen and (max-width: inc_set.$media_sp){
    .design_wrap{
      .ttl_area{
        width: 95%;
        .p2{
          text-align: left;
          line-height: 1.8em;
        }
      }
    }

    .sec1{
      padding-top: 80px;
      .s1_top{
        &::before{
          height: 75%;
        }
        .text_area{
          width: 95%;
          text-align: center;
          margin: 20px auto 10px;
          .s1_p1{
            font-size: clamp(10px,5.2vw,20px);
          }
          .s1_p2{
            font-size: clamp(10px,3.8vw,15px);
            letter-spacing: -0.06em;
            line-height: 2em;
          }
        }
        .img_area{
          width: 100%;
        }
      }

      .s1_bottom{
        margin-top: 80px;
        .ttl_area{
          margin-bottom: 20px;
        }
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________

  .sec2{
    background: #ECEEEF;
    padding: 100px 0;
    .ttl_area{
      .ttl{
        color: #006070;
      }
    }

    .s2_map_p{
      text-align: center;
      font-size: clamp(10px,4vw,18px);
      margin: 60px 0 -25px;
    }

    .img_box{
      background: #fff;
      height: 160px;
      overflow: hidden;
      .img_area{
        width: 45%;
        img{
          vertical-align: bottom;
        }
        .img_note{
          text-shadow: 2px 2px 8px #000, -2px 2px 8px #000, 2px -2px 8px #000, -2px -2px 8px #000;
        }
      }
      .text_area{
        width: 55%;
        height: 100%;
        .s2_p1{
          width: 92%;
          margin: 15px auto 0;
          font-size: clamp(10px,1.5vw,15px);
          letter-spacing: .01em;
          border-bottom: solid 1px #256270;
          span{
            background: #256270;
            color: #fff;
            padding: 3px 5px 0;
            font-size: clamp(10px,4vw,20px);
            margin-right: 8px;
          }
        }
        .s2_p2{
          height: 75%;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          flex-direction: column;
          color: #256270;
          font-size: clamp(10px,2vw,22px);
          line-height: 1.3em;
          font-weight: 600;
          span{
            font-size: clamp(10px,8vw,28px);
          }
        }
      }
      .close{
        position: absolute;
        top: 2px;
        right: 5px;
        width: 25px;
        height: 25px;
        cursor: pointer;
        span{
          width: 100%;
          height: 2px;
          position: absolute;
          left: 50%;
          top: 50%;
          background: #808080;
          &:nth-of-type(1){
            transform: translate(-50%, -50%) rotate(45deg);
          }
          &:nth-of-type(2){
            transform: translate(-50%, -50%) rotate(-45deg);
          }
        }
      }
    }

    .s2_top{
      margin: 0 0  50px;
      .map{
        max-width: 1100px;
        margin: 0 auto;
        mix-blend-mode: darken;
        .num{
          position: absolute;
          padding: 5px 5px 2px;
          background: #256270;
          cursor: pointer;
          animation: desi_num 2s ease forwards infinite;
          transition: .5s;
          z-index: 1;
          @keyframes desi_num {
            0% {
              filter: drop-shadow(0 0 0px rgba(147, 31, 44, 0)) drop-shadow(0 0 0px rgba(147, 31, 44, 0));
            }
            50% {
              filter: drop-shadow(0 0 5px #256270) drop-shadow(0 0 5px #256270);
            }
            100% {
              filter: drop-shadow(0 0 0px rgba(147, 31, 44, 0)) drop-shadow(0 0 0px rgba(147, 31, 44, 0));
            }
          }
          p{
            text-align: center;
            font-size: 14px;
            letter-spacing: .01em;
            color: #fff;
            span{
              background: #fff;
              color: #256270;
              padding: 1px 3px 0;
              font-size: 18px;
              margin-right: 5px;
            }
          }
          &.num1{
            top: 39%;
            right: 14%;
          }
          &.num2{
            top: 57%;
            right: 38%;
          }
          &.num3{
            top: 49.5%;
            right: 12%;
          }
          &.num4{
            bottom: 9%;
            left: 44%;
          }
          &.num4_2{
            bottom: 29.5%;
            right: 10%;
          }
          &.num5{
            top: 40%;
            left: 45%;
          }
          &.num5_2{
            top: 32%;
            left: 37%;
          }
          &.num6{
            bottom: 39%;
            left: 13%;
          }
          &.num7{
            top: 45%;
            right: 34%;
          }
          &.num8{
            top: 51%;
            left: 20%;
          }
        }

        .popup{
          position: absolute;
          opacity: 0;
          width: 50%;
          max-width: 550px;
          border: solid 1px rgba(0,0,0,.5);
          .img_box{
            .img_area{
              .img_note{
                bottom: 0;
              }
            }
          }
          &.active{
            opacity: 1;
            z-index: 2;
          }
          &.no_active{
            opacity: 0;
          }
          &.popup_01{
            top: 22%;
            right: 14%;
          }
          &.popup_02{
            top: 40%;
            right: 38%;
          }
          &.popup_03{
            top: 31.5%;
            right: 12%;
          }
          &.popup_04{
            bottom: 14%;
            left: 20%;
          }
          &.popup_05{
            top: 16%;
            left: 8%;
          }
          &.popup_06{
            bottom: 45%;
            left: 0%;
          }
          &.popup_07{
            top: 27%;
            right: 34%;
          }
          &.popup_08{
            top: 32%;
            left: 0%;
          }
        }
        .img_note{
          bottom: 50px;
        }
      }
    }

    .s2_center{
      // margin-bottom: 100px;
      .text_wrap{
        max-width: 1100px;
        width: 95%;
        margin: 30px auto 0;
        .img_box{
          width: calc(97%/2);
          margin-bottom: 30px;
        }
      }
    }

    .s2_bottom{
      max-width: 950px;
      margin: 0 auto;
      .text_area{
        margin-bottom: -80px;
        .s2b_p1{
          font-size: clamp(10px,4.5vw,20px);
          margin-bottom: 10px;
        }
        .s2b_p2{
          font-size: clamp(10px,3vw,14px);
        }
      }
      .s2b_img_wrap{
        .img_area{
          mix-blend-mode: darken;
        }
      }
    }
  }

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
    .sec2{
      padding: 60px 0;
      .ttl_area{
        margin-bottom: 30px;
      }
      .s2_map_p{
        margin: 0;
      }
      .img_box{
        height: auto;
        .img_area{
          width: 100%;
        }
        .text_area{
          width: 100%;
          height: 40vw;
          max-height: 200px;
          .s2_p1{
            // width: 92%;
            font-size: clamp(10px,3.5vw,13px);
            text-align: center;
            span{
              display: block;
              width: 15px;
              margin: 0 auto 10px;
              text-align: center;
            }
          }
          .s2_p2{
            height: 52%;
            font-size: clamp(10px,4.2vw,18px);
            letter-spacing: .01em;
            span{
              padding-top: 5px;
            }
          }
        }
        .close{
          span{
            background: #fff;
            filter: drop-shadow(0px 0px 5px rgba(0,0,0,0.8));
          }
        }
      }

      .s2_top{
        margin: -25px 0 30px 0;
        // overflow: scroll;
        .map{
          // width: 250%;
          .num{
            p{
              span{
                margin-right: 0;
                padding: 3px 3px 0;
              }
              b{
                display: none;
              }
            }
          }
          .popup{
            width: 60vw;
            max-width: 250px;
            transform: translate(-50%,0);
            &.popup_01{
              top: 18%;
              right: auto;
              left: 50%;
            }
            &.popup_02{
              top: 18%;
              right: auto;
              left: 50%;
            }
            &.popup_03{
              top: 18%;
              right: auto;
              left: 50%;
            }
            &.popup_04{
              bottom: auto;
              top: 18%;
              left: 50%;
            }
            &.popup_05{
              top: 18%;
              left: 50%;
            }
            &.popup_06{
              bottom: auto;
              top: 18%;
              left: 50%;
            }
            &.popup_07{
              top: 18%;
              right: auto;
              left: 50%;
            }
            &.popup_08{
              top: 18%;
              left: 50%;
            }
          }
          .img_note{
            bottom: 0;
          }
        }
      }

      .arrow{
        width: 15%;
        max-width: 110px;
        margin: 0 auto;
      }
      .pnc{
        justify-content: center;
        align-items: center;
        div{
          width: 12%;
          max-width: 60px;
        }
      }
      //
      // .s2_center{
      //   margin: 50px 0;
      // }

      .s2_bottom{
        max-width: 950px;
        margin: 0 auto;
        .text_area{
          width: 95%;
          margin: 0 auto;
        }
        .s2b_img_wrap{
            overflow: scroll;
          .img_area{
            width: 150%;
          }
        }
        // .img_note{
        //   font-size: 10px;
        //   text-align: right;
        // }
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){

  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________
}
