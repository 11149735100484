@use "inc/setting" as inc_set;
@use "inc/reset" as inc_re;

#modelroom{
  .main_wrap{
    .imgbox{
      background-image: url(../img/modelroom/main.jpg);
    }
  }

  .flex_area{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    &.fld{
      flex-direction: row-reverse;
    }
  }

  .sec{
    padding: 100px 0;
    .max1000{
      max-width: 1050px;
      width: 95%;
      margin: 0 auto;
    }
    p{
      &.col_fff{
        color: #fff;
      }
    }
    .t_r{
      width: 100%;
      text-align: right;
      font-size: clamp(10px,4.5vw,22px);
      letter-spacing: .1em;
    }
    .img_area{
      position: relative;
      p{
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 5px;
        font-size: 10px;
        color: #fff;
      }
    }
    .sec_p1,.sec_p2{
      text-align: center;
    }
    .sec_p1{
      color: #ceb274;
      font-size: clamp(10px,7.5vw,34px);
      letter-spacing: .1em;
    }
    .sec_p2{
      font-size: clamp(10px,4.2vw,18px);
      margin: 30px 0 60px;
    }
  }


  .sec1{
    div{
      max-width: 1500px;
      width: 75%;
      &:last-of-type{
        margin: 70px 0 0 auto;
      }
    }
  }
  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
    .main_wrap{
    }
    .flex_area{
      // &.fld{
      //   flex-direction: column-reverse;
      // }
    }
    .sec{
      padding: 50px 0;
      .sec_p2{
        margin: 15px 0 30px;
      }
    }

    .sec1{
      div{
        max-width: 600px;
        width: 100%;
        &:last-of-type{
          margin: 30px 0 0 auto;
        }
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________

  .sec2{
    background: #004747;
    padding: 100px 0 0;
    .s201_img{
      max-width: 850px;
      width: 95%;
      margin: 0 auto;
    }
    .s2_box{
      margin: 70px auto 100px;
      div{
        &.s2_b1{
          width: 55%;
        }
        &.s2_b2{
          width: 43%;
        }
      }
    }
    .img_area{
      img{
        vertical-align: bottom;
      }
    }
  }

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
    .sec2{
      padding: 50px 0 0;
      .s2_box{
        margin: 45px auto 45px;
        div{
          &.s2_b1{
            width: 100%;
            margin-bottom: 15px;
          }
          &.s2_b2{
            width: 100%;
          }
        }
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________

  .sec3{
    .flex_area{
      &:first-of-type{
        align-items: flex-start;
      }
      &:last-of-type{
        align-items: flex-end;
        margin-top: -20px;
      }
      .s3_b1{
        width: 32%;
        margin: 0 auto;
      }
      .s3_b2{
        width: 50%;
      }
    }
  }
  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
    .sec3{
      .flex_area{
        &:first-of-type{
          flex-direction: column-reverse;
        }
        &:last-of-type{
          margin-top: 0;
        }
        .s3_b1{
          width: 80%;
          margin: 10px 0;
        }
        .s3_b2{
          width: 100%;
          margin: 10px 0;
        }
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________
}
