@use "inc/setting" as inc_set;
@use "inc/reset" as inc_re;

#zeh{
  .main_wrap{
    margin-top: 100px;
    margin-bottom: 80px;
    .main{
      justify-content: flex-end;
      align-items: center;
      .main_txt{
        margin-right: 6vw;
        .m_p1{
          font-size: clamp(10px,3vw,45px);
          margin-bottom: 20px;
        }
        .m_p2{
          font-size: clamp(10px,1.5vw,18px);
          line-height: 1.8em;
        }
      }
      .imgbox{
        width: 52%;
      }
    }
  }

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
    .main_wrap{
      margin-top: 60px;
      margin-bottom: 20px;
      .main{
        width: 100%;
        flex-direction: column-reverse;
        align-items: flex-end;
        .main_txt{
          width: 95%;
          margin-right: 0;
          margin-top: 15px;
          p{
            letter-spacing: .01em;
          }
          .m_p1{
            margin-bottom: 10px;
            font-size: clamp(10px,8vw,40px);
          }
          .m_p2{
            font-size: clamp(10px,4vw,17px);
          }
        }
        .imgbox{
          width: 80%;
        }
      }
    }

  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________

  .flex_area{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    &.fld{
      flex-direction: row-reverse;
    }
  }

  .img_area{
    position: relative;
    .img_note{
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 10px;
      font-size: 10px;
      &.coler_white{
        color: #fff;
      }
    }
  }

  .merit_box{
    .merit{
      width: calc(90%/4);
      background: #fff;
      position: relative;
      padding: 20px 0 17px;
      &::before{
        content: "";
        width: 95%;
        height: 90%;
        position: absolute;
        top: 0;
        left: 0;
        clip-path: polygon(0 0, 0 100%, 100% 0);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
      p{
        text-align: center;
        font-size: clamp(10px,3.8vw,16px);
        line-height: 1.8em;
        position: relative;
        span{
          font-size: clamp(10px,7vw,30px);
        }
      }
      a{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }
      &.m1{
        color: #C1726C;
        &::before{
        background: #F6EEEC;
        }
      }
      &.m2{
        color: #AD941C;
        &::before{
        background: #F7EED4;
        }
      }
      &.m3{
        color: #49998E;
        &::before{
        background: #E6EEEC;
        }
      }
      &.m4{
        color: #7386A2;
        &::before{
        background: #EAEBEF;
        }
      }
    }
  }

  .j_link{
    position: relative;
    margin-top: -65px;
    padding-top: 65px;
  }

  .zeh_wrap{
    font-family: 'a1',serif;

    .ttl_p1{
      font-size: clamp(10px,5.2vw,38px);
      text-align: center;
    }
    .small{
      font-size: 10px;
      letter-spacing: .01em;
      &.text_r{
        text-align: right;
        width: 100%;
      }
    }

    .sans-serif{
      font-family: sans-serif;
    }

    .max1000{
      max-width: 1050px;
      width: 93%;
      margin: 0 auto;
    }
  }

  .sec{
    padding: 60px 0;
    position: relative;
    &::before{
      content: "";
      width: 45%;
      height: 15vw;
      position: absolute;
      top: 0;
      left: 0;
      clip-path: polygon(0 0, 0 100%, 100% 0);
    }
    .sec_main{
      position: relative;
      height: 370px;
      margin-top: 20px;
      margin-bottom: 50px;
      .ttl_area{
        width: 100%;
        position: relative;
        z-index: 1;
        .ttl{
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 40px;
          .p1{
            color: #fff;
            text-align: center;
            width: 16%;
            line-height: 2em;
            font-size: clamp(10px,4vw,15px);
            position: relative;
            z-index: 0;
            padding: 5px;
            margin-right: 20px;
            span{
              font-size: clamp(10px,7vw,32px);
            }
            &::before{
              content: "";
              width: 95%;
              height: 90%;
              position: absolute;
              top: 0;
              left: 0;
              clip-path: polygon(0 0, 0 100%, 100% 0);
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%,-50%);
              z-index: -1;
            }
          }
          .p2{
            font-size: clamp(10px,5.8vw,28px);
          }
        }
        .p3{
          font-size: clamp(10px,3.9vw,15px);
          width: 37%;
          line-height: 1.8em;
          letter-spacing: .1em;
        }
      }
      .img_area{
        width: 60%;
        position: absolute;
        right: 0;
        top: -15px;
      }
    }

    .ttl_01{
      text-align: center;
      font-size: clamp(10px,4.6vw,22px);
      letter-spacing: .1em;
      padding: 7px 0 7px;
      position: relative;
      .s_small{
        font-size: clamp(10px,3.8vw,18px);
      }
      .s_large{
        font-weight: 600;
        font-size: clamp(10px,5.3vw,25px);
      }
    }

    .text_01{
      font-size: 14px;
      letter-spacing: .05em;
    }

    .text_02{
      font-size: clamp(10px,4vw,16px);
    }

    .w_95{
      width: 95%;
      margin: 0 auto;
    }

    .merit_box{
      margin-bottom: 55px;
      .merit{
        border: solid 1px #e0e0e0;
      }
    }
  }

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
    .img_scroll{
      overflow: scroll;
      img{
        width: 170%;
      }
    }

    .j_link{
      margin-top: -45px;
      padding-top: 45px;
    }

    .merit_box{
      width: 85%;
      .merit{
        width: calc(94%/2);
        padding: 10px 0 7px;
        &:nth-of-type(-n+2){
          margin-bottom: 15px;
        }
      }
    }

    .scroll{
      position: relative;
      max-width: 150px;
      width: 34%;
      p{
        font-size: clamp(10px,4vw,20px);
      }
      .arrow{
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0,-50%);
        width: 40px;
        height: 6px;
        span{
          background: #000;
          height: 1px;
          position: absolute;
          &:nth-of-type(1){
            width: 100%;
            bottom: 0;
            right: 0;
          }
          &:nth-of-type(2){
            width: 24%;
            bottom: 4px;
            right: 0;
            transform: rotate(42deg);
          }
        }
      }
    }

    .sec{
      padding: 35px 0;
      &::before{
        width: 55%;
        height: 35vw;
      }
      .sec_main{
        height: auto;
        margin-top: 0;
        // margin-bottom: 50px;
        .ttl_area{
          width: 100%;
          .ttl{
            margin-bottom: 10px;
            justify-content: space-between;
            .p1{
              width: 40%;
              max-width: 250px;
              line-height: 1.8em;
              margin: 0 0 15px;
            }
            .p2{
              letter-spacing: .03em;
            }
          }
          .p3{
            width: 100%;
            line-height: 1.5em;
          }
        }
        .img_area{
          width: 100%;
          position: relative;
          right: auto;
          top: auto;
          margin-top: 20px;
        }
      }

      .merit_box{
        margin-bottom: 25px;
      }
    }

  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________


  .sec_top{
    .top_ttl{
      width: 100%;
      background: url(../img/zeh/top_bg.jpg);
      background-size: cover;
      position: relative;
      height: 36vh;
      &::before{
        content: "";
        width: 100%;
        height: 100%;
        background: rgba(#EBF2E2,.87);
        position: absolute;
        top: 0;
        left: 0;
      }
      .text_area{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        position: relative;
        p{
          text-align: center;
        }
        .ttl_p1{
          margin-bottom: 25px;
        }
        .t_p2{
          font-size: clamp(10px,4vw,18px);
        }
      }
    }

    .merit_area{
      background: rgba(#EBF2E2,.87);
      padding: 50px 0;
      .ttl_p1{
        color: #747338;
        letter-spacing: .1em;
        span{
          font-size: clamp(10px,10vw,50px);
        }
      }
      .merit_box{
        margin-top: 30px;
        // .merit{
        //   width: calc(90%/4);
        //   background: #fff;
        //   position: relative;
        //   padding: 20px 0 17px;
        //   &::before{
        //     content: "";
        //     width: 95%;
        //     height: 90%;
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     clip-path: polygon(0 0, 0 100%, 100% 0);
        //     position: absolute;
        //     top: 50%;
        //     left: 50%;
        //     transform: translate(-50%,-50%);
        //   }
        //   p{
        //     text-align: center;
        //     font-size: clamp(10px,3.8vw,16px);
        //     line-height: 1.8em;
        //     position: relative;
        //     span{
        //       font-size: clamp(10px,7vw,30px);
        //     }
        //   }
        //   a{
        //     width: 100%;
        //     height: 100%;
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     z-index: 1;
        //   }
        //   &.m1{
        //     color: #C1726C;
        //     &::before{
        //     background: #F6EEEC;
        //     }
        //   }
        //   &.m2{
        //     color: #AD941C;
        //     &::before{
        //     background: #F7EED4;
        //     }
        //   }
        //   &.m3{
        //     color: #49998E;
        //     &::before{
        //     background: #E6EEEC;
        //     }
        //   }
        //   &.m4{
        //     color: #7386A2;
        //     &::before{
        //     background: #EAEBEF;
        //     }
        //   }
        // }
      }
    }

    .zeh_1{
      background: #E7EDED;
      padding: 65px 0;
      .zeh_in{
        background: #fff;
        padding: 40px 0 20px;
        position: relative;
        .p_ab{
          position: absolute;
          left: -20px;
          top: -15px;
          background: #009EA6;
          color: #fff;
          padding: 2px 3px 3px 10px;
          font-size: clamp(10px,4.8vw,20px);
        }
        .zeh_text{
          width: 88%;
          margin: 0 auto;
          .z_p1{
            font-size: clamp(10px,4vw,15px);
            margin-bottom: 20px;
            line-height: 1.8em;
            letter-spacing: .1em;
          }
          .img_area{
            align-items: center;
            .box{
              &:nth-of-type(1),&:nth-of-type(3){
                width: calc(92%/2);
                height: 315px;
              }
              &:nth-of-type(2){
                position: relative;
                width: 18px;
                height: 3px;
                span{
                  width: 100%;
                  height: 100%;
                  background: #009EA6;
                  position: absolute;
                  &:nth-of-type(1){
                    transform: rotate(0deg) translate(-50%,0);
                    left: 50%;
                  }
                  &:nth-of-type(2){
                    transform: rotate(90deg);
                    left: 0;
                  }
                }
              }
              p{
                color: #009EA6;
                background: #D3EBED;
                text-align: center;
                font-size: clamp(10px,5vw,22px);
                padding: 6px 0 3px;
                letter-spacing: .01em;
              }
              div{
                width: 90%;
                margin: 20px auto 0;
              }
            }
          }
        }
      }
    }

    .zeh_2{
      padding: 65px 0;
      .zeh_in{
        .text_area{
          div{
            width: 95%;
            margin-bottom: 20px;
          }
          p{
            font-size: clamp(10px,3.8vw,15px);
            line-height: 1.8em;
          }
        }
        .img_area{
          width: 65%;
        }
      }
    }

    .zeh_3{
      background: #EBF4F5;
      padding: 60px 0;
      .zeh_in{
        .z3_p1{
          color: #766D68;
          font-size: clamp(10px,5.5vw,25px);
          margin-bottom: 15px;
        }
      }
    }
  }
  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
    .sec_top{
      .top_ttl{
        background: url(../img/zeh/top_bg_sp.jpg);
        background-size: cover;
        height: 65vw;
        .text_area{
          p{
            letter-spacing: .01em;
          }
        }
      }

      .merit_area{
        padding: 30px 0;
        .ttl_p1{
          font-size: clamp(10px,5.8vw,35px);
          letter-spacing: .01em;
        }
        .merit_box{
          margin-top: 15px;
          // width: 85%;
          // .merit{
          //   width: calc(94%/2);
          //   padding: 10px 0 7px;
          //   &:nth-of-type(-n+2){
          //     margin-bottom: 15px;
          //   }
          // }
        }
      }

      .zeh_1{
        padding: 45px 0;
        .zeh_in{
          padding: 40px 0 25px;
          .p_ab{
            left: -5px;
            // padding: 2px 3px 3px 10px;
          }
          .zeh_text{
            .img_area{
              flex-direction: column;
              .box{
                &:nth-of-type(1),&:nth-of-type(3){
                  width: calc(96%/1);
                  height: auto;
                }
                &:nth-of-type(2){
                  margin: 30px 0;
                }
              }
            }
          }
        }
      }

      .zeh_2{
        padding: 45px 0;
        .zeh_in{
          .text_area{
            width: 100%;
            div{
              width: 70%;
              max-width: 350px;
            }
          }
          .img_area{
            width: 100%;
            margin-top: 20px;
          }
        }
      }

      .zeh_3{
        padding: 40px 0;
        .zeh_in{
          .z3_p1{
            text-align: center;
          }
          .img_area{
            margin: 5px 0 10px;
          }
          .scroll{
            margin-bottom: 15px;
          }
        }
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________


  .sec1{
    &::before{
      background: #F6EEEC;
    }
    .sec_main{
      .ttl_area{
        .ttl{
          .p1{
            background: #C1726C;
            &::before{
              background: #B4605D;
            }
          }
        }
      }
    }

    .s1_wrap{
      .ttl_01{
        background: #FBF7F6;
        border-top: solid 1px #C1726C;
        border-bottom: solid 1px #C1726C;
        color: #C1726C;
        margin-bottom: 20px;
      }

      .img_area{
        .img_note{
          position: relative;
          text-align: right;
          margin-top: -10px;
        }
      }

      .s1_box1{
        margin: 50px auto;
      }

      .s1_box2{
        .box{
          margin: 5px 0;
          width: calc(90%/3);
          .text_02{
            border-bottom: solid 1px #7D7D7D;
            color: #C1726C;
            margin-bottom: 10px;
            letter-spacing: .01em;
            padding-bottom: 2px;
            .number{
              background: #C1726C;
              border-radius: 5px;
              padding: 3px 5px;
              color: #fff;
              font-size: clamp(10px,4.2vw,18px);
              margin-right: 5px;
            }
            .letter{
              font-size: clamp(10px,3.5vw,13px);
            }
          }
          .img_area{
            margin-top: 10px;
          }
          // &:nth-of-type(-n+4){
          //   width: calc(90%/3);
          // }
          // &:nth-of-type(5){
          //   width: calc(96%/3*2);
          //   .img_area{
          //     margin-top: 0;
          //   }
          //   .s1_06{
          //     .l{
          //       width: 32%;
          //     }
          //     .r{
          //       width: 65%;
          //       .text_01{
          //         margin-bottom: 2px;
          //       }
          //     }
          //   }
          // }
          // &:nth-of-type(n+6){
          //   width: calc(92%/4);
          // }
        }
      }

      .s1_box3{
        margin-top: 50px;
        .s1b3_in{
          align-items: center;
          .text_02{
            width: 52%;
            letter-spacing: .1em;
            .border_b{
              border-bottom: solid 1px #C1726C;
            }
          }
          .img_area{
            width: 47%;
          }
        }
      }
    }
  }

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
    .sec1{
      .s1_wrap{
        .img_area{
          .img_note{
            position: relative;
            text-align: right;
            margin-top: -10px;
          }
        }

        .s1_box1{
          margin: 25px auto;
        }

        .s1_box2{
          margin-top: 35px;
          .box{
            &:nth-of-type(-n+4){
              width: calc(96%/2);
            }
            &:nth-of-type(5){
              width: 100%;
              .s1_06{
                .l{
                  width: 55%;
                  margin: 0 auto;
                }
                .r{
                  width: 100%;
                  .text_01{
                    margin-bottom: 5px;
                  }
                }
              }
            }
            &:nth-of-type(n+6){
              width: calc(96%/2);
            }
          }
        }

        .s1_box3{
          margin-top: 50px;
          .s1b3_in{
            .text_02{
              width: 100%;
              margin-bottom: 10px;
            }
            .img_area{
              width: 100%;
            }
          }
        }
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________

  .sec2{
    &::before{
      background: #F7EED4;
    }
    .sec_main{
      .ttl_area{
        .ttl{
          .p1{
            background: #AD941C;
            &::before{
              background: #A48822;
            }
          }
        }
      }
    }

    .s2_wrap{
      .ttl_01{
        background: #F7EED4;
        border-top: solid 1px #AD941C;
        border-bottom: solid 1px #AD941C;
        color: #AD941C;
        margin-bottom: 20px;
      }
      .s2_p1{
        text-align: center;
        font-size: clamp(10px,4vw,18px);
        border-bottom: solid 1px #000000;
        padding-bottom: 10px;
        margin-bottom: 20px;
        span{
          font-size: clamp(10px,4.5vw,24px);
        }
      }

      .s2_box1{
        margin-bottom: 10px;
        div{
          &:last-of-type{
            width: 80%;
            margin: 40px auto 0;
          }
        }
      }
      .s2_box2{
        margin: 60px 0 40px;
        .s2b2in{
          margin-bottom: 10px;
          .box{
            width: calc(96%/2);
            padding: 20px 0;
            .img_in{
              width: 80%;
              margin: 15px auto;
              &.w_90{
                width: 92%;
              }
            }
            .s2b2_p1{
              text-align: center;
              font-size: clamp(10px,4vw,20px);
              font-family: "axis",sans-serif;
            }
            &.box_col{
              background: #F1F5F4;
              .s2b2_p1{
                color: #218B7F;
              }
            }
          }
          .small{
            margin-top: 10px;
            width: 95%;
          }
        }
      }
    }
  }

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
    .sec2{
      .s2_wrap{
        .ttl_01{
          letter-spacing: .01em;
        }
        .s2_p1{
          letter-spacing: .01em;
        }

        .s2_box1{
          div{
            &:last-of-type{
              width: 90%;
            }
          }
        }
        .s2_box2{
          .s2b2in{
            .box{
              width: calc(100%/1);
              .img_in{
                margin: 10px auto;
              }
            }
          }
        }
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________

  .sec3{
    &::before{
      background: #E6EEEC;
    }
    .sec_main{
      .ttl_area{
        .ttl{
          .p1{
            background: #49998E;
            &::before{
              background: #218B7F;
            }
          }
        }
      }
    }

    .s3_wrap{
      .ttl_01{
        background: #F1F5F4;
        border-top: solid 1px #218B7F;
        border-bottom: solid 1px #218B7F;
        color: #218B7F;
        margin-bottom: 20px;
      }
      .s3_box1{
        .img_area{
          .img_note{
            width: 77%;
            right: auto;
            left: 0;
            padding: 5px;
          }
        }
      }

      .s3_box2{
        margin-top: 50px;
      }
    }
  }

  // ______________________________________
  @media screen and (max-width: 1000px){
    .sec3{
      .s3_wrap{
        .s3_box1{
          .img_area{
            .img_note{
              width: 100%;
              position: relative;
            }
          }
        }
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________


  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
    .sec3{
      .s3_wrap{
        .s3_box1{
          .img_area{
            .img_note{
              padding: 10px 0 0;
            }
          }
        }

        .s3_box2{
          margin-top: 30px;
          .img_area{
            .small{
              text-align: left;
            }
          }
        }
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________


  .sec4{
    &::before{
      background: #EAEBEF;
    }
    .sec_main{
      height: auto;
      .ttl_area{
        .ttl{
          .p1{
            background: #7386A2;
            &::before{
              background: #5A7492;
            }
          }
        }
      }
    }

    .s4_wrap{
      .ttl_01{
        background: #EAEBEF;
        border-top: solid 1px #5A7492;
        border-bottom: solid 1px #5A7492;
        color: #5A7492;
        margin-bottom: 20px;
      }

      .s4_box1{
        .img_area{
          margin: 10px auto;
        }
      }

      .s4_box2{
        margin: 40px 0;
        .s4b2{
          .l{
            width: 36%;
          }
          .r{
            width: 60%;
          }
        }

        .s4b3{
          .l{
            width: 45%;
            .small{
              margin-top: 10px;
            }
          }
          .r{
            width: 52%;
          }
        }
      }
    }
  }

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
    .sec4{
      .s4_wrap{
        .s4_box2{
          margin: 40px 0;
          .s4b2,.s4b3{
            .l{
              width: 100%;
              margin-top: 10px;
              .small{
                margin: 10px 0;
              }
            }
            .r{
              width: 100%;
            }
          }
        }
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________

  .sec_bottom{
    .sb_bg{
      background: #E7EDED;
      padding: 50px 0;
      .sb_in{
        .sb_p{
          font-size: clamp(10px,4vw,18px);
          line-height: 1.7em;
          margin-bottom: 20px;
          span{
            background: #FFFBCF;
            color: #004D72;
            padding: 5px 5px 2px;
          }
        }
        .l{
          width: 45%;
        }
        .r{
          width: 45%;
          display: flex;
          align-items: center;
          flex-direction: column;
          div{
            width: 42%;
            margin: 0 auto;
          }
        }
      }
    }

    .sb_bg2{
      width: 100%;
      height: 49vw;
      background: url(../img/zeh/bottom_bg.jpg);
      background-size: cover;
      position: relative;
      .text_area{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        p{
          text-align: center;
          line-height: 2em;
        }
        .sbg_p1{
          font-size: clamp(10px,1.6vw,22px);
          &:nth-of-type(1){
            margin-top: 6vw;
          }
          &:nth-of-type(2){
            margin: 3vw 0 5vw;
          }
        }
        .sbg_p2{
          font-size: clamp(10px,2.5vw,35px);
        }
      }
    }
  }

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
    .sec_bottom{
      .sb_bg{
        padding: 35px 0;
        .sb_in{
          .sb_p{
            text-align: center;
          }
          .l{
            width: 100%;
            div{
              width: 95%;
              margin: 0 auto;
              .small{
                margin-top: 10px;
              }
            }
          }
          .r{
            width: 100%;
            margin-top: 20px;
          }
        }
      }

      .sb_bg2{
        height: 142vw;
        background: url(../img/zeh/bottom_bg_sp.jpg);
        background-size: cover;
        .text_area{
          p{
            line-height: 1.8em;
            letter-spacing: .01em;
          }
          .sbg_p1{
            font-size: clamp(10px,4vw,22px);
            &:nth-of-type(1){
              margin-top: 12vw;
            }
            &:nth-of-type(2){
              margin: 5vw 0 10vw;
            }
          }
          .sbg_p2{
            font-size: clamp(10px,6.2vw,35px);
          }
        }
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________
}
