@use "setting" as inc_set;

footer {
  width: 100%;
  position: relative;
  z-index: 10;
  background: #fff;

  .bnr_area {
    max-width: 750px;
    width: 90%;
    margin: 0 auto;
    filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.4));
  }

  .top_footer {
    nav {
      width: 100%;
      background: #dcdcdc;
      padding: 10px 0;

      ul {
        width: 95%;
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;

        li {
          width: calc(100%/9);
          border-left: 1px solid #333;
          padding: 10px 0 5px;

          &.none {
            opacity: .3;
            pointer-events: none;
          }

          &:last-child {
            border-right: 1px solid #333;
          }

          .new {
            display: none;
          }

          p {
            font-size: clamp(0px, inc_set.$vw_20px, 14px);
            text-align: center;
          }
        }
      }
    }

    .notice_box {
      width: 90%;
      margin: 40px auto 0;
      max-width: 1000px;

      p {
        text-align: center;
      }

      .n_p1 {
        color: #000;
        font-size: clamp(10px, 4vw, 18px);
      }

      .n_p2 {
        color: #877D54;
        font-size: clamp(10px, 6vw, 38px);
        margin: 20px 0 10px;
        line-height: 1.2em;

        span {
          &.s1 {
            font-size: clamp(0px, 4vw, 30px);
          }

          &.s2 {
            letter-spacing: -0.1em;
          }

          &.mark {
            margin-left: -10px;
          }
        }
      }

      .btn_flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        max-width: 800px;
        margin: 0 auto;

        .cv {
          width: calc(90%/2);
          margin: 20px auto 0;
          padding: 3px 0;

          p {
            color: #006070;
            display: block;
            font-size: clamp(0px, inc_set.$vw_28px, 20px);
          }

          &.req {
            background: linear-gradient(90deg, #c2c2c2 0%, #fff 50%, #c2c2c2 100%);

            &:hover {
              background: linear-gradient(90deg, #fff 0%, #fff 50%, #fff 100%);
            }
          }

          &.rev {
            background: linear-gradient(90deg, #D3A685 0%, #fff 50%, #D3A685 100%);

            &:hover {
              background: linear-gradient(90deg, #fff 0%, #fff 50%, #fff 100%);
            }
          }
        }
      }
    }

    .tel_box {
      // width: 95%;
      // padding: 50px 0;
      width: 100%;
      margin: 0 auto;
      padding: 50px 0 20px;
      text-align: center;

      img {
        max-width: 500px;
        margin: 0 auto;
      }

      p {
        text-align: center;
        letter-spacing: .15em;
        line-height: 1.5em;
        font-size: clamp(0px, inc_set.$vw_20px, 14px);
      }

      .vc_info {
        width: 100%;
        max-width: 800px;
        font-size: clamp(0px, 3vw, 14px);
        margin: 20px auto;
        color: #960e16;
        border: 1px solid #960e16;
        padding: 12px 0 8px;
      }
    }
  }


  .bottom_footer {
    border-top: 1px solid #333;

    .brand {
      width: 95%;
      max-width: 900px;
      margin: 0 auto;
      padding: 30px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      .brand_logo {
        width: 50%;
        max-width: 400px;

        a {
          display: flex;
          justify-content: center;
          align-items: center;

          p {
            width: 25%;
            font-size: clamp(0px, inc_set.$vw_20px, 12px);
          }

          .logo {
            width: 70%;
          }
        }
      }
    }
  }

  .footer_nav {
    // background: url(../img/common/f-nav_bg.jpg);
    background-size: 100% 100%;
    // padding: 60px 0 80px;
    padding: 60px 0 40px;
    position: relative;

    // &::before{
    //   content: "";
    //   width: 100%;
    //   height: 100%;
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   background: rgba(#29332E,.6);
    // }
    .text_area {
      // max-width: 900px;
      // width: 90%;
      margin: 0 auto;
      text-align: center;
      position: relative;

      .sec4_ttl {
        font-size: clamp(10px, 6vw, 30px);
        margin-bottom: 30px;
        color: #247488;
        letter-spacing: .1em;
      }

      .content_btn {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        flex-wrap: wrap;

        div {
          width: calc(100%/5);
          overflow: hidden;
          position: relative;

          img {
            vertical-align: bottom;
            transform: scale(1);
            transition: 2s;
          }

          &::before {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: rgba(#29332E, .6);
            transition: 1s;
            z-index: 1;
          }

          // &:nth-of-type(n+4){
          //   margin-top: 30px;
          // }
          p {
            position: absolute;
            top: 55%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: clamp(10px, 2.2vw, 25px);
            // line-height: 1em;
            z-index: 2;
            color: #fff;
            width: 100%;

            span {
              font-size: clamp(10px, 3vw, 14px);
            }
          }

          a {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 3;
          }

          &:hover {
            &::before {
              background: rgba(#29332E, .2);
            }

            img {
              transform: scale(1.2);
            }
          }
        }
      }
    }
  }

  .copy {
    background: #006070;
    padding: 10px 0;

    p {
      color: #fff;
      font-size: clamp(0px, inc_set.$vw_20px, 14px);
      text-align: center;
    }
  }
}

// ______________________________________
@media screen and (max-width: inc_set.$media_sp) {
  footer {
    width: 100%;
    padding: 0 0 40px;

    .top_footer {
      nav {
        display: none;
      }

      .notice_box {
        .n_p2 {
          margin: 10px 0 0;

          span {
            &.s2 {
              letter-spacing: -0.2em;
            }

            &.mark {
              margin-left: -5px;
            }
          }
        }

        .btn_flex {
          max-width: 800px;
          margin: 0 auto;

          .cv {
            width: calc(90%/1);
          }
        }
      }

      .tel_box {
        width: 95%;
        max-width: 550px;
        padding: inc_set.$vw_50px 0;

        .vc_info {
          margin: 20px auto 0;
        }
      }
    }


    .bottom_footer {
      .brand {
        padding: 10px 0;
        display: block;

        .brand_logo {
          width: 100%;
          max-width: 200px;
          margin: 15px auto;

          a {
            display: block;
            text-align: center;

            p {
              width: 100%;
              text-align: center;
              margin: 0 0 5px;
            }

            .logo {
              width: 100%;
            }
          }
        }
      }
    }

    .footer_nav {
      // background: url(../img/common/f-nav_bg_sp.jpg);
      // background-size: cover;
      padding: 60px 0 40px;

      .text_area {
        .content_btn {
          div {
            width: calc(100%/2);

            // &:nth-of-type(n+3){
            //   margin-top: 30px;
            // }
            p {
              font-size: clamp(10px, 4.2vw, 25px);
            }
          }
        }
      }
    }

    .copy {
      padding: 5px 0;
    }
  }

  /*@media screen end*/
}

// ______________________________________
// ______________________________________
// ______________________________________
// ______________________________________
@media screen and (max-width: inc_set.$media_sp) {

  /*@media screen end*/
}

// ______________________________________
// ______________________________________
// ______________________________________
// ______________________________________
@media screen and (max-width: inc_set.$media_sp) {

  /*@media screen end*/
}

// ______________________________________
// ______________________________________
// ______________________________________
// ______________________________________
@media screen and (max-width: inc_set.$media_sp) {

  /*@media screen end*/
}

// ______________________________________
// ______________________________________
// ______________________________________
// ______________________________________
@media screen and (max-width: inc_set.$media_sp) {

  /*@media screen end*/
}

// ______________________________________
// ______________________________________
// ______________________________________