@use "inc/setting" as inc_set;
@use "inc/reset" as inc_re;

#map{
  main{
    background: #e6e6e6;
  }
  .main_wrap{
    .imgbox{
      background-image: url(../img/map/main.jpg);
    }
  }

  .sec{
    margin: 100px auto;
    .lead_box{
      margin: 0 auto 50px;
      p{
        text-align: center;
      }
    }
    .img_area{
      margin: 50px auto;
    }
    .btn_area{
      width: 95%;
      max-width: 700px;
      margin: 50px auto 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      .btn{
        width: calc(95%/2);
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: linear-gradient(90deg, #cdc499 0%, #ffffff 50%, #cdc499 100%);
        box-shadow: 0 0 15px rgba(0,0,0,.5);
        transition: .5s;
        &:hover{
          box-shadow: 0 0 0 rgba(0,0,0,0);
        }
        p{
          color: #505050;
          font-size: clamp(10px,4vw,20px);
          line-height: 1em;
          text-align: center;
          padding: 10px 0 5px;
          .span1{
            font-size: clamp(10px,3vw,12px);
          }
        }
      }
    }
  }
  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
    .sec{
      margin: inc_set.$vw_100px auto;
      .lead_box{
        margin: 0 auto 10px;
      }
      .img_area{
        margin: 30px auto;
      }
      .btn_area{
        max-width: 500px;
        width: 85%;
        margin: inc_set.$vw_50px auto 0;
        .btn{
          width: 100%;
          height: 40px;
          margin: 0 auto 15px;
          padding: 2px 0;
          p{
            padding: 10px 0 5px;
          }
        }
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){

  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________
}
