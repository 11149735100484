@use "inc/setting" as inc_set;
@use "inc/reset" as inc_re;

#outline{
  main{
    background: #e6e6e6;
  }

  .lead_box{
    margin: 150px 0 50px;
    p{
      text-align: center;
    }
  }
  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
    .lead_box{
      margin: inc_set.$vw_150px 0 inc_set.$vw_50px;
      p{
        text-align: center;
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________


  .sec{
    width: 95%;
    max-width: 1000px;
    margin: 0 auto 50px;
    .lead{
      text-align: left;
      font-size: clamp(10px,5.2vw,22px);
    }
    table{
      width: 100%;
      margin: 20px auto 50px;
      th,td{
        text-align: left;
        letter-spacing: .1em;
        line-height: 1.5em;
        // font-size: clamp(0px, inc_set.$vw_24px, 16px);
        font-size: clamp(10px,3.4vw,16px);
        border-bottom: 1px solid #9e9e9e;
        padding: 10px;
        box-sizing: border-box;
      }
      th{
        width: 25%;
      }
      td{
        width: 75%;
        span{
          font-size: clamp(0px,2.8vw,14px);
        }
      }
    }

    .notice_box{
      margin: 50px auto;
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      .p1{
        width: 20%;
        text-align: center;
        border: 1px solid #000;
        font-size: clamp(0px,inc_set.$vw_24px,16px);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
      .p2{
        width: 79%;
        text-align: left;
        font-size: clamp(0px,inc_set.$vw_20px,14px);
      }
    }

    .update{
      margin: 50px auto;
      p{
        font-size: clamp(10px,3.2vw,16px);
      }
    }
  }
  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
    .sec{
      margin: 0 auto inc_set.$vw_50px;
      .lead{
        letter-spacing: .1em;
      }
      table{
        margin: inc_set.$vw_20px auto 10vw;
        th,td{
          display: block;
        }
        th{
          width: 100%;
          background: #cecece;
        }
        td{
          width: 100%;
        }
      }

      .notice_box{
        margin: inc_set.$vw_50px auto;
        display: block;
        .p1{
          width: 100%;
          padding: 10px 0;
          margin: 0 0 10px;
        }
        .p2{
          width: 100%;
        }
      }

      .update{
        margin: inc_set.$vw_50px auto;
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){

  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________
}
