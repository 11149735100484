/*-----------------font---------------------*/
@font-face {
	font-family: 'a1';
	src: url(../fonts/a1.otf) format('opentype');
}
@font-face {
	font-family: 'gara';
	src: url(../fonts/gara.otf) format('opentype');
}
@font-face {
	font-family: 'axis';
	src: url(../fonts/axis.otf) format('opentype');
}
@font-face {
	font-family: 'newyork';
	src: url(../fonts/newyork.otf) format('opentype');
}



body{
  font-family: 'gara','a1',serif;
}
.a1{
  font-family: 'a1',serif;
}
.gara{
  font-family: 'gara',serif;
}
.allura{
	font-family: "allura", sans-serif;
	font-weight: 400;
	font-style: normal;
}
.axis{
  font-family: 'axis',sans-serif;
}
.newyork{
  font-family: 'newyork',sans-serif;
}
