@use "inc/setting" as inc_set;
@use "inc/reset" as inc_re;

#brand{
  .main_wrap{
    .imgbox{
      background-image: url(../img/brand/main.jpg);
    }
  }

  .sec_wrap{
    .sec_in{
      max-width: 1000px;
      margin: 0 auto;
    }
    .sec_1,.sec_3{
      background: #DBDBDB;
    }
    .sec_1{
      padding: 55px 0;
      .p1{
        font-size: clamp(10px,8vw,32px);
        color: #005959;
        text-align: center;
      }
      .p2{
        font-size: clamp(10px,3.7vw,14px);
        text-align: center;
        margin-top: 25px;
      }
      .text_area{
        margin: 50px 0 70px;
        .flex{
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0 auto;
          &:not(:last-of-type) {
            margin-bottom: 32px;
          }
          .t_p1,.t_p2{
            color: #73572A;
            font-size: clamp(12px,3.3vw,19px);
            span{
              &.s1{
                font-size: 60px;
              }
              &.s2{
                font-size: clamp(25px,7vw,32px);
              }
              &.s3{
                font-size: clamp(15px,4vw,19px);
              }
              &.gray{
                color: #505050;
              }
            }
          }
          .t_p1{
            width: 28%;
            position: relative;
            &::before{
              content: "";
              display: inline-block;
              width: 30px;
              height: 1px;
              background: #73572A;
              position: absolute;
              top: 50%;
              right: 0;
              transform: translate(0,-50%);
            }
          }
          .t_p2{
            width: 70%;
          }
        }
      }

      .img_area{
        .i_p1{
          text-align: center;
          color: #005959;
          font-size: clamp(20px,6vw,22px);
        }
        .flex{
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          margin: 20px 0 20px;
          .img{
            position: relative;
            .number{
              position: absolute;
              bottom: 5px;
              right: 0;
              font-size: 14px;
              color: #fff;
              background: #005959;
              display: inline-block;
              padding: 2px 6px 0 6px;
            }
          }
          .img_01{
            width: 50%;
          }
          .img_02{
            width: 48.5%;
          }
          .img_flex{
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-top: 10px;
            .left{
              width: 70%;
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
              align-items: flex-start;
              .img_03{
                width: 52%;
              }
              .img_04{
                width: 44.5%;
              }
              .img_05{
                width: 45%;
                margin-top: 10px;
              }
              .img_06{
                width: 52%;
                margin-top: 10px;
              }
            }
            .right{
              width: 28%;
            }
          }
        }
      }

      .nmber_area{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        max-width: 620px;
        margin: 0 -30px 0 auto;
        li{
          width: 50%;
          margin-bottom: 10px;
          font-size: clamp(10px,3.8vw,15px);
          span.number{
            font-size: 15px;
            color: #fff;
            background: #005959;
            display: inline-block;
            padding: 3px 6px 1px 6px;
            margin-right: 5px;
          }
          span.place{
            margin-left: -7px;
          }
          &.n01{
            order: 1;
          }
          &.n02{
            order: 3;
          }
          &.n03{
            order: 5;
          }
          &.n04{
            order: 7;
          }
          &.n05{
            order: 2;
          }
          &.n06{
            order: 4;
          }
          &.n07{
            order: 6;
          }
        }
      }
    }

    // ______________________________________
    @media screen and (max-width: inc_set.$media_sp){
      .sec_in{
        width: 95%;
      }
      .sec_1{
        padding: 55px 0;
        .p2{
          letter-spacing: .01em;
          line-height: 1.8;
        }
        .text_area{
          margin: 50px 0 70px;
          .flex{
            width: 90%;
            max-width: 500px;
            position: relative;
            &:not(:last-of-type) {
              margin-bottom: 50px;
            }
            .t_p1,.t_p2{
              span{
                &.s2{
                  position: absolute;
                  top: -12px;
                  left: 17%;
                }
              }
            }
            .t_p1{
              width: 15%;
              position: unset;
              &::before{
                display: none;
              }
            }
            .t_p2{
              width: 85%;
              position: absolute;
              right: -5px;
              top: 12px;
              letter-spacing: .1em;
            }
          }
        }

        .img_area{
          .flex{
            .img_01,.img_02{
              width: 100%;
            }
            .img_flex{
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
              margin-top: 5px;
              .img_03{
                width: 53%;
              }
              .img_04{
                width: 44.5%;
              }
              .bottom{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                .b_left{
                  width: 53%;
                  .img_06{
                    margin-top: 3px;
                  }
                }
                .b_right{
                  width: 44.5%;
                }
              }
            }
          }
        }

        .nmber_area{
          max-width: 800px;
          margin: 0 0 0 auto;
          flex-direction: column;
          li{
            width: 100%;
            span.number{
              padding: 4px 7px 1px 6px;
            }
            &.n01{
              order: 1;
            }
            &.n02{
              order: 2;
            }
            &.n03{
              order: 3;
            }
            &.n04{
              order: 4;
            }
            &.n05{
              order: 5;
            }
            &.n06{
              order: 6;
            }
            &.n07{
              order: 7;
            }
          }
        }
      }
    /*@media screen end*/}
    // ______________________________________
    // ______________________________________
    // ______________________________________

    .sec_2{
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url(../img/brand/s2_main.jpg);
      position: relative;
      padding: 0 0 80px;
      &::before{
        content: "";
        background: rgba(0,89,89,.3);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
      .mv_note{
        position: absolute;
        bottom: 0;
        right: 0;
        color: #fff;
        font-size: 10px;
      }
      .mv_text{
        max-width: 850px;
        width: 90%;
        position: absolute;
        left: 0;
      }
      .sec2_p1{
        padding: 100px 0 50px;
        font-size: clamp(23px,6.2vw,25px);
        text-align: center;
        background: linear-gradient(to right, #B1853A, #FFF5CF 70%, #A98341);
        -webkit-background-clip: text;
        -webkit-text-fill-color: rgba(0,0,0,0);
        position: relative;
      }
      .img_area{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        position: relative;
        .img_box{
          width: calc(96%/3);
          img{
            vertical-align: bottom;
          }
          .sec2_p2,.sec2_p3{
            text-align: center;
          }
          .sec2_p2{
            background: #005959;
            color:#fff;
            font-size: clamp(10px,4vw,15px);
            padding: 5px 0 2px;
          }
          .text_in{
              background: #fff;
              height: 70px;
              display: flex;
              justify-content: center;
              align-items: flex-start;
              flex-direction: column;
              padding: 0 10px;
            .sec2_p3{
              color:#005959;
              position: relative;
              font-size: clamp(10px,4vw,14px);
              letter-spacing: .01em;
              line-height: 1.3em;
              span.icon{
                display: inline-block;
                width: 25px;
                height: auto;
                margin-right: 5px;
              }
              &:nth-of-type(even){
                margin-top: 5px;
              }
            }
          }
          &:nth-of-type(1){
            .text_in{
              align-items: center;
            }
          }
        }
      }
    }
    // ______________________________________
    @media screen and (max-width: inc_set.$media_sp){
      .sec_2{
        padding: 0 0 40px;
        .sec2_p1{
          padding: 50px 0 30px;
        }
        .img_area{
          .img_box{
            width: calc(96%/1);
            margin-bottom: 20PX;
            .text_in{
              .sec2_p3{
                font-size: clamp(10px,3.8vw,15px);
                line-height: 1;
              }
            }
          }
        }
      }
    /*@media screen end*/}
    // ______________________________________
    // ______________________________________
    // ______________________________________


    .sec_3{
      padding: 55px 0;
      .logo{
        max-width: 300px;
        margin: 40px auto;
        width: 70%;
      }
      .sec3_p1,.sec3_p2{
        text-align: center;
      }
      .sec3_p1{
        font-size: clamp(25px,7.5vw,32px);
      }
      .sec3_p2{
        font-size: clamp(10px,4vw,14px);
        margin: 20px 0 60px;
      }
      .box_area{
        .sec3_p3{
          color: #005959;
          text-align: center;
          margin-bottom: 20px;
          font-size: clamp(20px,6vw,22px);
        }
        .flex{
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
        }
        .box_top{
          .box{
            width: 280px;
            height: 170px;
            background: #005959;
            color: #fff;
            margin: 0 25px 35px;
            text-align: center;
            .sec3_p4{
              border-top: solid 1px #DBDBDB;
              border-bottom: solid 1px #DBDBDB;
              padding: 10px 0 5px;
              width: 100%;
              font-size: clamp(10px,4.5vw,18px);
              margin: 22px 0 15px;
            }
            .sec3_p5{
              font-size: clamp(10px,4.2vw,14px);
              letter-spacing: .01em;
            }
          }
        }
      }
    }

    // ______________________________________
    @media screen and (max-width: inc_set.$media_sp){
      .sec_3{
        padding: 55px 0;
        .sec3_p1,.sec3_p2{
          letter-spacing: -0.1em;
        }
        .sec3_p2{
          line-height: 1.8;
        }
        .box_area{
          .flex{
            flex-direction: column;
          }
          .box_top{
            .box{
              width: 95%;
              height: 160px;
              margin: 0 auto;
              .sec3_p4{
                padding: 5px 0 2px;
              }
              .sec3_p5{
                height: auto;
              }
            }
          }
          .box_top{
            .box{
              &:nth-of-type(even){
                margin: 20px auto;
              }
            }
          }
        }
      }
    /*@media screen end*/}
    // ______________________________________
    // ______________________________________
    // ______________________________________
  }
}
