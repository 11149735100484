@use "inc/setting" as inc_set;
@use "inc/reset" as inc_re;

#lproomplan{
  .main_wrap{
    .main_cmn {
      height: 45vh;
      .main_txt {
        text-align: center;
        color: #fff;
        height: unset;
        width: 80%;
        max-width: 600px;
        p {
          font-size: clamp(22px,3vw,40px);
        }
        .line {
          padding: 5px 0;
          box-sizing: border-box;
          img {
            width: 100%;
          }
        }
        img {
          height: unset;
        }
      }
    }
    .imgbox{
      background-image: url(../img/lproomplan/main.jpg);
      &::before {
        background: #002327;
        opacity: 0.4;
      }
    }
  }

  img {
    vertical-align: bottom;
  }

  .imgbox {
    position: relative;
  }

  .imgnote {
    position: absolute;
    bottom: 3px;
    right: 3px;
    font-size: 10px;
  }

  .flbox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .w1000 {
    width: 95%;
    max-width: 1000px;
    margin: 0 auto;
  }

  .sec{
    text-align: center;
    .ttl_area {
      background-size: cover;
      background-position: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 5% 0;
      position: relative;
      .imgbox {
        width: 30%;
        max-width: 120px;
      }
      .p1 {
        font-size: clamp(10px,5vw,27px);
        color: #003C46;
        margin-top: 15px;
      }
    }
    .bk_blk {
      color: #fff;
      background-color: rgba(0, 0, 0, 0.5);
      bottom: 0;
      right: 0;
      padding: 3px 1em 0px;
    }
  }

  .lead {
    color: #005959;
  }


// ______________________________________
@media screen and (max-width: inc_set.$media_sp){
  .main_wrap{
    .main_cmn {
      height: 30vh;
      .main_txt {
        width: 90%;
      }
    }
    .imgbox{
      background-image: url(../img/lproomplan/main_sp.jpg);
    }
  }

  .sec {
    .ttl_area {
      padding: 9% 0;
      .imgbox {
        width: 25%;
      }
    }
  }

/*@media screen end*/}
// ______________________________________
// ______________________________________
// ______________________________________

  .sec1{
    padding: 3% 0;
    background-image: url(../img/lproomplan/bg01.jpg);
    .text {
      padding-bottom: 3%;
      letter-spacing: .05em;
      font-size: clamp(10px,4vw,16px);
    }
    .flbox {
      .box {
        box-sizing: border-box;
        padding: 5px;
        padding-bottom: 0;
        width: calc(94%/3);
        background-color: #004747;
        position: relative;
        .imgbox {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          margin: 0 auto;
          aspect-ratio: 2/1;
          background-image: url(../img/lproomplan/mrt01.jpg);
          background-position: center;
          background-size: cover;
          .text_area {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .img1 {
              width: 28%;
              max-width: 250px;
              margin-bottom: 5px;
            }
            p {
              color: #fff;
            }

            .p1 {
              font-size: clamp(10px,1.8vw,20px);
              margin-top: 5px;
            }
          }
          .imgnote {
            color: #fff;
          }
        }
        .p2 {
          color: #fff;
          position: relative;
          width: 95%;
          margin: 0 auto;
          padding: 5px 0 1px;
          font-size: clamp(10px,2vw,20px);
          &::before,
          &::after {
            content: "";
            position: absolute;
            height: 1px;
            width: 35%;
            background-color: #fff;
            top: 50%;
            transform: translateY(-50%);
          }
          &::before {
            right: 66%;
          }
          &::after {
            left: 66%;
          }
        }
        a {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }
        &:nth-child(2) {
          .imgbox {
            background-image: url(../img/lproomplan/mrt02.jpg);
          }
        }
        &:nth-child(3) {
          .imgbox {
            background-image: url(../img/lproomplan/mrt03.jpg);
          }
        }
        &:hover {
          opacity: 0.5;
        }
      }
    }
  }

// ______________________________________
@media screen and (max-width: inc_set.$media_sp){
  .sec1 {
    padding: 8% 0 10%;
    .text {
      padding-bottom: 8%;
    }
    .flbox {
      .box {
        width: 85%;
        margin: 0 auto;
        .imgbox {
          .text_area {
            .img1 {
              max-width: unset;
            }
            .p1 {
              font-size: clamp(10px,4.5vw,24px);
              margin-top: 5px;
            }
          }
        }
        .p2 {
          font-size: clamp(10px,5.5vw,20px);
        }
        &:nth-child(n+2) {
          margin-top: 5%;
        }
      }
    }
  }
/*@media screen end*/}
// ______________________________________
// ______________________________________
// ______________________________________

  .sec2 {
    .ttl_area {
      background-image: url(../img/lproomplan/p1_bg.jpg);
    }
    .border {
      border: 1px solid;
      border-image: linear-gradient(90deg, #d7c586 0%, #eaeaea 50%, #d7c586 100%);
      border-image-slice: 1;
    }
    .flbox {
      align-items: flex-start;
      padding: 5% 0 6%;
      .box {
        // width: 45%;
        width: 48%;
        .div1 {
          background-color: #006070;
          padding: 8px;
          box-sizing: border-box;
          position: relative;
          box-shadow: 3px 3px 6px 0px rgba(0,0,0,.4);
          p {
            background: linear-gradient(90deg, #d7c586 0%, #ffffff 50%, #d7c586 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: rgba(0,0,0,0);
            letter-spacing: 0.05em;
          }
          .p1 {
            font-size: clamp(10px,7.5vw,36px);
            padding-top: 2vh;
            position: relative;
            &::after {
              content: "";
              position: absolute;
              width: 80%;
              max-width: 300px;
              height: 1px;
              background: linear-gradient(90deg, #d7c586 0%, #ffffff 50%, #d7c586 100%);
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);
            }
            .big {
              font-size: clamp(10px,14vw,60px);
            }
          }
          .p2 {
            padding: 10px 0;
            font-size: clamp(10px,6.5vw,30px);
            .big {
              font-size: clamp(10px,12vw,45px);
            }
            .middle {
              font-size: clamp(10px,7vw,33px);
            }
            .small {
              font-size: clamp(10px,4.5vw,14px);
            }
          }
        }
        .div2 {
          padding: 0;
          margin: 7% auto 2%;
          align-items: flex-end;
          .box2_1 {
            // width: 48%;
            width: 50%;
            text-align: left;
            p {
              letter-spacing: 0.05em;
            }
            .p1 {
              font-size: clamp(10px,4vw,14px);
            }
            .p2 {
              padding-top: 2vh;
              font-size: clamp(10px,1.7vw,18px);
              .gln {
                color: #006070;
              }
              .big {
                font-size: clamp(10px,5vw,50px);
              }
              .middle {
                font-size: clamp(10px,3vw,36px);
              }
            }
            .p3 {
              font-size: clamp(10px,1.3vw,14px);
            }
            &:nth-child(3) {
              width: 100%;
              text-align: center;
            }
          }
        }
        .div3 {
          img {
            box-shadow: 3px 3px 6px 0px rgba(0,0,0,.4);
          }
        }
        .div4 {
          justify-content: center;
          .box {
            width: 48%;
            aspect-ratio: 23/8;
            background-color: #fff;
            box-shadow: 3px 3px 6px 0px rgba(0,0,0,.4);
            box-sizing: border-box;
            padding: 5px;
            .border {
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              p {
                color: #004747;
                letter-spacing: 0.05em;
              }
              .p1{
                font-size: clamp(10px,1vw,15px);
                padding-top: 6px;
              }
            }
            &:nth-child(1) {
              .p1{
                font-size: clamp(10px,1vw,13px);
              }
            }
            &:nth-child(even) {
              margin-left: 10px;
            }
            &:not(:last-child) {
              margin-bottom: 10px;
            }
          }
        }
        .div5 {
          width: 50%;
        }
        &:nth-child(1) {
          width: 52%;
        }
      }
    }
  }

// ______________________________________
@media screen and (max-width: inc_set.$media_sp){
  .sec2 {
    .ttl_area {
      background-image: url(../img/lproomplan/p1_bg_sp.jpg);
    }
    .flbox {
      margin: 5% auto 7%;
      .box {
        width: 100%;
        .div2 {
          margin: 5% 0;
          .box2_1 {
            width: 100%;
            text-align: center;
            .p1 {
              font-size: clamp(10px,4vw,20px);
            }
            .p2 {
              padding-top: 2vh;
              font-size: clamp(10px,5vw,20px);
              .big {
                font-size: clamp(10px,14vw,55px);
              }
              .middle {
                font-size: clamp(10px,14vw,36px);
              }
            }
            .p3 {
              padding-top: 1vh;
              font-size: clamp(10px,3.6vw,15px);
            }
          }
        }
        .div3 {
          width: 95%;
          margin: 0 auto;
        }
        .div4 {
          margin: 8% auto;
          .box {
            width: 90%;
            aspect-ratio: 35/8;
            .border {
              .p1 {
                font-size: clamp(10px,3.8vw,25px);
              }
            }
            &:not(:last-child) {
              margin: 0;
            }
            &:nth-child(even) {
              margin: 0;
            }
            &:nth-child(n+2) {
              margin-top: 10px;
            }
          }
        }
        .guide {
          width: 70%;
          margin: 0 auto;
          margin-top: 5%;
        }
        &:nth-child(1) {
          width: 100%;
        }
      }
    }
  }

/*@media screen end*/}
// ______________________________________
// ______________________________________
// ______________________________________

  .sec3 {
    background-color: #F0F0F0;
    .ttl_area {
      background-image: url(../img/lproomplan/p2_bg.jpg);
    }
    .col_wh {
      color: #fff;
    }
    .photo_area {
      align-items: flex-start;
      box-sizing: border-box;
      padding: 5% 0;
      .imgphoto {
        width: calc(95%/3);
        position: relative;
        margin-top: 2vw;
        .img_ttl {
          position: absolute;
          top: -2.5vh;
          left: .5em;
          width: 90px;
        }
      }
      .p1 {
        font-size: clamp(11px,1.4vw,15px);
        letter-spacing: 0em;
        color: #505050;
        margin-top: 10px;
      }
    }
    .tub_area {
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      padding: 10px 0;
      .tub {
        cursor: pointer;
        padding: 5px 20px;
        box-sizing: border-box;
        p {
          padding-top: 4px;
          font-size: clamp(10px,3.6vw,18px);
        }
        &:nth-child(2) {
          margin-left: 60px;
          position: relative;
          &::after {
            position: absolute;
            content: "";
            height: 100%;
            width: 1px;
            background-color: #000;
            top: 0;
            left: -30px;
          }
        }
        &.active {
          color: #fff;
          background: rgb(36,91,120);
          background: linear-gradient(90deg, rgba(36,91,120,1) 0%, rgba(0,60,70,1) 100%);
        }
      }
    }
    .plan_box{
      display: none;
      .text_area {
        padding: 5.5% 0 7%;
        display: flex;
        align-items: center;
        justify-content: center;
        .lead {
          font-size: clamp(10px,5vw,28px);
          color: #003C46;
        }
        .text_box {
          position: relative;
          padding: 30px 0;
          margin-left: 1em;
          &::after,
          &::before {
            content: "";
            position: absolute;
            height: 4vw;
            width: 94vw;
            max-width: 300px;
            background-image: url(../img/lproomplan/p2_6.png);
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            transform: translateX(-50%);
          }
          &::after {
            left: 50%;
            bottom: 100%;
          }
          &::before {
            left: 50%;
            top: 100%;
            transform: rotate(180deg) translateX(50%);
          }
          .txt {
            margin-top: 10px;
            font-size: clamp(10px,3.5vw,16px);
            color: #505050;
          }
        }
      }
      .flbox {
        width: 100%;
      }
      .imgbox {
        width: 100%;
        max-width: 1200px;
        margin-top: 5%;
      }
      &.active {
        display: block;
      }
    }
    .plan_box1{
      .flbox {
        align-items: flex-start;
      }
    }
    .plan_box2{
      .flbox {
        text-align: center;
        justify-content: center;
      }
    }
  }

// ______________________________________
@media screen and (max-width: inc_set.$media_sp){
  .sec3 {
    padding-bottom: 8%;
    .ttl_area {
      background-image: url(../img/lproomplan/p2_bg_sp.jpg);
    }
    .photo_area {
      width: 90%;
      margin: 0 auto;
      padding: 20% 0 5%;
      .imgphoto {
        width: 100%;
        position: relative;
        .img_ttl {
          top: -6vw;
          left: .5em;
          width: 25vw;
        }
        &:nth-child(n+2) {
          margin-top: 14%;
        }
      }
      .p1 {
        font-size: clamp(10px,4.1vw,16px);
        letter-spacing: 0.05em;
      }
    }
    .tub_area {
      .tub {
        padding: 0;
        p {
          padding: 8px 2px 5px;
        }
        &:nth-child(2) {
          margin-left: 30px;
          &::after {
            left: -15px;
          }
        }
      }
    }
    .plan_box {
      .flbox {
        .imgphoto {
          width: 100%;
        }
      }
      .text_area {
        padding: 8% 0;
        .text_box {
          padding: 15px 0;
          .txt {
            font-size: clamp(10px,3.5vw,15px);
          }
          &::before,
          &::after {
            height: 4.5vw;
          }
        }
      }
    }
  }

/*@media screen end*/}
// ______________________________________
// ______________________________________
// ______________________________________

  .sec4 {
    .text_area {
      padding: 3% 0;
    }
    .ttl_area {
      padding: 5% 0 3%;
    }
    .imgbox1 {
      p {
        color: #fff;
      }
    }
    .flbox {
      justify-content: space-between;
      .imgbox {
        width: 100%;
        background-color: #b1adad;
        p {
          color: #fff;
          font-size: clamp(10px,3vw,14px);
          text-shadow: #000;
          text-shadow: 0 0 10px #000,0 0 10px #000;
          .big {
            font-size: clamp(10px,3vw,30px);
          }
        }
        &:nth-child(n+2) {
          width: 49%;
          margin-top: 20px;
        }
      }
    }
  }

// ______________________________________
@media screen and (max-width: inc_set.$media_sp){

  .sec4 {
    .ttl_area {
      padding: 10% 0 0;
    }
    .imgbox1 {
      overflow-x: scroll;
      margin: 10px 0;
      img {
        width: 250%;
      }
    }
    .note {
      text-align: right;
    }
    .swipe {
      width: 95%;
      margin: 0 auto;
      padding: 10px 0;
    }
    .text_area {
      .lead {
        font-size: clamp(10px,4.5vw,20px);
        padding: 5% 0;
        margin-left: 0.3em;
      }
    }
    .flbox {
      .imgbox {
        width: 100%;
        p {
        }
        &:nth-child(n+2) {
          width: 100%;
        }
      }
    }
  }

/*@media screen end*/}
// ______________________________________
// ______________________________________
// ______________________________________


.sec5 {
  padding-top: 5%;
  .btn {
    position: relative;
    background: rgb(36,91,120);
    background: linear-gradient(90deg, rgba(36,91,120,1) 0%, rgba(0,60,70,1) 100%);
    width: 80%;
    max-width: 300px;
    margin: 3% auto 0;
    padding: 20px 0;
    transition: 0.5s;
    box-shadow: 3px 4px 10px 0px rgba(0, 0, 0, 0.2);
    margin-bottom: 15px;
    box-sizing: border-box;
    p {
      color: #fff;
      letter-spacing: 0.05em;
    }
    a {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
    &:hover {
      opacity: 0.5;
    }
  }
}
  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
    .sec5 {
      padding-top: 10%;
      .btn {
        margin: 10% auto;
        padding: 16px 0 15px;
      }
    }

  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________


}
