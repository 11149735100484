@use "inc/setting" as inc_set;
@use "inc/reset" as inc_re;

#plan.type{
  .sec1{
    margin: 0 auto;
    padding: 100px 0 0;
  }

  // #type_A{
  //   .type_img{
  //     max-width: 496px;
  //   }
  // }
  // #type_C{
  //   .type_img{
  //     max-width: 485px;
  //   }
  // }
  // #type_D{
  //   .type_img{
  //     max-width: 485px;
  //   }
  // }
  // #type_E{
  //   .type_img{
  //     max-width: 489px;
  //   }
  // }
  // #type_Eg{
  //   .type_img{
  //     max-width: 489px;
  //   }
  // }
  // #type_F{
  //   .type_img{
  //     max-width: 522px;
  //   }
  // }
  // #type_Fg{
  //   .type_img{
  //     max-width: 522px;
  //   }
  // }
  // #type_G{
  //   .type_img{
  //     max-width: 457px;
  //   }
  // }
  // #type_Gg{
  //   .type_img{
  //     max-width: 457px;
  //   }
  // }
  // #type_J{
  //   .type_img{
  //     max-width: 472px;
  //   }
  // }
  // #type_L{
  //   .type_img{
  //     max-width: 719px;
  //   }
  //   .planL_p{
  //     color: #006070;
  //     text-align: center;
  //     font-size: clamp(10px,3.8vw,20px);
  //     margin: 80px 0 30px;
  //   }
  //   .planL_img{
  //     position: relative;
  //     p{
  //       position: absolute;
  //       bottom: 0;
  //       right: 0;
  //       font-size: 10px;
  //       color: #fff;
  //       padding: 10px;
  //     }
  //   }
  // }

  .list_box{
    margin: 50px auto 0;
    background-color: #e2e4e5;
    padding-top: 50px;
  }

  .type_box{
    width: 95%;
    margin: 0 auto;
    max-width: 1000px;
    // display: none;
    // &.show{
    //   display: block;
    // }
    .type_txt{
      margin: 0 0 50px;
      .gld{
        background: linear-gradient(90deg, #d7c586 0%, #ffffff 50%, #d7c586 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: rgba(0,0,0,0);
      }
      .top{
        background: #006070;
        position: relative;
        padding: 30px 0;
        text-align: center;
        &::before{
          content: "";
          display: block;
          width: calc(100% - 10px);
          height: calc(100% - 10px);
          border: 1px solid #fff;
          left: 50%;
          top: 50%;
          position: absolute;
          transform: translate(-50%,-50%);
        }
        .p1{
          display: inline-block;
          letter-spacing: 0em;
          font-size: clamp(0px, inc_set.$vw_38px, 28px);
          border-right: 1px solid #fff;
          padding: 10px 15px 0 0;
          margin: 0 15px 0 0;
          .span1{
            font-size: clamp(0px, inc_set.$vw_72px, 56px);
          }
        }
        .p2{
          display: inline-block;
          font-size: clamp(0px, inc_set.$vw_32px, 20px);
          .span1{
            font-size: clamp(0px, inc_set.$vw_46px, 32px);
          }
          .floor{
            font-size: clamp(10px,3vw,14px);
          }
        }
        &.flex{
          display: flex;
          align-items: center;
          justify-content: center;
          .p1{
            padding: 15px 15px 0 0;
          }
        }
      }
      .top_menu_type{
        .p1{
          padding: 10px 15px 5px 0;
          position: relative;
          .menu_type{
            border: solid 1px #d7c586;
            font-size: clamp(10px,3vw,15px);
            position: absolute;
            bottom: -18%;
            left: 45%;
            transform: translate(-50%,0);
            width: 90%;
            line-height: 1.1em;
          }
        }
      }

      .bottom{
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin: 20px auto;
        p{
          text-align: left;
        }
        .p1{
          font-size: clamp(0px, inc_set.$vw_48px, 28px);
          line-height: 1em;
          .span1{
            font-size: clamp(0px, inc_set.$vw_72px, 42px);
          }
          .span2{
            font-size: clamp(0px, inc_set.$vw_24px, 16px);
          }
        }
        .p2{
          font-size: clamp(0px, inc_set.$vw_24px, 14px);
          line-height: 1.2em;
          margin: 0 0 5px 20px;
        }
      }
    }

    .type_img{
      // width: 100%;
      max-width: 850px;
      width: 100%;
      margin: 0 auto;
    }

    .type_note{
      width: 95%;
      max-width: 400px;
      margin: 50px auto;
    }

    .bnr {
      margin: 0 auto;
      margin-bottom: 50px;
      max-width: 800px;
    }

    .planL_p{
      color: #006070;
      text-align: center;
      font-size: clamp(10px,3.8vw,20px);
      margin: 80px 0 30px;
    }
    .planL_img{
      position: relative;
      p{
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: 10px;
        color: #fff;
        padding: 10px;
      }
    }
  }

  .tab_btn{
    max-width: 500px;
    width: 95%;
    margin: 0 auto 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn{
      width: calc(95%/2);
      padding: 10px 0 8px;
      cursor: pointer;
      border: solid 1px #006070;
      color: #006070;
      display: flex;
      align-items: center;
      justify-content: center;
      p{
        font-size: clamp(10px,3.5vw,16px);
      }
      &.active{
        background: #006070;
        p{
          color: #fff;
        }
      }
    }
  }

  .tab_type{
    display: none;
    &.active{
      display: block;
    }
  }
  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
    .sec1{
      padding: inc_set.$vw_100px 0;
    }

    // #type_A{
    //   .type_img{
    //     max-width: 496px * .47;;
    //   }
    // }
    // #type_C{
    //   .type_img{
    //     max-width: 485px * .47;
    //   }
    // }
    // #type_D{
    //   .type_img{
    //     max-width: 485px * .47;
    //   }
    // }
    // #type_E{
    //   .type_img{
    //     max-width: 489px * .47;
    //   }
    // }
    // #type_Eg{
    //   .type_img{
    //     max-width: 489px * .47;
    //   }
    // }
    // #type_F{
    //   .type_img{
    //     max-width: 522px * .47;
    //   }
    // }
    // #type_Fg{
    //   .type_img{
    //     max-width: 522px * .47;;
    //   }
    // }
    // #type_G{
    //   .type_img{
    //     max-width: 457px * .47;;
    //   }
    // }
    // #type_Gg{
    //   .type_img{
    //     max-width: 457px * .47;;
    //   }
    // }
    // #type_J{
    //   .type_img{
    //     max-width: 472px * .47;
    //   }
    // }
    // #type_L{
    //   .type_img{
    //     max-width: 719px * .47;
    //   }
    //   .planL_p{
    //     margin: 45px 0 10px;
    //   }
    // }

    .type_box{
      .type_txt{
        margin: 0 auto inc_set.$vw_30px;
        .top{
          padding: 10px 0;
          &::before{
            width: calc(100% - 5px);
            height: calc(100% - 5px);
          }
          .p1{
            padding: 5px 10px 0 0;
            margin: 0 10px 0 0;
          }
        }
        .top_menu_type{
          padding: 10px 0 17px;
          .p1{
            padding: 5px 10px 5px 0;
          }
        }

        .bottom{
          margin: 0 auto;
          align-items: center;
          flex-direction: column;
          p{
            text-align: center;
          }
          .p1{
            margin: 0;
          }
          .p2{
            margin: 0;
          }
        }
      }

      .type_note{
        margin: 10px auto;
        max-width: 250px;
      }

      .planL_p{
        margin: 50px 0 10px;
      }
    }

    .tab_btn{
      margin: 0 auto 15px;
      .btn{
        padding: 10px 0 7px;
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________
  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){

  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________
}
