@use "inc/setting" as inc_set;
@use "inc/reset" as inc_re;

#access{
  .main_wrap{
    .imgbox{
      background-image: url(../img/access/main.jpg);
    }
  }



  .access_wrap{
    background: #d6d6d6;
    .text{
      letter-spacing: -0.03em;
      font-size: clamp(10px,4vw,16px)
    }
  }

  .sec1{
    .lead_box{
      padding: 50px 0 0;
      p{
        text-align: center;
        line-height: 2em;
      }
      .gld{
        color: #8f805a;
      }
      .lead{
        color: #005959;
        margin: 0 0 20px;
        letter-spacing: .1em;
        font-size: clamp(10px,5.6vw,26px);
        .span1{
          font-size: clamp(0px,8.8vw,52px);
        }
      }
    }

    .img_area{
      width: 95%;
      max-width: 1000px;
      margin: 50px auto 100px;
      position: relative;
      .note{
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 5px;
      }
    }

    .flex{
      width: 95%;
      max-width: 1000px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      .img{
        width: calc(100%/2);
        position: relative;
        .text_img{
          width: 60%;
          position: absolute;
          left: -25px;
          top: -27px;
        }
        .note{
          position: absolute;
          bottom: 0;
          right: 0;
          padding: 5px;
          text-shadow: 0 0 1px rgba(0,0,0,1),
          0 0 1px rgba(0,0,0,1),
          0 0 1px rgba(0,0,0,1),
          0 0 1px rgba(0,0,0,1),
          0 0 1px rgba(0,0,0,1),
          0 0 1px rgba(0,0,0,1);
        }
      }
    }

    .s2_bottom{
      max-width: 1000px;
      margin: 80px auto 0;
      background: #d6d6d6;
      .text_area{
        margin-bottom: -130px;
        p{
          letter-spacing: .1em;
        }
        .s2b_p1{
          font-size: clamp(10px,4.5vw,20px);
          margin-bottom: 10px;
        }
        .s2b_p2{
          font-size: clamp(10px,3.8vw,14px);
        }
      }
      .s2b_img_wrap{
        mix-blend-mode: darken;
        .s2b_img{
          position: relative;
          .img_note{
            position: absolute;
            right: 0;
            bottom: 0;
            font-size: 10px;
            padding: 5px;
          }
        }
      }
    }
  }

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
    .sec1{
      .lead_box{
        padding: inc_set.$vw_50px 0 0;
        .lead{
          margin: 0 0 10px;
        }
      }

      .img_area{
        margin: inc_set.$vw_50px auto inc_set.$vw_100px;
        .ovr{
          overflow: auto;
          img{
            width: 200%;
          }
        }
      }

      .flex{
        .img{
          width: 100%;
          margin: 0 auto 10px;
          .text_img{
            width: 70%;
            left: -5px;
            top: -21px;
          }
        }
      }

      .s2_bottom{
        // max-width: 950px;
        width: 95%;
        margin: 30px auto;
        .text_area{
          width: 95%;
          margin: 0 auto;
        }
        .s2b_img_wrap{
            overflow: auto;
          .s2b_img{
            width: 150%;
            // mix-blend-mode: darken !important;
            background: #d6d6d6;
            // img{
            //   background: #d6d6d6;
            //
            // }
            // background: #d6d6d6;
            // mix-blend-mode: none;
          }
        }
      }

      // .arrow{
      //   width: 35%;
      //   margin: 0 auto;
      // }
      .swipeIco{
        margin: 0
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________



  .sec2{
    .flex{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .img{
        width: calc(100%/2);
        position: relative;
        // &::before{
        //   content: "";
        //   display: block;
        //   width: 100%;
        //   height: 100%;
        //   position: absolute;
        //   left: 0;
        //   top: 0;
        //   background: rgba(0,0,0,.3);
        // }
        img{
          display: block;
        }
        .icon_area{
          width: 100%;
          max-width: 250px;
          position: relative;
          z-index: 2;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
          display: flex;
          justify-content: space-between;
          align-items: center;
          .ico{
            width: calc(90%/2);
            height: 110px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            border: 1px solid #fff;
            border-radius: 50%;
            img{
              max-width: 30px;
              margin: 0 auto 5px;
            }
            p{
              color: #fff;
              font-size: clamp(0px, inc_set.$vw_20px, 14px);
            }
          }
        }
        .note{
          position: absolute;
          bottom: 0;
          right: 0;
          padding: 5px;
        }
      }
    }
  }
  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
    .sec2{
      .flex{
        width: 100%;
        .img{
          width: 100%;
          .icon_area{
            max-width: 150px;
            .ico{
              height: 70px;
              img{
                max-width: 20px;
              }
            }
          }
        }
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________



  .sec3{
    padding: 100px 0;
    .flex{
      width: 95%;
      max-width: 1000px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      flex-wrap: wrap;
      position: relative;
      border: 1px solid #005959;
      box-sizing: border-box;
      padding: 40px 20px;
      &::before{
        content: "";
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        display: block;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        border: 1px solid #005959;
        position: absolute;
      }
      .text_area{
        width: 47%;
        .lead{
          margin: 0 0 10px;
          color: #005959;
          font-size: clamp(10px,6vw,24px);
        }
      }
      .img_area{
        width: 50%;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        .img{
          width: calc(95%/2);
          position: relative;
        }
        .text_img{
          width: auto;
          height: 50px;
          top: -32px;
          left: -20px;
          position: absolute;
          img{
            width: auto;
            height: 100%;
          }
        }
        .note{
          text-align: right;
        }
      }
    }
  }
  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
    .sec3{
      padding: inc_set.$vw_100px 0;
      .flex{
        padding: 20px 10px;
        &::before{
          width: calc(100% - 5px);
          height: calc(100% - 5px);
        }
        .text_area{
          width: 100%;
          p{
            text-align: center;
          }
        }
        .img_area{
          width: 100%;
          margin: 30px auto 0;
          .img{
            width: 100%;
            margin: 0 auto 10px;
          }
          .text_img{
            width: auto;
            height: 45px;
            top: -26px;
            left: 0;
          }
        }
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________


  .sec4{
    padding: 0 0 100px;
    .lead_box{
      padding: 0 0 30px;
      p{
        text-align: center;
        line-height: 2em;
      }
      .gld{
        color: #8f805a;
      }
      .lead{
        color: #005959;
        margin: 0 0 20px;
        letter-spacing: .1em;
        font-size: clamp(10px,6vw,24px);
        .span1{
          font-size: clamp(0px, inc_set.$vw_60px, 42px);
        }
      }
    }

    .flex{
      width: 95%;
      max-width: 1000px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      position: relative;
      .img_area{
        width: calc(95%/4);
        position: relative;
      }
      .text_img{
        width: auto;
        height: 40px;
        top: -38px;
        left: -20px;
        position: absolute;
        img{
          width: auto;
          height: 100%;
        }
      }
      .note{
        text-align: right;
      }
    }
  }

    // ______________________________________
    @media screen and (max-width: inc_set.$media_sp){
      .sec4{
        .lead_box{
          padding: 0 0 inc_set.$vw_50px;
          .lead{
            margin: 0 0 10px;
          }
        }
        .flex{
          .img_area{
            width: calc(95%/2);
            margin: 0 0 10px;
          }
          .text_img{
            height: 30px;
            top: -28px;
            left: 0;
            img{
              width: auto;
              height: 100%;
            }
          }
          .note{
            text-align: right;
          }
        }
      }
    /*@media screen end*/}
    // ______________________________________
    // ______________________________________
    // ______________________________________
}
