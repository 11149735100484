@use "inc/setting" as inc_set;
@use "inc/reset" as inc_re;

#concept{
  .main_wrap{
    .imgbox{
      background-image: url(../img/concept/main.jpg);
    }
  }


  .concept_wrap{
    background: #eceeef;
    padding: 0 0 100px;
  }

  .num{
    p{
      color: #fff;
    }
    .p1{
      font-size: clamp(10px,10vw,48px);
      letter-spacing: 0em;
      line-height: 1em;
    }
    .p2{
      font-size: clamp(10px,4.2vw,20px);
      letter-spacing: 0em;
    }
    .p3{
      font-size: clamp(10px,3.3vw,14px);
      letter-spacing: 0em;
    }
  }

  .sec1{
    width: 100%;
    background-image: url(../img/concept/sec1_bg1.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 100px 0;
    position: relative;
    .note{
      position: absolute;
      bottom: 0;
      right: 0;
      margin: 5px;
    }
    .logo{
      width: 95%;
      max-width: 300px;
      margin: 0 auto 50px;
    }
    .text_area{
      p{
        color: #fff;
        text-align: center;
      }
      .lead{
        margin: 0 0 20px;
        font-size: clamp(10px,6vw, 22px);
      }
      .txt{
        line-height: 2.5em;
        letter-spacing: .01em;
        font-size: clamp(10px,4vw,15px);
      }
    }

    .lead_box{
      width: 95%;
      max-width: 900px;
      margin: 50px auto 0;
      background-image: url(../img/concept/sec2_lead_bk.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;
      padding: 70px 0 60px;
      p{
        color: #005959;
        text-align: center;
        background: linear-gradient(90deg, #ccc398 0%, #ffffff 50%, #cbc295 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: rgba(0,0,0,0);
        .span1{
          color: #80805a;
          font-size: clamp(0px, inc_set.$vw_56px, 38px);
        }
      }
    }

    .flex{
      width: 95%;
      max-width: 900px;
      margin: 50px auto 0;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .num{
        width: calc(95%/5);
        p{
          text-align: center;
        }
      }
    }
  }
  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
    .concept_wrap{
      padding: 0;
    }
    .sec1{
      padding: 50px 0;
      .logo{
        max-width: 250px;
        margin: 0 auto 20px;
      }
      .text_area{
        .lead{
          margin: 0 0 15px;
        }
        .txt{
          letter-spacing: -0.06em;
          line-height: 2em;
        }
      }
      .lead_box{
        margin: 20px auto 0;
        padding: 40px 0 20px;
      }


      .flex{
        flex-wrap: wrap;
        margin: inc_set.$vw_50px auto 0;
        justify-content: center;
        .num{
          width: calc(95%/3);
          margin: 20px 2px 0 2px;
          // .p1{
          //   font-size: clamp(0px, inc_set.$vw_56px, 48px);
          // }
          // .p2{
          //   font-size: clamp(0px, inc_set.$vw_28px, 20px);
          // }
          // .p3{
          //   font-size: clamp(0px, inc_set.$vw_20px, 14px);
          // }
        }
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________

  .sec2{
    .flex{
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin: 0 auto;
      margin-right: 0;
      padding: 50px 0;
      &.flex_re{
        margin: 0;
        flex-direction: row-reverse;
      }
      .text_area{
        width: 40%;
        margin: 0 auto;
        &.scrollin{
          .num{
            &::before,&::after{
              opacity: 1;
              transform: translate(0,0);
            }
          }
        }
        p{
          text-align: center;
        }
        .num{
          max-width: 270px;
          margin: 0 auto 20px;
          padding: 50px 0 20px;
          position: relative;
          &::before,&::after{
            content: "";
            display: block;
            position: absolute;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100% 100%;
            transition: 1s;
            opacity: 0;
          }
          &::before{
            width: 80px;
            height: 80px;
            left: 0;
            top: 0;
            background-image: url(../img/concept/sec2_txtDeco_left.png);
            transform: translate(20px,20px);
          }
          &::after{
            width: 60px;
            height: 60px;
            right: 0;
            bottom: 0;
            background-image: url(../img/concept/sec2_txtDeco_right.png);
            transform: translate(-20px,-20px);
          }
          p{
            color: #005e6d;
          }
        }
        .ttl{
          color: #005e6d;
          margin: 0 0 20px;
          .let{
            letter-spacing: -.3em;
          }
        }
        .text{
          color: #323232;
          letter-spacing: .1em;
          line-height: 1.8em;
          font-size: clamp(10px, 3.8vw, 16px);
        }
      }
      .img_area{
        width: 55%;
        height: 30vw;
        position: relative;
        overflow: hidden;
        .imgbox{
          width: 100%;
          height: 100%;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }
        .note{
          color: #fff;
          text-shadow: 0 0 2px rgba(0,0,0,1),0 0 2px rgba(0,0,0,1);
          position: absolute;
          bottom: 0;
          right: 0;
          padding: 5px;
        }
      }
    }
  }

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
    .sec2{
      .flex{
        padding: 20px 0;
        .text_area{
          width: 100%;
          margin: 0 0 20px;
          .num{
            max-width: 200px;
            margin: 0 auto 10px;
            padding: 50px 0 30px;
            &::before{
              width: 70px;
              height: 70px;
            }
            &::after{
              width: 50px;
              height: 50px;
            }
          }

          .ttl{
            margin: 0 0 10px;
          }
        }
        .img_area{
          width: 100%;
          height: 70vw;
        }
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________

  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){

  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________
}
