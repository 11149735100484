@use "inc/setting" as inc_set;
@use "inc/reset" as inc_re;

#location{
  .main_wrap{
    .imgbox{
      background-image: url(../img/location/main.jpg);
    }
  }
  $green:#005959;
  $whgld:#dccfac;
  $gray:#505050;
  .lead{
    letter-spacing: .05em;
  }
  .text{
    letter-spacing: .08em;
  }
  .flbox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  .imgbox{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    &.bggrd{
      &::before{
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 30%;
        background: linear-gradient(0deg,rgba(#000,.6),rgba(#000,0));
      }
    }
  }
  .imgnote{
    position: absolute;
    bottom: 5px;
    right: 5px;
    color: #fff;
    text-shadow: 0 0 3px #000;
    font-size: 10px;
    letter-spacing: .05em;
    text-align: right;
    line-height: 1em;
  }
  .imgnum{
    position: absolute;
    bottom: 3px;
    right: 3px;
    color: #fff;
    text-shadow: 0 0 8px #000;
    text-align: right;
    font-size: clamp(10px,3vw,13px);
    span{
      font-size: clamp(10px,4vw,30px);
    }
  }
  .col_bl{
    color: #000;
    text-shadow: 0 0 8px #fff;
  }
  .lead,.text{
    line-height: 2em;
  }
  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
    .main_wrap{
      .imgbox{
        background-position: left 30% center;
      }
    }
    .imgbox{
      align-items: flex-end;
      &.bggrd{
        &::before{
          height: 40px;
        }
      }
    }
    .imgnum{
      bottom: 0px;
      right: 0px;
      font-size: clamp(10px,3vw,12px);
      letter-spacing: .05em;
      span{
        font-size: clamp(10px,7vw,30px);
      }
    }
    .lead,.text{
      line-height: 1.8em;
    }
    .lead{
      font-size: clamp(10px,6vw,22px);
    }
    .text{
      font-size: clamp(10px,4vw,15px);
      letter-spacing: .01em;
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________
  .top_lead_box{
    background: $green;
    padding: 50px 0;
    .lead_box{
      width: 90%;
      max-width: 1000px;
      margin: 0 auto;
      p{
        text-align: center;
      }
      .lead{
        color: $whgld;
        letter-spacing: .08em;
      }
      .text{
        color: #fff;
        padding: 30px 0;
        box-sizing: border-box;
      }
      .arcangel_box{
        padding: 50px;
        box-sizing: border-box;
        width: 100%;
        position: relative;
        .ar_line{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
        }
        .ar_line02{
          top: auto;
          bottom: 0;
          transform: rotate(180deg);
        }
        .flbox{
          img{
            width: calc(98%/2);
          }
        }
        .note{
          margin-top: 10px;
          color: #fff;
          text-align: right;
        }
      }
    }
  }
  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
    .top_lead_box{
      .lead_box{
        .text{
          padding: 15px 0 30px;
          letter-spacing: -0.05em
        }
        .arcangel_box{
          padding: 30px 10px;
          .lead{
            letter-spacing: 0em;
            font-size: clamp(10px,5vw,20px);
          }
          .flbox{
            img{
              width: 100%;
              &:nth-of-type(2){
                margin-top: 10px;
              }
            }
          }
        }
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________

  .sec{
    background: #d3d3d3;
    padding-bottom: 50px;
    box-sizing: border-box;
    .ttl_lbr{
      width: 100%;
      height: 28vw;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      .secttl{
        position: absolute;
        width: 100%;
        max-width: 1000px;
        bottom: 0;
        left: 50%;
        transform: translate(-50%,30%);
        color: #fff;
        font-size: clamp(10px,11vw,160px);
        line-height: 1em;
        letter-spacing: 0em;
        text-shadow: 0 0 10px rgba(#000,.2);
      }
    }
    .sectexbox01{
      text-align: center;
      padding: 40px 0;
      box-sizing: border-box;
      .lead{
        color: $green;
        margin-bottom: 20px;
      }
      .text{
        color: $gray;
      }
    }
  }
  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
    .sec{
      .ttl_lbr{
        height: 70vw;
        .secttl{
          font-size: clamp(10px,18vw,150px);
        }
      }
      .lead{
        font-size: clamp(10px,4.8vw,20px);
        letter-spacing: .05em;
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec1{
    .ttl_lbr{
      background-image: url(../img/location/sec1bg.jpg);
      .imgnum{
        background: none;
      }
    }
    .sec1_L{
      position: relative;
      &::before{
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0,-50%);
        background: #e8e8e8;
        width: 80%;
        height: 90%;
        z-index: 0;
      }
      .sec1_L_in{
        justify-content: flex-start;
        align-items: flex-start;
        width: 97%;
        max-width: 1600px;
        .imgarea_sec1_2{
          width: 60%;
          align-items: flex-end;
          position: relative;
          z-index: 1;
          .imgboxsec1_2{
            width: 60%;
          }
          .imgboxsec1_3{
            width: 38%;
          }
        }
        .texarea_sec1_2{
          width: 40%;
          margin-top: 50px;
          position: relative;
          z-index: 1;
          .lead{
            color: $green;
            padding-bottom: 10px;
            border-bottom: solid 1px $green;
            margin-bottom: 10px;
            line-height: 1em;
            padding-left: 20px;
            box-sizing: border-box;
          }
          .text{
            color: $gray;
            padding-left: 20px;
            box-sizing: border-box;
          }
          .imgboxsec1_1{
            width: 80%;
            margin-left:20%;
            margin-top: 20px;
          }
        }
      }
    }
    .sec1_R{
      display: flex;
      justify-content: flex-end;
      &::before{
        right: auto;
        left: 0;
      }
      .sec1_L_in{
        justify-content: flex-end;
        .imgarea_sec1_2{
          .imgboxsec1_2{
            width: calc(98%/2);
          }
          .imgboxsec1_3{
            width: calc(98%/2);
          }
        }
        .texarea_sec1_2{
          .imgboxsec1_1{
            width: 95%;
            margin-left:auto;
            margin-right: 5%;
          }
        }
      }
    }
    .sec1_center{
      width: 90%;
      max-width: 800px;
      margin: 50px auto;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .lead{
        color: $green;
        padding-bottom: 10px;
        border-bottom: solid 1px $green;
        margin-bottom: 10px;
        line-height: 1em;
        width: 100%;
        text-align: center;
      }
      .text{
        color: $gray;
        max-width: 500px;
      }
      .imgbox_center{
        margin-top: 20px;
        .imgbox{
          width: calc(95%/2);
        }
      }
    }
  }
  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
    .sec1{
      .ttl_lbr{
        background-image: url(../img/location/sec1bg_sp.jpg);
        .secttl{
          font-size: clamp(10px,25vw,150px);
        }
      }
      .sectexbox01{
        .lead{
          font-size: clamp(10px,4.2vw,20px);
        }
      }
      .sec1_L{
        &::before{
          top: 0%;
          transform: translate(0,0%);
          width: 95%;
        }
        .sec1_L_in{
          width: 100%;
          flex-direction: column-reverse;
          .imgarea_sec1_2{
            width: 100%;
            margin-top: 20px;
            .imgboxsec1_2{
              width: 90%;
            }
            .imgboxsec1_3{
              width: 70%;
              margin-top: 10px;
              margin-left: 30%;
            }
          }
          .texarea_sec1_2{
            width: 90%;
            margin: 30px auto 0;
            .imgboxsec1_1{
              width: 80%;
              margin-left:0;
              margin: 10px auto 0;
            }
          }
        }
      }
      .sec1_R{
        .sec1_L_in{
          flex-direction: column;
          .imgarea_sec1_2{
            .imgboxsec1_2{
            }
            .imgboxsec1_3{
              width: 90%;
              margin-left: 10%;
            }
          }
        }
      }
      .sec1_center{
        .imgbox_center{
          .imgbox{
            width: 100%;
            &:nth-of-type(2){
              margin-top: 10px;
            }
          }
        }
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________
  .sec2{
    .ttl_lbr{
      background-image: url(../img/location/sec2bg.jpg);
    }
    .sec2_cottl{
      color: $gray;
      font-size: clamp(10px,5vw,30px);
      padding: 20px 0;
      box-sizing: border-box;
    }
    .sec2_cobox{
      margin: 50px auto;

    }
    .sec2_shopping{
      align-items: flex-end;
      position: relative;
      &::before{
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0,-50%);
        width: 93%;
        height: 90%;
        z-index: 0;
        background: #e7d8d5;
      }
      .sec2_cottl{
        &._pc{
          display: none;
        }
        &._sp{
          display: block;
        }
      }
      .imgbox_sh1{
        width: 49%;
      }
      .imgbox_sec2_2{
        width: 50%;
        position: relative;
        z-index: 1;
        .imgbox{
          width: 80%;
        }
      }
      .imgbox_sec2_3{
        width: 45%;
        margin-left: 9%;
        margin-top: 20px;
        .imgbox{
          width: calc(98%/2);
        }
      }
      .imgbox_sh7{
        width: 45%;
      }
    }
    .sec2_education{
      position: relative;
      &::before{
        content: "";
        position: absolute;
        top: 0%;
        right: 0;
        width: 93%;
        height: 90%;
        z-index: 0;
        background: #dae6f0;
      }
      .sec2_cottl{
        width: 80%;
        margin-left: 20%;
        position: relative;
        z-index: 1;
      }
      .imgbox{
        &._pc{
          display: flex;
        }
        &._sp{
          display: none;
        }
      }
      .imgbox_sec2_4{
        width: 90%;
        margin: 0 auto;
        .imgbox{
          &:nth-of-type(1){
            width: 50%;
          }
          &:nth-of-type(2){
            width: 48.5%;
          }
        }
      }
      .imgbox_sec2_5{
        width: 95%;
        margin-left: 5%;
        margin-top: 20px;
        .imgbox{
          width: 30%;
        }
        .imgbox_edu3{
          width: 36.5%;
        }
      }
      .imgbox_sec2_6{
        width: 95%;
        max-width: 1000px;
        margin: 20px auto 0;
        .imgbox{
          width: 48%;
        }
      }
    }
    .sec2_canter{
      box-sizing: border-box;
      position: relative;
      &::before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 93%;
        height: 100%;
        z-index: 0;
      }
      .sec2_cottl{
        text-align: center;
        position: relative;
        z-index: 1;
      }
      .imgarea_sec2{
        width: 90%;
        margin: 0 auto;
      }
    }
    .sec2_bank{
      &::before{
        background: #e0dae7;
      }
      .imgarea_sec2{
        .imgbox{
          width: calc(98%/4);
          &:nth-of-type(3),&:nth-of-type(2){
            width: 23.5%;
          }
        }
      }
    }
    .sec2_hospital{
      &::before{
        background: #d5e3e2;
        left: 50%;
        transform: translate(-50%,0);
        height: 80%;
      }
      .imgarea_sec2{
        width: 85%;
        margin: 0 auto;
        .imgbox{
          width: calc(98%/3);
        }
      }
    }
  }
  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
    .sec2{
      .ttl_lbr{
        background-image: url(../img/location/sec2bg_sp.jpg);
        .imgnote{
          bottom: auto;
          top: 5px;
        }
      }
      .sec2_cottl{
        font-size: clamp(10px,6vw,30px);
        line-height: 1.5em;
        text-align: center;
      }
      .sec2_cobox{
        margin: 50px auto;

      }
      .sec2_shopping{
        margin-top: 0;
        &::before{
          top: 0%;
          transform: translate(0,0%);
          width: 95%;
        }
        .sec2_cottl{
          width: 100%;
          &._pc{
            display: block;
            position: relative;
            z-index: 1;
          }
          &._sp{
            display: none;
          }
        }
        .imgbox_sh1{
          width: 90%;
        }
        .imgbox_sec2_2{
          width: 70%;
          margin-top: 10px;
          margin-left: 30%;
          .imgbox{
            width: 100%;
          }
        }
        .imgbox_sec2_3{
          width: 95%;
          margin-left: 0%;
          margin: 10px auto 0;
          order: 2;
          .imgbox{
            &:nth-of-type(3),&:nth-of-type(4){
              margin-top: 10px;
            }
          }
        }
        .imgbox_sh7{
          width: 70%;
          margin-top: 10px;
          order: 1;
        }
      }
      .sec2_education{
        &::before{
          width:95%;
        }
        .sec2_cottl{
          width: 100%;
          margin-left: 0%;
        }
        .imgbox{
          &._pc{
            display: none;
          }
          &._sp{
            display: flex;
          }
        }
        .imgbox_sec2_4{
          width: 100%;
          .imgbox{
            &:nth-of-type(1){
              width: 95%;
              margin-left: 5%;
            }
            &:nth-of-type(2){
              width: 90%;
              margin-left: 10%;
              margin-top: 10px;
            }
          }
        }
        .imgbox_sec2_5{
          width: 95%;
          margin: 10px auto 0;
          .imgbox{
            width: 48%;
            &:nth-of-type(1){
              order: 2;
              margin-left: 5%;
              margin-top: 10px;
            }
            &:nth-of-type(2){
              order: 1;
              margin-top: 10px;
            }
            &:nth-of-type(3){
              order: 2;
              margin-top: 10px;
            }
          }
          .imgbox_edu3{
            width: 80%;
          }
        }
        .imgbox_sec2_6{
          margin: 10px auto 0;
        }
      }
      .sec2_canter{
        &::before{
          width:100%;
        }
        .imgarea_sec2{
          width: 95%;
        }
      }
      .sec2_bank{
        .imgarea_sec2{
          .imgbox{
            width: calc(98%/2);
            &:nth-of-type(3),&:nth-of-type(2){
              width: calc(98%/2);
            }
            &:nth-of-type(3),&:nth-of-type(4){
              margin-top: 10px;
            }
          }
        }
      }
      .sec2_hospital{
        width: 95%;
        &::before{
          height: 90%;
        }
        .imgarea_sec2{
          width: 85%;
          .imgbox{
            width:100%;
            margin-top: 15px;
            &:nth-of-type(1){
              margin-top: 0;
            }
          }
        }
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________

  .guide{
    padding: 0 0 50px;
    background-image: url(../img/location/grm_bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .main{
      width: 100%;
      height: 20vw;
      position: relative;
      .imgbox{
        width: 100%;
        height: 100%;
        background-image: url(../img/location/guide_main.jpg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: column;
        .ttlbox{
          width: 95%;
          max-width: 1000px;
          margin: 0 auto -10px;
          img{
            width: 80%;
          }
        }
      }
      .note{
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 5px;
      }
    }

    .text_area{
      margin: 50px auto;
      p{
        text-align: center;
        margin: 0 0 20px;
      }
      .lead{
        color: #005959;
      }
    }

    .num{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-image: url(../img/location/grm_numBg.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      p{
        color: #fff;
        font-size: clamp(0px, inc_set.$vw_32px, 28px);
        line-height: 1em;
        padding: 3px 0 0 5px;
        letter-spacing: 0em;
        text-align: center;
      }
    }

    .guide_map{
      .in{
        position: relative;
      }
      .txt{
        text-align: center;
      }
      .note{
        text-align: right;
      }
      @keyframes num{
        0%{
          filter: drop-shadow(0 0 0px rgba(147,31,44,0))drop-shadow(0 0 0px rgba(147,31,44,0));
        }
        50%{
          filter: drop-shadow(0 0 2px rgba(147,31,44,1))drop-shadow(0 0 2px rgba(147,31,44,1));
        }
        100%{
          filter: drop-shadow(0 0 0px rgba(147,31,44,0))drop-shadow(0 0 0px rgba(147,31,44,0));
        }
      }
      .num{
        position: absolute;
        width: 35px;
        height: 25px;
        cursor: pointer;
        filter: drop-shadow(0 0 5px rgba(147,31,44,1))drop-shadow(0 0 5px rgba(147,31,44,1));
        animation: num 2s ease forwards infinite;
        transition: .5s;
        &.active{
          transform: scale(1.5);
          background-image: url(../img/location/grm_numBg_wh.png);
          p{
            color: #931f2c;
          }
        }
        p{
          font-size: clamp(0px, inc_set.$vw_26px, 20px);
          transition: .5s;
        }
      }
      .num1{
        left: 30%;
        bottom: 28%;
      }
      .num2{
        right: 6%;
        top: 50%;
      }
      .num3{
        left: 40%;
        top: 35%;
      }
      .num4{
        left: 55%;
        top: 30%;
      }
      .num5{
        right: 35%;
        top: 25%;
      }
      .num6{
        left: 26%;
        bottom: 45%;
      }
      .num7{
        right: 38%;
        top: 16%;
      }
      .num8{
        right: 32%;
        top: 15%;
      }
      .num9{
        right: 37%;
        top: 34%;
      }
      .num10{
        right: 34%;
        top: 46%;
      }
      .num11{
        left: 13%;
        bottom: 23%;
      }
    }

    .guide_sld_box{
      width: 95%;
      max-width: 1000px;
      margin: 0 auto;
      .flipster__nav{
        display: none;
      }
      .list{
        max-width: 400px;
        height: 360px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        background: #fff;
        box-sizing: border-box;
        padding: 25px;
        box-shadow: 0 5px 10px rgba(0,0,0,.5);
        .num{
          position: absolute;
          right: -10px;
          top: 20px;
          width: 45px;
          height: 35px;
        }
        .text_area{
          margin: 0;
          p{
            text-align: left;
            margin: 0 0 15px;
            letter-spacing: .05em;
          }
          .p1{
            // font-size: clamp(0px, inc_set.$vw_20px, 14px);
            font-size: clamp(10px,3.8vw,14px);
            margin: 0 0 20px;
          }
          .p2{
            // font-size: clamp(0px, inc_set.$vw_36px, 28px);
            font-size: clamp(10px,5.2vw,28px);
            line-height: 1em;
            letter-spacing: 0em;
            .note{
              font-size: 12px;
              margin-left: 5px;
              display: inline-block;
            }
          }
          .p3{
            font-size: clamp(10px,3vw,12px);
          }
        }

        .img_area{
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          .img{
            width: 50%;
          }
        }
      }
    }
  }
  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
    .guide{
      .main{
        width: 100%;
        height: 70vw;
        // .imgbox{
        //   .ttlbox{
        //     margin: 0 auto -20px;
        //   }
        // }
      }

      .text_area{
        margin: 20px auto;
        p{
          margin: 0 0 10px;
        }
        .lead{
          font-size: clamp(10px,4.8vw,24px);
        }
        .txt{
          font-size: clamp(10px,3.5vw,14px);
        }
      }

      .num{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-image: url(../img/location/grm_numBg.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        p{
          color: #fff;
          font-size: clamp(0px, inc_set.$vw_38px, 28px);
          line-height: 1em;
          padding: 3px 0 0 5px;
          letter-spacing: 0em;
          text-align: center;
        }
      }

      .guide_map{
        .ovr{
          overflow: auto;
          .in{
            width: 170%;
          }
        }
        .num{
          width: 20px;
          height: 15px;
          p{
            padding: 2px 0 0;
          }
        }
        .num1{
          left: 30%;
          bottom: 28%;
        }
        .num2{
          right: 6%;
          top: 50%;
        }
        .num3{
          left: 40%;
          top: 35%;
        }
        .num4{
          left: 55%;
          top: 30%;
        }
        .num5{
          right: 35%;
          top: 25%;
        }
        .num6{
          left: 26%;
          bottom: 45%;
        }
        .num7{
          right: 38%;
          top: 16%;
        }
        .num8{
          right: 32%;
          top: 15%;
        }
        .num9{
          right: 37%;
          top: 34%;
        }
        .num10{
          right: 34%;
          top: 46%;
        }
        .num11{
          left: 13%;
          bottom: 23%;
        }
        .txt{
          font-size: clamp(10px,3.2vw,14px);
          letter-spacing: .01em;
          margin-top: 10px;
        }
      }

      .guide_sld_box{
        width: 95%;
        .swipe{
          width: 100%;
          margin: 20px auto;
          color: #000;
          text-align: center;
          font-size: clamp(0px, inc_set.$vw_32px, 24px);
          background-image: url(../img/location/grm_swipe.png);
          background-position: center;
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }
        ul{
          margin-bottom: 50px;
        }
        .flipster__nav{
          display: none;
        }
        .flipster__item{
          width: 92%;
        }
        .list{
          width: 100%;
          // height: 75vw;
          height: 90vw;
          max-height: 420px;
          max-width: none;
          margin: 0 auto;
          padding: 10px;
          .num{
            position: absolute;
            right: 0;
            top: 10px;
            width: 30px;
            height: 20px;
          }
          .text_area{
            p{
              margin: 0 0 10px;
            }
            .p1{
              margin: 0 0 10px;
              letter-spacing: .01em;
            }
          }
        }
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________


  .lifeinfo{
    width: 90%;
    max-width: 1000px;
    margin: 30px auto;
    .ttlbox{
      text-align: center;
      .lead{
        color: $green;
        font-size: clamp(10px,6vw,40px);
        letter-spacing: 0em;
        line-height: 1.2em;
      }
      .text{
        color: $gray;
      }
    }
    .map{
      margin: 30px auto 0;
    }
    .tubttl{
      background: $green;
      text-align: center;
      color: #fff;
      margin-top: 5px;
      padding: 5px 0;
      box-sizing: border-box;
    }
    .tub_wrap{
      width: 100%;
      margin: 5px auto 10px;
      .tub{
        width: calc(98%/6);
        background: #b1b1b1;
        color: #fff;
        padding: 10px 0;
        box-sizing: border-box;
        align-self: stretch;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &.active{
          background: $green;
        }
      }
    }
    .tub_texwrap{
      width: 100%;
      border: solid 1px $green;
      padding: 30px;
      box-sizing: border-box;
      .tub_tex{
        align-items: flex-start;
        opacity: 0;
        display: none;
        &.show{
          opacity: 1;
          display: flex;
        }
        img{
          width: calc(97%/2);
        }
      }
    }
  }
  // ______________________________________
  @media screen and (max-width: inc_set.$media_sp){
    .lifeinfo{
      width: 95%;
      .ttlbox{
        .lead{
          font-size: clamp(10px,8vw,40px);
        }
      }
      .tub_wrap{
        .tub{
          width: calc(96%/3);
          padding: 18px 0;
          p{
            font-size: clamp(10px,3.5vw,15px);
          }
          &:nth-of-type(4),&:nth-of-type(5),&:nth-of-type(6){
            padding: 10px 0;
            margin-top: 8px;
          }
        }
      }
      .tub_texwrap{
        padding: 20px 8px;
        .tub_tex{
          img{
            width: 100%;
            &:nth-of-type(2){
              margin-top: 10px;
            }
          }
        }
      }
    }
  /*@media screen end*/}
  // ______________________________________
  // ______________________________________
  // ______________________________________
}
